import React, { useState, useRef } from 'react';
import LoginAccountImage from './popupimage/LoginAccountImage.png';
import EmailImage from '../../../../assets/images/EmailImage.png';
import { IoArrowBackCircleSharp } from 'react-icons/io5';
import { FaEdit } from 'react-icons/fa';
import {
  clientLogin,
  sendOtp,
  submitNameDobBox,
  verifyOtp,
} from '../../../event-management/EventApis';
import { Modal } from 'antd';
import './Popup.css';
import { toast } from 'react-toastify';
import { captureFingerPrint } from '../../../client-management/ClientApi';
import Swal from 'sweetalert2';
import moment from 'moment';

function LoginAccount({
  isOpenLogin,
  onCloseLOgin,
  clientId,
  fetchClientData,
  fingerPrintString,
  onLoginSuccess,
}) {
  const [isManualLogin, setIsManualLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [timer, setTimer] = useState(30);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [loginType, setLoginType] = useState('');
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [boxNo, setBoxNo] = useState('');
  const [generalError, setGeneralError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const inputRefs = useRef([]);

  const handleLoginTypeChange = (e) => {
    setLoginType(e.target.value);
    resetFields();
  };

  const resetFields = () => {
    setEmail('');
    setName('');
    setDob('');
    setBoxNo('');
    setEmailError('');
    setGeneralError('');
    setIsEmailVerified(false);
  };

  const resetModalState = () => {
    setIsLoading(false);
    setIsManualLogin(false);
    setLoginType('');
    setEmail('');
    setOtp(new Array(4).fill(''));
    setOtpError('');
    setEmailError('');
    setIsEmailVerified(false);
    setIsOtpVerified(false);
    setTimer(0);
  };

  const handleNameDobSubmit = async () => {
    setIsLoading(true);
    setGeneralError('');

    if (!name || !dob || !boxNo) {
      setGeneralError('All fields are required.');
      setIsLoading(false);
      return;
    }
    const formattedDob = formatDate(dob);
    try {
      const response = await submitNameDobBox(name, formattedDob, boxNo);
      if (response.success) {
        fetchClientData();
        onCloseLOgin();
        onLoginSuccess(clientId);
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.message || 'Login successful!',
        });
        setName('');
        setDob('');
        setBoxNo('');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'error',
          text: response.message || 'Please match credentials',
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong, please try again later.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (date) => {
    return moment(date).format('DD-MM-YYYY');
  };

  if (!isOpenLogin) return null;

  const handleManualLoginClick = async () => {
    // setIsManualLogin(true);
    const clientLoginResponse = await clientLogin(clientId);
    if (clientLoginResponse.success) {
      fetchClientData();
      onCloseLOgin();
      setTimer(0);
      resetModalState();
      onLoginSuccess(clientId);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: clientLoginResponse.message,
        confirmButtonColor: '#d33',
      });
      setIsManualLogin(false);
      onCloseLOgin();
      console.error('Client login failed:', clientLoginResponse);
    }
  };

  const handleVerifyEmail = async () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError('Email field cannot be empty.');
      return;
    }
    if (!emailPattern.test(email)) {
      setEmailError('Please enter a valid email address.');
      return;
    }
    setEmailError('');
    try {
      setIsLoading(true);
      const response = await sendOtp(clientId, email);
      if (response.success) {
        setIsEmailVerified(true);
        startTimer();
      } else {
        setEmailError(response.message || 'Please check the mail');
        setIsEmailVerified(false);
      }
    } catch (error) {
      setEmailError('Failed to send OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async () => {
    if (otp.some((digit) => !digit)) {
      setOtpError('Please fill in all OTP fields.');
      return;
    }
    setOtpError('');
    try {
      setIsLoading(true);
      const userId = clientId;
      const otpValue = otp.join('');
      const response = await verifyOtp(userId, otpValue);

      if (response.success) {
        setIsOtpVerified(true);
        setEmail('');
        setOtp('');
        onCloseLOgin();

        const clientLoginResponse = await clientLogin(clientId);
        if (clientLoginResponse.success) {
          fetchClientData();
          setIsOtpVerified(true);
          setEmail('');
          setOtp('');
          onCloseLOgin();
          setTimer(0);
          resetModalState();
          onLoginSuccess(clientId);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: clientLoginResponse.message,
            confirmButtonColor: '#d33',
          });
          setIsManualLogin(false);
          setEmail('');
          setOtp('');
          onCloseLOgin();
          setIsEmailVerified(false);
          console.error('Client login failed:', clientLoginResponse);
        }
      } else {
        setOtpError('Invalid OTP. Please try again.');
      }
    } catch (error) {
      setOtpError('Failed to verify OTP. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditEmail = () => {
    setIsEmailVerified(false);
    setOtp(['', '', '', '']);
    setTimer(30);
  };

  const handleBack = () => {
    setIsManualLogin(false);
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    }
  };

  const startTimer = () => {
    if (!isTimerRunning) {
      setIsTimerRunning(true);
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(intervalId);
            setIsTimerRunning(false);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
  };

  const handleResendOtp = () => {
    if (isOtpVerified) return;
    setTimer(30);
    setIsTimerRunning(false);
    startTimer();
    setOtp(['', '', '', '']);
  };

  if (!isOpenLogin) return null;

  const handleScanFingerPrint = async () => {
    setIsLoading(true);
    try {
      const captureData = await captureFingerPrint();
      const matchScore = await compareFingerprints(
        fingerPrintString,
        captureData?.TemplateBase64
      );
      if (matchScore >= 80) {
        toast.success('Fingerprint matched successfully');
        const clientLoginResponse = await clientLogin(clientId);
        if (clientLoginResponse.success) {
          onCloseLOgin();
          fetchClientData();
          onLoginSuccess(clientId);
        } else {
          toast.error(clientLoginResponse.message);
          console.error('Client login failed:', clientLoginResponse);
        }
      } else {
        toast.error('Fingerprint mismatch. Please try again.');
      }
    } catch (error) {
      toast.error('Error connecting to the capture services.');
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const compareFingerprints = async (
    fingerPrintString,
    capturedFingerPrint
  ) => {
    try {
      const response = await fetch('https://localhost:8443/SGIMatchScore', {
        method: 'POST',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded',
          Origin: 'https://webapipython.secugen.com',
          Referer: 'https://webapipython.secugen.com/',
          'User-Agent':
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/130.0.0.0 Safari/537.36',
          'sec-ch-ua':
            '"Chromium";v="130", "Google Chrome";v="130", "Not?A_Brand";v="99"',
          'sec-ch-ua-mobile': '?0',
          'sec-ch-ua-platform': '"Windows"',
        },
        body: new URLSearchParams({
          licstr: '',
          Template1: fingerPrintString,
          Template2: capturedFingerPrint,
          Templateformat: 'ISO',
        }),
      });

      const data = await response.json();
      return data?.MatchingScore;
    } catch (error) {
      console.error('Error comparing fingerprints:', error);
      return 0;
    }
  };

  return (
    <Modal
      open={isOpenLogin}
      onCancel={() => {
        onCloseLOgin();
        resetModalState();
      }}
      footer={null}
      centered
      onClose={() => {
        resetModalState();
      }}
    >
      <div>
        {!isManualLogin ? (
          <div className="d-flex flex-column align-items-center">
            <img
              src={LoginAccountImage}
              alt="Login"
              className="popupSuccessImage mb-3"
            />
            <h4 className="text-dark text-center">Login to your account</h4>
            <p className="text-center">
              Login securely with fingerprint or enter your credentials manually
            </p>
            <div className="d-flex justify-content-center mb-4">
              <button
                className="btn btn-primary me-3"
                onClick={handleScanFingerPrint}
                disabled={isLoading}
              >
                {isLoading ? 'Scanning...' : 'Scan Fingerprint'}
              </button>
              <button
                onClick={handleManualLoginClick}
                className="btn border border-dark"
              >
                Login Manually
              </button>
            </div>
          </div>
        ) : (
          <div>
            <IoArrowBackCircleSharp className="fs-3" onClick={handleBack} />
            <div className="d-flex flex-column align-items-center w-100">
              <img src={EmailImage} alt="Email" className="popupSuccessImage" />
              <h4 className="header-text fs-4 text-center">
                Login to your account
              </h4>
              <p className="text-center">
                Login securely by entering your credentials manually
              </p>

              <select
                className="form-select"
                aria-label="Default select example"
                value={loginType}
                onChange={handleLoginTypeChange}
              >
                <option value="" disabled>
                  Please select login type
                </option>
                <option value="email">Login with Email</option>
                <option value="namedob">
                  Login with Name, DOB, and Box No.
                </option>
              </select>

              {loginType === 'email' && (
                <div className="email-input-container d-flex align-items-center w-100 mt-3">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError('');
                    }}
                    className="form-control"
                    required
                    disabled={isEmailVerified}
                  />
                  {isEmailVerified && (
                    <FaEdit
                      className="text-primary fs-3"
                      onClick={handleEditEmail}
                      style={{
                        marginLeft: '10px',
                        cursor: 'pointer',
                        color: '#007BFF',
                      }}
                    />
                  )}
                </div>
              )}

              {emailError && <p className="text-danger mt-2">{emailError}</p>}

              {loginType === 'email' && !isEmailVerified && (
                <div className="d-flex justify-content-center mt-3">
                  <button
                    onClick={handleVerifyEmail}
                    className="btn btn-primary"
                    disabled={!email || isLoading}
                  >
                    {isLoading ? 'Sending...' : 'Verify Email'}
                  </button>
                </div>
              )}

              {isEmailVerified && (
                <>
                  <p className="fw-light mt-3 text-center">
                    Enter 4-digit code for manual login
                  </p>
                  <div className="d-flex justify-content-center mb-4">
                    {otp &&
                      otp?.map((digit, index) => (
                        <input
                          key={index}
                          ref={(el) => (inputRefs.current[index] = el)}
                          type="text"
                          className="otp-input text-center"
                          maxLength="1"
                          value={digit}
                          onChange={(e) => {
                            handleInputChange(e, index);
                            setOtpError('');
                          }}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          style={{
                            width: '3rem',
                            height: '3rem',
                            fontSize: '2rem',
                            margin: '0.5rem',
                            borderRadius: '0.5rem',
                          }}
                        />
                      ))}
                  </div>
                  {otpError && <p className="text-danger mt-2">{otpError}</p>}
                  <div className="d-flex justify-content-center">
                    <button
                      onClick={handleLogin}
                      className="btn btn-primary"
                      disabled={
                        isLoading || (Array.isArray(otp) && !otp.every(Boolean))
                      }
                    >
                      {isLoading ? 'Verifying OTP...' : 'Verify OTP'}
                    </button>
                  </div>
                  {timer > 0 && !isOtpVerified && (
                    <p className="mt-3">Resend OTP in {timer}s</p>
                  )}
                  {timer === 0 && !isOtpVerified && (
                    <button
                      onClick={handleResendOtp}
                      className="btn btn-link text-primary"
                    >
                      Resend OTP
                    </button>
                  )}
                </>
              )}

              {loginType === 'namedob' && (
                <div className="namedob-login mt-3 w-100">
                  <input
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control mb-2"
                  />
                  <input
                    type="date"
                    placeholder="Enter your DOB"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    className="form-control mb-2"
                  />
                  <input
                    type="text"
                    placeholder="Enter your Box No."
                    value={boxNo}
                    onChange={(e) => setBoxNo(e.target.value)}
                    className="form-control mb-2"
                  />
                  {generalError && (
                    <p className="text-danger">{generalError}</p>
                  )}
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={handleNameDobSubmit}
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? 'Submitting...' : 'Submit'}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default LoginAccount;
