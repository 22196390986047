import React, { useEffect, useState } from 'react';
import { Tab, Nav, Row, Col, Card, Form, Table } from 'react-bootstrap';
import { ClientViewDetailsAPI } from './ClientApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Image, Spin } from 'antd';
import './client-style/ClientDetailsViewAdmin.css';
import NavbarWithSearchBar from '../reusable/reusableComponent/NavbarWithSearchBar';
import moment from 'moment';
import BackButton from '../reusable/reusableComponent/BackButton';
import HomeFooter from '../home/HomeFooter/HomeFooter';

const ClientDetailsViewAdmin = () => {
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = location.state || {};

  useEffect(() => {
    if (!id) {
      setError('No client ID provided');
      setLoading(false);
      return;
    }

    const fetchedData = async () => {
      try {
        const response = await ClientViewDetailsAPI(id);
        if (response.success && response.data) {
          const client = response.data;
          setFormData(client);
        } else {
          setError('Failed to fetch client data');
        }
      } catch (error) {
        setError('Error fetching client data');
      } finally {
        setLoading(false);
      }
    };

    fetchedData();
  }, [id]);

  if (loading) {
    return (
      <div>
        <Spin className="d-flex justify-content-center align-items-center" />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!formData) {
    return <div>No client data available</div>;
  }

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <>
      <NavbarWithSearchBar />
      <div className="mt-4 p-4">
        <Tab.Container id="left-tabs-example" defaultActiveKey="personalInfo">
          <Nav
            variant="pills"
            className="custom-nav d-flex justify-content-between"
          >
            <Nav.Item>
              <Nav.Link eventKey="personalInfo" className="custom-nav-link">
                Client Personal Detail
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="nomineeOne" className="custom-nav-link">
                First Nominee Detail
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="nomineeTwo" className="custom-nav-link">
                Second Nominee Detail
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="visitHistory" className="custom-nav-link">
                Visit History
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="transactionHistory"
                className="custom-nav-link"
              >
                Transaction History
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="boxDetails" className="custom-nav-link">
                Box Details
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="personalInfo">
              <h5 className="mb-4">Personal Information</h5>
              <Card className="shadow-lg border-0">
                <Card.Body>
                  <Row className="mb-3">
                    <Col md={3} className="d-flex justify-content-center">
                      <Image
                        src={formData.clientPic}
                        alt="Client Picture"
                        roundedCircle
                        width={150}
                        height={150}
                        style={{ objectFit: 'cover' }}
                      />
                    </Col>
                    <Col md={9}>
                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Application No:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData.applicationNo || ''}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>UCC Number:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData.uccNumber || ''}
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Full Name:</strong>
                          <Form.Control
                            className="form-control"
                            value={`${formData.title} ${formData.foreName} ${formData.surName}`}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Email:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData.email || ''}
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Telephone:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData.telephone || ''}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Date of Birth:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData.dob || ''}
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Postcode:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData.postcode || ''}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Nationality:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData.nationality || ''}
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Street:</strong>
                          <Form.Control
                            className="form-control"
                            value={`${formData.street}`}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Town:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData.town || ''}
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={12}>
                          <strong>Remark:</strong>
                          <Form.Control
                            className="form-control"
                            value={
                              formData.remark ||
                              'No Remark available for this client'
                            }
                            readOnly
                            as="textarea"
                            rows={3}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={12}>
                          <strong>Identification Documents:</strong>
                          {formData?.identificationDocument?.length ? (
                            <ul
                              style={{
                                paddingLeft: '20px',
                                listStyleType: 'none',
                              }}
                            >
                              {formData?.identificationDocument.map(
                                (doc, index) => (
                                  <li
                                    key={index}
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ margin: '10px 0' }}
                                  >
                                    <div>{doc.documentType}</div>
                                    <Button
                                      variant="link"
                                      onClick={() =>
                                        window.open(doc.documentUrl, '_blank')
                                      }
                                      style={{
                                        textDecoration: 'none',
                                        color: '#007bff',
                                      }}
                                    >
                                      View Document
                                    </Button>
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            <div>No identification documents available.</div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Tab.Pane>

            <Tab.Pane eventKey="nomineeOne">
              <Card className="shadow-lg border-0">
                <Card.Body>
                  <Row className="mb-3">
                    <Col md={3} className="d-flex justify-content-center">
                      <Image
                        src={formData?.nomineeOne?.nomineePic}
                        alt="Client Picture"
                        roundedCircle
                        width={150}
                        height={150}
                        style={{ objectFit: 'cover' }}
                      />
                    </Col>
                    <Col md={9}>
                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Full Name:</strong>
                          <Form.Control
                            className="form-control"
                            value={`${formData?.nomineeOne?.nomineeTitle} ${formData?.nomineeOne?.nomineeForename} ${formData?.nomineeOne?.nomineeSurname}`}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Email:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData?.nomineeOne?.nomineeEmail || ''}
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Telephone:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData?.nomineeOne?.nomineeTel || ''}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Date of Birth:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData?.nomineeOne?.nomineeDob || ''}
                            readOnly
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Postcode:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData?.nomineeOne?.nomineePostcode || ''}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Nationality:</strong>
                          <Form.Control
                            className="form-control"
                            value={
                              formData?.nomineeOne?.nomineeNationality || ''
                            }
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Street:</strong>
                          <Form.Control
                            className="form-control"
                            value={`${formData?.nomineeOne?.nomineeStreet}`}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Town:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData?.nomineeOne?.nomineeTown || ''}
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={12}>
                          <strong>Identification Documents:</strong>
                          {formData?.nomineeOne?.identificationDocument
                            .length ? (
                            <ul
                              style={{
                                paddingLeft: '20px',
                                listStyleType: 'none',
                              }}
                            >
                              {formData?.nomineeOne?.identificationDocument.map(
                                (doc, index) => (
                                  <li
                                    key={index}
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ margin: '10px 0' }}
                                  >
                                    <div>{doc.documentType}</div>
                                    <Button
                                      variant="link"
                                      onClick={() =>
                                        window.open(doc.documentUrl, '_blank')
                                      }
                                      style={{
                                        textDecoration: 'none',
                                        color: '#007bff',
                                      }}
                                    >
                                      View Document
                                    </Button>
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            <div>No identification documents available.</div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Tab.Pane>

            <Tab.Pane eventKey="nomineeTwo">
              <Card className="shadow-lg border-0">
                <Card.Body>
                  <Row className="mb-3">
                    <Col md={3} className="d-flex justify-content-center">
                      <Image
                        src={formData?.nomineeTwo?.nomineePic}
                        alt="Client Picture"
                        roundedCircle
                        width={150}
                        height={150}
                        style={{ objectFit: 'cover' }}
                      />
                    </Col>
                    <Col md={9}>
                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Full Name:</strong>
                          <Form.Control
                            className="form-control"
                            value={`${formData?.nomineeTwo?.nomineeTitle} ${formData?.nomineeTwo?.nomineeForename} ${formData?.nomineeTwo?.nomineeSurname}`}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Email:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData?.nomineeTwo?.nomineeEmail || ''}
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Telephone:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData?.nomineeTwo?.nomineeTel || ''}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Date of Birth:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData?.nomineeTwo?.nomineeDob || ''}
                            readOnly
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Postcode:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData?.nomineeTwo?.nomineePostcode || ''}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Nationality:</strong>
                          <Form.Control
                            className="form-control"
                            value={
                              formData?.nomineeTwo?.nomineeNationality || ''
                            }
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={6}>
                          <strong>Street:</strong>
                          <Form.Control
                            className="form-control"
                            value={`${formData?.nomineeTwo?.nomineeStreet}`}
                            readOnly
                          />
                        </Col>
                        <Col md={6}>
                          <strong>Town:</strong>
                          <Form.Control
                            className="form-control"
                            value={formData?.nomineeTwo?.nomineeTown || ''}
                            readOnly
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md={12}>
                          <strong>Identification Documents:</strong>
                          {formData?.nomineeTwo?.identificationDocument
                            .length ? (
                            <ul
                              style={{
                                paddingLeft: '20px',
                                listStyleType: 'none',
                              }}
                            >
                              {formData?.nomineeTwo?.identificationDocument.map(
                                (doc, index) => (
                                  <li
                                    key={index}
                                    className="d-flex justify-content-between align-items-center"
                                    style={{ margin: '10px 0' }}
                                  >
                                    <div>{doc.documentType}</div>
                                    <Button
                                      variant="link"
                                      onClick={() =>
                                        window.open(doc.documentUrl, '_blank')
                                      }
                                      style={{
                                        textDecoration: 'none',
                                        color: '#007bff',
                                      }}
                                    >
                                      View Document
                                    </Button>
                                  </li>
                                )
                              )}
                            </ul>
                          ) : (
                            <div>No identification documents available.</div>
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Tab.Pane>

            <Tab.Pane eventKey="visitHistory">
              <table className="table text-center">
                <thead>
                  <tr>
                    <th>Login Time</th>
                    <th>Logout Time</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.visitHistory.map((visit, index) => (
                    <tr key={index}>
                      <td>
                        <div>
                          {moment(visit.loginTime).format('DD-MM-YYYY')}
                          <p>({moment(visit.loginTime).format('HH:SS A')})</p>
                        </div>
                      </td>
                      <td>
                        <div>
                          {moment(visit.logoutTime).format('DD-MM-YYYY')}
                          <p>({moment(visit.logoutTime).format('HH:SS A')})</p>
                        </div>
                      </td>
                      <td>
                        {visit.status === 'logged_out' && (
                          <span className="text-danger">Logged Out</span>
                        )}
                        {visit.status === 'active' && (
                          <span className="text-success">Active</span>
                        )}
                        {visit.status === 'pending' && (
                          <span className="text-warning">Pending</span>
                        )}
                        {visit.status === 'completed' && (
                          <span className="text-info">Completed</span>
                        )}
                        {![
                          'logged_out',
                          'active',
                          'pending',
                          'completed',
                        ].includes(visit.status) && (
                          <span className="text-muted">{visit.status}</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tab.Pane>

            <Tab.Pane eventKey="transactionHistory">
              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th>Payment Method</th>
                    <th>Details</th>
                    <th>Credit</th>
                    <th>Debit</th>
                    <th>Balance</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {formData.transactionHistory.map((transaction, index) => (
                    <tr key={index}>
                      <td>{transaction.paymentMethod}</td>
                      <td>{transaction.details}</td>
                      <td>{transaction.debit}</td>
                      <td>{transaction.credit}</td>
                      <td
                        className={
                          transaction.balance < 0 ? 'text-danger fw-bold' : ''
                        }
                      >
                        {transaction.balance}
                      </td>
                      <td>
                        {moment(transaction.createdAt).format('DD-MM-YYYY')} (
                        {moment(transaction.createdAt).format('HH:MM A')})
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Tab.Pane>
            <Tab.Pane eventKey="boxDetails">
              {formData ? (
                <table className="table table-bordered text-center">
                  <thead>
                    <tr>
                      <th>Box Type</th>
                      <th>Box Size</th>
                      <th>Box Number</th>
                      {formData?.boxType !== 'safe' && <th>Column</th>}
                      <th>Status</th>
                      {formData?.boxType === 'safe' && (
                        <>
                          <th>Complimentary Locker Number</th>
                          <th>Complimentary Locker Column</th>
                        </>
                      )}
                      <th>Plan</th>
                      <th>Payment Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{formData?.boxType || '---'}</td>
                      <td>{formData?.boxSize || '---'}</td>
                      <td>{formData?.boxNumber || '---'}</td>
                      {formData?.boxType !== 'safe' && (
                        <td>{formData.locker?.column || '---'}</td>
                      )}
                      <td>
                        {formData?.locker?.status ||
                          formData?.safe?.status ||
                          '---'}
                      </td>
                      {formData?.boxType === 'safe' && (
                        <>
                          <td>
                            {formData?.complimentaryLocker?.lockerNumber ||
                              '---'}
                          </td>
                          <td>
                            {formData?.complimentaryLocker?.column || '---'}
                          </td>
                        </>
                      )}
                      <td>
                        {formData?.plan ? `${formData.plan} months` : '---'}
                      </td>
                      <td>{formData?.paymentAmount || '---'}</td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <div>No Locker Information Available</div>
              )}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        <div className="back-button-container">
          <BackButton onClick={handleBack} />
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

export default ClientDetailsViewAdmin;
