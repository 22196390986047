import React, { useEffect, useState } from 'react';
import {
  FaFingerprint,
  FaMailBulk,
  FaRegEye,
  FaUserPlus,
} from 'react-icons/fa';
import ClientTable from './ClientTable';
import FingerPrintScan from './FingerPrintScan';
import ArchiveLokerTable from './ArchiveLokerTable';
import { useLocation, useNavigate } from 'react-router-dom';
import './client-style/ClientFilterButtons.css';
import { FiEdit } from 'react-icons/fi';
import {
  MdOutlineHistory,
  MdOutlinePublishedWithChanges,
  MdOutlineUnarchive,
} from 'react-icons/md';
import EditClientDetails from './EditClientDetails';
import ClientChangePlan from './ClientChangePlan';
import AllClientVisitHistory from './AllClientVisitHistory';
import NewClientForm from './NewClientForm';
import EventMailStorage from '../event-management/Events/EventMailStorage';

const buttons = [
  {
    id: 1,
    name: 'Fingerprint Scan',
    icon: <FaFingerprint className="fs-5" />,
  },
  {
    id: 2,
    name: 'Create Client',
    icon: <FaUserPlus className="fs-5" />,
  },
  {
    id: 3,
    name: 'Edit Client',
    icon: <FiEdit className="fs-5" />,
  },
  {
    id: 4,
    name: 'View Client',
    icon: <FaRegEye className="fs-5" />,
  },
  {
    id: 5,
    name: 'Client History',
    icon: <MdOutlineHistory className="fs-5" />,
  },
  {
    id: 6,
    name: 'Change Plan',
    icon: <MdOutlinePublishedWithChanges className="fs-5" />,
  },
  {
    id: 7,
    name: 'Archived Client',
    icon: <MdOutlineUnarchive className="fs-5" />,
  },
  {
    id: 8,
    name: 'Mail Storage',
    icon: <FaMailBulk className="fs-5" />,
  },
];

const ClientFilterButtons = ({ searchTerm }) => {
  const [activeButton, setActiveButton] = useState(buttons[0].id);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.previousTab) {
      setActiveButton(location.state.previousTab);
    } else {
      setActiveButton(1);
    }
  }, [location.state]);

  const handleButtonClick = (id) => {
    setActiveButton(id);
    navigate(location.pathname, { state: { previousTab: id } });
  };

  const handleFormSuccess = () => {
    setActiveButton(4);
  };

  return (
    <div className="p-2">
      <div className="button-group">
        {buttons.map((button) => (
          <button
            key={button.id}
            className={`filter-button ${
              activeButton === button.id ? 'active' : 'inactive'
            }`}
            onClick={() => handleButtonClick(button.id)}
          >
            <div className="d-flex align-items-center gap-2">
              <span>{button.icon}</span>
              <span className="button-text">{button.name}</span>
            </div>
          </button>
        ))}
      </div>
      {activeButton === 1 && <FingerPrintScan />}

      {activeButton === 2 && <NewClientForm onSuccess={handleFormSuccess} />}
      {activeButton === 3 && (
        <div className="client-table-container mt-3">
          <EditClientDetails searchTerm={searchTerm} />
        </div>
      )}
      {activeButton === 4 && (
        <div className="client-table-container mt-3">
          <ClientTable searchTerm={searchTerm} />
        </div>
      )}
      {activeButton === 5 && (
        <div className="client-table-container mt-3">
          <AllClientVisitHistory searchTerm={searchTerm} />
        </div>
      )}
      {activeButton === 6 && (
        <div className="client-table-container mt-3">
          <ClientChangePlan searchTerm={searchTerm} />
        </div>
      )}
      {activeButton === 7 && (
        <div className="client-table-container mt-3">
          <ArchiveLokerTable searchTerm={searchTerm} />
        </div>
      )}
      {activeButton === 8 && (
        <div className="client-table-container mt-3">
          <EventMailStorage searchTerm={searchTerm} />
        </div>
      )}
    </div>
  );
};

export default ClientFilterButtons;
