import React, { useEffect, useState } from 'react';
import { Input, message, Radio, Table, Spin, Image } from 'antd';
import { getForename, makePayment } from './FinancialApi.jsx';
import BackButton from '../../reusable/reusableComponent/BackButton.jsx';
import { useNavigate } from 'react-router-dom';
import { getClientTableAPI } from '../../client-management/API/ClientTableAPI.jsx';
import moment from 'moment';
import CopyableText from '../../reusable/Common/CopyableText.jsx';
import DefaultClientProfile from '../../../assets/user.jpg';

const MakePayment = ({ searchTerm, setSearchTerm }) => {
  const [uccNumber, setUccNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [paymentDate, setPaymentDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState('');
  const [searchTerm1, setSearchTerm1] = useState('');
  const [clientDatas, setClientDatas] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);

  const navigate = useNavigate();
  const { Search } = Input;

  useEffect(() => {
    const fetchName = async () => {
      if (uccNumber) {
        try {
          const result = await getForename(uccNumber);
          if (result.success) {
            setName(`${result?.foreName || ''} ${result?.surName || ''}`);
          } else {
            setName(result.message);
          }
        } catch (error) {
          message.error('Failed to fetch forename');
        }
      } else {
        setName('');
      }
    };

    fetchName();
  }, [uccNumber]);

  const validateFields = () => {
    const newErrors = {};
    if (!uccNumber) newErrors.uccNumber = 'UCC number is required';
    if (!amount || isNaN(amount) || parseInt(amount, 10) <= 0)
      newErrors.amount = 'Amount must be a valid number greater than 0';
    if (!description) newErrors.description = 'Description is required';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    setLoading(true);
    const paymentAmount = parseInt(amount, 10);
    const dataToSubmit = {
      uccNumber,
      amount: paymentAmount,
      
      paymentMethod:
        description === 'Non Cash Adjustment (No Payment received)'
          ? 'non_cash'
          : paymentMethod,
      description,
      paymentDate,
    };

    try {
      const result = await makePayment(dataToSubmit);
      if (result.status === 201) {
        message.success(result.message || 'Payment processed successfully!');
        setUccNumber('');
        setAmount('');
        setDescription('');
        setPaymentMethod('cash');
      } else {
        message.error(result.message || 'Payment failed');
      }
    } catch (err) {
      message.error(`Payment failed: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleBackPrevious = () => {
    navigate(-1);
  };

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const response = await getClientTableAPI();
      if (response.success && response.data) {
        const clients = response?.data?.clients;
        setClientDatas(clients);
        setFilteredClients(clients);
      } else {
        setErrors(response.message || 'Error fetching clients');
      }
    } catch (error) {
      setErrors('Network Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientData();
  }, []);

  useEffect(() => {
    filterClients();
  }, [searchTerm, searchTerm1, clientDatas]);

  const filterClients = () => {
    let filtered = [...clientDatas];

    if (searchTerm && searchTerm.trim()) {
      const lowerCaseSearchTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((client) => {
        const fullNameMatch = `${client.foreName} ${client.surName}`
          .toLowerCase()
          .includes(lowerCaseSearchTerm);
        const emailMatch = client.email
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const uccNumberMatch = client.uccNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const boxNumberMatch = client.boxNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const postcodeMatch = client.postcode
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const telephoneMatch = client.telephone
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        let renewDate = moment(client.renewalDate).format('DD-MM-YYYY');
        const renewalDateMatch = renewDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        let regDate = moment(client.createdAt).format('DD-MM-YYYY');
        const RegDateMatch = regDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const dobDate = moment(client.dob).format('DD-MM-YYYY');
        const dobDateMatch = dobDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const streetMatch = client.street
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);
        const townMatch = client.town
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm);

        return (
          fullNameMatch ||
          emailMatch ||
          uccNumberMatch ||
          boxNumberMatch ||
          postcodeMatch ||
          telephoneMatch ||
          renewalDateMatch ||
          RegDateMatch ||
          dobDateMatch ||
          streetMatch ||
          townMatch
        );
      });
    }

    if (searchTerm1 && searchTerm1.trim()) {
      const lowerCaseSearchTerm1 = searchTerm1.toLowerCase();
      filtered = filtered.filter((client) => {
        const fullNameMatch = `${client.foreName} ${client.surName}`
          .toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const emailMatch = client.email
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const uccNumberMatch = client.uccNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const boxNumberMatch = client.boxNumber
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const postcodeMatch = client.postcode
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const telephoneMatch = client.telephone
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const streetMatch = client.street
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const townMatch = client.town
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        let renewDate = moment(client.renewalDate).format('DD-MM-YYYY');
        const renewalDateMatch = renewDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        let regDate = moment(client.createdAt).format('DD-MM-YYYY');
        const RegDateMatch = regDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        const dobDate = moment(client.dob).format('DD-MM-YYYY');
        const dobDateMatch = dobDate
          ?.toLowerCase()
          .includes(lowerCaseSearchTerm1);
        return (
          fullNameMatch ||
          emailMatch ||
          uccNumberMatch ||
          boxNumberMatch ||
          postcodeMatch ||
          telephoneMatch ||
          renewalDateMatch ||
          RegDateMatch ||
          streetMatch ||
          townMatch ||
          dobDateMatch
        );
      });
    }

    setFilteredClients(filtered);
  };

  const columns = [
    {
      title: 'ClIENT PICTURE',
      dataIndex: 'clientPic',
      key: 'clientPic',
      className: 'text-center',
      render: (text) => (
        <Image
          src={text || DefaultClientProfile}
          alt="Client"
          width={60}
          height={60}
          className="rounded-circle border"
        />
      ),
    },
    {
      title: 'NAME',
      className: 'text-center',
      dataIndex: 'foreName',
      key: 'foreName',
      render: (text, record) => (
        <>
          <div className="text-center">
            <p className="mb-0 fw-bold">{`${record.foreName} ${record.surName}`}</p>
            <p>{record.email}</p>
          </div>
        </>
      ),
    },

    {
      title: 'UCC NUMBER',
      dataIndex: 'uccNumber',
      className: 'text-center',
      key: 'uccNumber',
      render: (text, record) => (
        <CopyableText
          text={record.uccNumber}
          textStyle={{
            color: 'green',
            fontWeight: 'bold',
          }}
        />
      ),
    },
    {
      title: 'BOX NUMBER',
      dataIndex: 'boxNumber',
      className: 'text-center',
      key: 'boxNumber',
    },
    {
      title: 'TELEPHONE',
      dataIndex: 'telephone',
      key: 'telephone',
      className: 'text-center',
    },
    {
      title: 'POSTCODE',
      dataIndex: 'postcode',
      key: 'postcode',
      className: 'text-center',
    },
    {
      title: 'STREET',
      dataIndex: 'street',
      key: 'street',
      className: 'text-center',
    },
    {
      title: 'TOWN',
      dataIndex: 'town',
      key: 'town',
      className: 'text-center',
    },
    {
      title: 'Actions',
      key: 'actions',
      className: 'text-center',
      render: (text, record) => (
        <button
          className="btn next-btn"
          onClick={() => handleMakePayment(record.uccNumber)}
        >
          Make Payment
        </button>
      ),
    },
  ];

  const handleMakePayment = (selectedUccNumber) => {
    setUccNumber(selectedUccNumber);
    handleSearchClean();
  };

  const handleSearchClean = () => {
    setSearchTerm1('');
    setSearchTerm('');
  };

  return (
    <div className="user-details-container p-3">
      <div className="d-flex justify-content-between mb-3">
        <h4 className="header-text fs-3">MAKE PAYMENT</h4>
        <Search
          placeholder="Search by Name, UCC No., Postcode, etc."
          onChange={(e) => setSearchTerm1(e.target.value)}
          enterButton
          allowClear
          value={searchTerm1}
          className="custom-search-bar w-50"
        />
      </div>
      {searchTerm.trim() || searchTerm1.trim() ? (
        <>
          <Table
            dataSource={filteredClients}
            columns={columns}
            rowKey="uccNumber"
            loading={loading}
          />

          <div className="back-button-container">
            <BackButton onClick={handleSearchClean} />
          </div>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                <div className="d-flex align-items-center mb-0 position-relative">
                  <label htmlFor="title" className="custom-label">
                    UCC NO: <span className="required text-danger">*</span>
                    <br />
                  </label>
                  <input
                    id="uccno"
                    name="uccno"
                    type="text"
                    className="form-control custom-input"
                    placeholder="Ucc No."
                    value={uccNumber}
                    onChange={(e) => setUccNumber(e.target.value)}
                  />
                </div>

                <div
                  className="error-message position-absolute"
                  style={{ left: '9.5rem' }}
                >
                  {name && <p className="text-success fs-6 fw-bold">{name}</p>}
                  {errors.uccNumber && (
                    <small className="text-danger">{errors.uccNumber}</small>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                <div className="d-flex align-items-center">
                  <label htmlFor="dob" className="custom-label">
                    DATE OF PAYMENT:
                    <span className="required text-danger">*</span>
                  </label>
                  
                 <input
              id="dob"
              name="dob"
              type="date"
              className="form-control custom-input"
              value={paymentDate} 
              onChange={(e) => setPaymentDate(e.target.value)} 
              max={new Date().toISOString().split('T')[0]}
            />
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                <div className="d-flex align-items-center mb-0 position-relative">
                  <label htmlFor="title" className="custom-label">
                    AMOUNT:<span className="required text-danger">*</span>
                    <br />
                  </label>
                  <input
                    id="amount"
                    name="amount"
                    type="text"
                    className="form-control custom-input"
                    placeholder="Amount"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>

                <div
                  className="error-message position-absolute"
                  style={{ left: '9.5rem' }}
                >
                  {errors.amount && (
                    <small className="text-danger">{errors.amount}</small>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="d-flex align-items-center position-relative">
                  <label htmlFor="dob" className="custom-label">
                    DESCRIPTION:<span className="required text-danger">*</span>
                  </label>
                  <select
                    id="description"
                    className="form-control custom-input"
                    value={description}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      if (
                        e.target.value ===
                        'Non Cash Adjustment (No Payment received)'
                      ) {
                        setPaymentMethod('non_cash');
                      }
                      else if (e.target.value === 'Issue Refund') {
                        setPaymentMethod('refund');
                      }
                      else {
                        setPaymentMethod('cash');
                      }
                    }}
                  >
                    <option value="">Select Description</option>
                    <option value="Box Fee received ">Box Fee Received </option>
                    <option value="Key Deposit received ">
                      Key Deposit Received{' '}
                    </option>
                    <option value="Renewal Fee">Renewal Fee</option>
                    <option value="Non Cash Adjustment (No Payment received)">
                      Non Cash Adjustment (No Payment received)
                    </option>
                    <option value="Issue Refund">
                      Issue Refund
                    </option>
                  </select>
                </div>
                <div
                  className="error-message position-absolute"
                // style={{ left: '9.5rem' }}
                >
                  {errors.description && (
                    <small className="text-danger">{errors.description}</small>
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-3 mt-2">
              <label className="custom-label">
                Payment Method:<span className="required text-danger">*</span>
              </label>
              <div className="d-flex justify-content-between">
                <Radio.Group
                  value={paymentMethod}
                  onChange={(e) => setPaymentMethod(e.target.value)}
                  className="w-100 fs-4"
                  disabled={
                    description === 'Non Cash Adjustment (No Payment received)'
                  }
                >
                  <Radio value="cash" className="fs-6">
                    Cash
                  </Radio>
                  <Radio value="cc" className="fs-6 text-center">
                    CC
                  </Radio>
                  <Radio value="dc" className="fs-6 text-center">
                    DC
                  </Radio>
                  <Radio value="bt" className="fs-6">
                    BT
                  </Radio>
                  <Radio value="so" className="fs-6">
                    SO
                  </Radio>
                </Radio.Group>
              </div>
            </div>

            {loading ? (
              <div>Loading...</div>
            ) : (
              <div className="btn d-flex justify-content-end">
                <button type="submit" className="btn next-btn">
                  Make Payment
                </button>
              </div>
            )}
          </form>
          <div className="back-button-container">
            <BackButton onClick={handleBackPrevious} />
          </div>
        </>
      )}
    </div>
  );
};

export default MakePayment;
