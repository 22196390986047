// import React, { useState } from 'react';
// import NavbarWithSearchBar from '../reusable/reusableComponent/NavbarWithSearchBar';
// import { Input, Form, message, Card, Button, Tooltip } from 'antd';
// import './client-style/LockerSafeAllocate.css';
// import { getApplicationNumber, sendOtpToEmail, verifyOtp } from './ClientApi';
// import ChooseLockerAndSafe from './ChooseLockerAndSafe';
// import BackButton from '../reusable/reusableComponent/BackButton';
// import { useNavigate } from 'react-router-dom';
// import CopyableText from '../reusable/Common/CopyableText';
// import { BsClipboard, BsClipboardCheck } from 'react-icons/bs';

// const LockerSafeAllocate = () => {
//   const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
//   const [email, setEmail] = useState('');
//   const [otp, setOtp] = useState(['', '', '', '']);
//   const [loading, setLoading] = useState(false);
//   const [applicationNumber, setApplicationNumber] = useState(null);
//   const [isOtpVerified, setIsOtpVerified] = useState(false);
//   const [copied, setCopied] = useState(false);

//   const navigate = useNavigate();

//   // const handleEmailSubmit = async () => {
//   //   setLoading(true);
//   //   const result = await sendOtpToEmail(email);
//   //   setLoading(false);
//   //   if (result.status === 200) {
//   //     setIsEmailSubmitted(true);
//   //     message.success(result.message);
//   //   } else {
//   //     message.error(result.message);
//   //   }
//   // };

//   const handleEmailSubmit = async () => {
//     setLoading(true);
//     try {
//       const response = await getApplicationNumber(email);

//       if (response?.success) {
//         setApplicationNumber(response.applicationNo);
//         message.warning(
//           `This email is already registered. Application Number: ${response.applicationNo}`
//         );
//         setLoading(false);
//         return;
//       }

//       const result = await sendOtpToEmail(email);
//       if (result.status === 200) {
//         setIsEmailSubmitted(true);
//         message.success(result.message);
//       } else {
//         message.error(result.message);
//       }
//     } catch (error) {
//       message.error('An error occurred while checking the email.');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleOtpSubmit = async () => {
//     if (otp.join('').length === 4) {
//       setLoading(true);
//       const otpCode = otp.join('');
//       const result = await verifyOtp(email, otpCode);
//       setLoading(false);
//       if (result?.success) {
//         message.success(result.message);
//         setIsOtpVerified(true);
//       } else {
//         message.error(result.message);
//       }
//     } else {
//       message.error('Please enter a valid 4-digit OTP!');
//     }
//   };

//   const handleOtpChange = (e, index) => {
//     const newOtp = [...otp];
//     newOtp[index] = e.target.value;
//     setOtp(newOtp);
//   };

//   const handleOtpFocus = (e, index) => {
//     if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
//       document.getElementById(`otp-input-${index - 1}`).focus();
//     } else if (e.key !== 'Backspace' && otp[index] !== '') {
//       if (index < 3) {
//         document.getElementById(`otp-input-${index + 1}`).focus();
//       }
//     }
//   };

//   const handleBack = () => {
//     setIsEmailSubmitted(false);
//     setIsOtpVerified(false);
//     setEmail('');
//     setOtp(['', '', '', '']);
//     setApplicationNumber(null);
//   };

//   const handleBackPrevious = () => {
//     navigate(-1);
//   };

//   const resetFlow = () => {
//     const resetData = {
//       isEmailSubmitted: false,
//       isOtpVerified: false,
//       email: '',
//       otp: ['', '', '', ''],
//     };

//     setIsEmailSubmitted(resetData.isEmailSubmitted);
//     setIsOtpVerified(resetData.isOtpVerified);
//     setEmail(resetData.email);
//     setOtp(resetData.otp);
//   };

//   const handleCopyToClipboard = () => {
//     navigator.clipboard.writeText(applicationNumber);
//     setCopied(true);
//     setTimeout(() => setCopied(false), 2000);
//   };

//   const handleEmailChange = (e) => {
//     const newEmail = e.target.value;
//     setEmail(newEmail);
//     if (applicationNumber) {
//       setApplicationNumber(null);
//     }
//   };

//   return (
//     <div>
//       <NavbarWithSearchBar />
//       <div>
//         {!isOtpVerified ? (
//           !isEmailSubmitted ? (
//             <>
//               <div className="container-card-locker-safe">
//                 <Card className="form-card-locker-safe" hoverable>
//                   <Form onFinish={handleEmailSubmit} layout="vertical">
//                     <Form.Item
//                       label="Email Address"
//                       name="email"
//                       rules={[
//                         {
//                           required: true,
//                           type: 'email',
//                           message: 'Please enter a valid email!',
//                         },
//                       ]}
//                     >
//                       <Input
//                         type="email"
//                         value={email}
//                         onChange={handleEmailChange}
//                         placeholder="Enter your email address"
//                         className="input-field-locker-safe"
//                       />
//                     </Form.Item>
//                     {applicationNumber && (
//                       <div className="email-container-box text-center">
//                         <p className="text-danger">
//                           This email is already registered. Application Number:{' '}
//                         </p>

//                         <div className="copy-container text-center mt-3">
//                           <Tooltip title={copied ? 'Copied!' : 'Click to copy'}>
//                             <div
//                               onClick={handleCopyToClipboard}
//                               className="application-number d-flex align-items-center justify-content-center"
//                               style={{
//                                 cursor: 'pointer',
//                                 fontSize: '1.5rem',
//                                 border: '1px solid #e0e0e0',
//                                 padding: '10px',
//                                 borderRadius: '8px',
//                                 backgroundColor: '#f9f9f9',
//                                 marginBottom: '20px',
//                               }}
//                             >
//                               <span>{applicationNumber}</span>
//                               {copied ? (
//                                 <BsClipboardCheck
//                                   className="ms-2"
//                                   style={{ color: 'green', fontSize: '1.5rem' }}
//                                 />
//                               ) : (
//                                 <BsClipboard
//                                   className="ms-2"
//                                   style={{ fontSize: '1.5rem' }}
//                                 />
//                               )}
//                             </div>
//                           </Tooltip>
//                         </div>
//                       </div>
//                     )}
//                     <Button
//                       type="primary"
//                       htmlType="submit"
//                       className="submit-button"
//                       loading={loading}
//                       disabled={loading}
//                     >
//                       Submit Email
//                     </Button>
//                   </Form>
//                 </Card>
//               </div>
//               <div className="p-2">
//                 <div className="back-button-container">
//                   <BackButton onClick={handleBackPrevious} />
//                 </div>
//               </div>
//             </>
//           ) : (
//             <div className="container-card-locker-safe">
//               <Card className="form-card-locker-safe" hoverable>
//                 <p className="text-center">
//                   Please check your email address:{' '}
//                   <span className="text-danger fw-bold">{email}</span>
//                 </p>
//                 <Form onFinish={handleOtpSubmit} layout="vertical">
//                   <Form.Item
//                     label="Enter OTP"
//                     name="otp"
//                     rules={[
//                       {
//                         required: true,
//                         message: 'Please enter a 4-digit OTP!',
//                       },
//                     ]}
//                   >
//                     <div className="otp-input-container">
//                       {otp.map((digit, index) => (
//                         <Input
//                           key={index}
//                           id={`otp-input-${index}`}
//                           type="text"
//                           maxLength={1}
//                           value={digit}
//                           onChange={(e) => handleOtpChange(e, index)}
//                           onKeyUp={(e) => handleOtpFocus(e, index)}
//                           className="otp-input"
//                           autoFocus={index === 0}
//                         />
//                       ))}
//                     </div>
//                   </Form.Item>
//                   <Button
//                     type="primary"
//                     htmlType="submit"
//                     className="submit-button"
//                     loading={loading}
//                     disabled={loading}
//                   >
//                     Verify OTP
//                   </Button>
//                 </Form>
//               </Card>
//             </div>
//           )
//         ) : (
//           <div className="p-4">
//             <div className="back-button-container">
//               <BackButton onClick={handleBack} />
//             </div>
//             <ChooseLockerAndSafe
//               email={email}
//               isEmailSubmitted={isEmailSubmitted}
//               resetFlow={resetFlow}
//               handleBackPrevious={handleBackPrevious}
//               handleBack={handleBack}
//             />
//           </div>
//         )}
//       </div>
//       {/* <div className="p-2">
//         <div className="back-button-container">
//           <BackButton onClick={handleBackPrevious} />
//         </div>
//       </div> */}
//     </div>
//   );
// };

// export default LockerSafeAllocate;

import React, { useState } from "react";
import NavbarWithSearchBar from "../reusable/reusableComponent/NavbarWithSearchBar";
import { Input, Form, message, Card, Button, DatePicker } from "antd";
import "./client-style/LockerSafeAllocate.css";
import ChooseLockerAndSafe from "./ChooseLockerAndSafe";
import BackButton from "../reusable/reusableComponent/BackButton";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const LockerSafeAllocate = () => {
  const [telephone, setTelephone] = useState("");
  const [dob, setDob] = useState(null);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const navigate = useNavigate();

  const handleNext = () => {
    // Check if at least one field (Telephone or DOB) is filled
    if (!telephone && !dob) {
      message.error(
        "Please fill in at least one of the fields (Telephone or Date of Birth)."
      );
      return;
    }

    // Proceed to the next component if validation passes
    setIsFormSubmitted(true);
  };

  const handleBack = () => {
    setIsFormSubmitted(false);
    setTelephone("");
    setDob(null);
  };

  const handleBackPrevious = () => {
    navigate(-1);
  };

  return (
    <div>
      <NavbarWithSearchBar />
      <div>
        {!isFormSubmitted ? (
          <>
            <div className="container-card-locker-safe">
              <Card className="form-card-locker-safe" hoverable>
                <p
                  style={{
                    fontStyle: "italic",
                    fontWeight: "bold",
                    color: "#555",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    padding: "10px",
                    backgroundColor: "#f9f9f9",
                    textAlign: "center",
                  }}
                >
                  <span style={{ color: "#DC3545" }}>NOTE</span>: You can either
                  select the{" "}
                  <span style={{ color: "#007BFF" }}>telephone number</span>,
                  <span style={{ color: "#28A745" }}>date of birth</span>, or
                  both.
                </p>

                <Form onFinish={handleNext} layout="vertical">
                  <Form.Item
                    label="Telephone"
                    name="telephone"
                    rules={[
                      {
                        required: false,
                        pattern: /^[0-9]{10}$/,
                        message:
                          "Please enter a valid 10-digit telephone number!",
                      },
                    ]}
                  >
                    <Input
                      type="tel"
                      value={telephone}
                      onChange={(e) => setTelephone(e.target.value)}
                      placeholder="Enter your telephone number"
                      className="input-field-locker-safe"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Date of Birth"
                    name="dob"
                    rules={[
                      {
                        required: false,
                        message: "Please select your date of birth!",
                      },
                    ]}
                  >
                    <DatePicker
                      value={dob ? moment(dob) : null}
                      onChange={(date) => setDob(date)}
                      format="DD-MM-YYYY"
                      placeholder="Select your date of birth"
                      className="input-field-locker-safe w-100"
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="submit-button"
                  >
                    Next
                  </Button>
                </Form>
              </Card>
            </div>
            <div className="p-2">
              <div className="back-button-container">
                <BackButton onClick={handleBackPrevious} />
              </div>
            </div>
          </>
        ) : (
          <div className="p-4">
            <div className="back-button-container">
              <BackButton onClick={handleBack} />
            </div>
            <ChooseLockerAndSafe
              telephone={telephone}
              dob={dob}
              handleBackPrevious={handleBackPrevious}
              handleBack={handleBack}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default LockerSafeAllocate;
