import React, { useEffect, useState } from 'react';
import UserDetails from '../reusable/Client/UserDetails';
import NomineeDetails from '../reusable/Client/NomineeDetails';
import { FaUser, FaBoxOpen } from 'react-icons/fa';
import { MdEditDocument } from 'react-icons/md';
import './client-style/NewClientForm.css';
import {
  getClientData,
  getFingerPrint,
  updateClientDetailApi,
  updateUserDetails,
} from './ClientApi';
import RemarkModal from '../reusable/Client/RemarkModal';
import BackButton from '../reusable/reusableComponent/BackButton';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import PaymentProcess from '../reusable/Client/PaymentProcess';

function NewClientForm({ onSuccess }) {
  const [currentStep, setCurrentStep] = useState(0);
  const [clientId, setClientId] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [remark, setRemark] = useState('');
  const [fingerPrintString, setFingerPrintString] = useState('');
  const [formData, setFormData] = useState({
    applicationNo: '',
    title: '',
    foreName: '',
    surName: '',
    postcode: '',
    telephone: '',
    dob: '',
    nationality: '',
    email: '',
    fingerPrint: '',
    street: '',
    town: '',
    clientPic: '',
    identificationDocument: [],
    nomineeOne: {
      nomineeTitle: '',
      nomineeForename: '',
      nomineeSurname: '',
      nomineeDob: '',
      nomineePostcode: '',
      nomineeTel: '',
      nomineeNationality: '',
      nomineeEmail: '',
      nomineeStreet: '',
      nomineeTown: '',
      nomineePic: '',
      identificationDocument: [],
    },
    nomineeTwo: {
      nomineeTitle: '',
      nomineeForename: '',
      nomineeSurname: '',
      nomineeDob: '',
      nomineePostcode: '',
      nomineeTel: '',
      nomineeNationality: '',
      nomineeEmail: '',
      nomineeStreet: '',
      nomineeTown: '',
      nomineePic: '',
      identificationDocument: [],
    },
    feedback: '',
    feedbackRemark: '',
  });
  const [disableFields, setDisableFields] = useState(true);
  const [clientExist, setClientExist] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (formData.applicationNo && formData.applicationNo.length >= 6) {
        try {
          const fingerprint = await getFingerPrint(formData?.applicationNo);
          if (fingerprint?.success) {
            setDisableFields(true);
            setFingerPrintString(fingerprint.fingerPrintString);
            setFormData((prevFormData) => ({
              ...prevFormData,
              fingerPrint: fingerprint.fingerPrintString,
            }));
            toast.success('Fingerprint found, you can now fill the form');
            setDisableFields(false);
          } else {
            toast.error('Invalid Application Number or Fingerprint not found');
            setDisableFields(true);
          }

          const clientData = await getClientData(formData?.applicationNo);
          if (
            clientData &&
            clientData.success &&
            clientData?.status === 200 &&
            clientData?.data?._id
          ) {
            setClientId(clientData?.data?._id);
          } else {
            console.warn('Client ID not available');
          }
          if (clientData && clientData.success && clientData.status === 200) {
            const {
              title,
              foreName,
              surName,
              postcode,
              telephone,
              dob,
              email,
              fingerPrint,
              nationality,
              clientPic,
              street,
              town,
              identificationDocument,
            } = clientData.data;
            setFormData((prevData) => ({
              ...prevData,
              title,
              foreName,
              surName,
              postcode,
              telephone,
              dob,
              email,
              fingerPrint,
              nationality,
              identificationDocument,
              clientPic,
              street,
              town,
            }));
            setRemark(remark);
            setClientExist(true);
          }
        } catch (error) {
          toast.error('Error fetching data. Please try again later.');
          setDisableFields(true);
        }
      }
    };

    fetchData();
  }, [formData.applicationNo]);

  useEffect(() => {
    if (formData.applicationNo === '') {
      setDisableFields(true);
    }
  }, [formData.applicationNo]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F10') {
        event.preventDefault();
        setShowPopup(true);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const nextStep = () => setCurrentStep((prevStep) => prevStep + 1);
  const prevStep = () => setCurrentStep((prevStep) => prevStep - 1);

  const handleChange = (section, field) => (e) => {
    console.log(section,field, e.target.value,"helllllllllllllllllllllllll");
    const value = e.target.value;
    if (section === 'applicationNo' && value.length < 6) {
      setDisableFields(true);
    } else {
      if (section === 'applicationNo' && value.length >= 6) {
        setDisableFields(false);
      }
    }

    if (section && field) {
      setFormData((prevData) => ({
        ...prevData,
        [section]: {
          ...prevData[section],
          [field]: value,
        },
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [section]: value,
      }));
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleRemarkUpdate = async () => {
    try {
      const response = await updateUserDetails({ userId: clientId, remark });
      setFormData((prevData) => ({
        ...prevData,
        remark,
      }));
      setShowPopup(false);
      return response;
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  const steps = [
    {
      name: 'User Details',
      nameDetail: 'Enter Details',
      icon: <FaUser />,
    },
    {
      name: 'Nominee Details',
      nameDetail: 'Enter Nominee Details',
      icon: <MdEditDocument />,
    },
    {
      name: 'Feedback',
      nameDetail: 'Enter Feedback',
      icon: <FaBoxOpen />,
    },
  ];

  const handleSubmit = async () => {
    const dataToSubmit = {
      ...formData,
      userId: clientId,
    };

    try {
      const result = await updateClientDetailApi(dataToSubmit);

      if (result.success) {
        toast.success('Client details updated successfully!');
        onSuccess(formData);
        setCurrentStep(0);
        setFormData({
          applicationNo: '',
          title: '',
          foreName: '',
          surName: '',
          postcode: '',
          telephone: '',
          dob: '',
          nationality: '',
          email: '',
          address: '',
          fingerPrint: '',
          remark: '',
          street: '',
          town: '',
          nomineeOne: {
            title: '',
            foreName: '',
            surName: '',
            dob: '',
            postcode: '',
            telephone: '',
            nationality: '',
            email: '',
            address: '',
            street: '',
            town: '',
          },
          nomineeTwo: {
            title: '',
            foreName: '',
            surName: '',
            dob: '',
            postcode: '',
            telephone: '',
            nationality: '',
            email: '',
            address: '',
            street: '',
            town: '',
          },
          feedback: '',
          feedbackRemark: '',
        });
      } else {
        toast.error('Error updating client details');
      }
    } catch (error) {
      toast.error(error.message || 'Error submitting the form');
      console.error('Error submitting client data:', error);
    }
  };

  const stepComponents = [
    <UserDetails
      nextStep={nextStep}
      handleChange={handleChange}
      formData={formData}
      setFormData={setFormData}
      remark={remark}
      clientExist={clientExist}
      clientId={clientId}
      disableFields={disableFields}
    />,
    <NomineeDetails
      nextStep={nextStep}
      prevStep={prevStep}
      formData={formData}
      setFormData={setFormData}
      handleChange={handleChange}
    />,

    <PaymentProcess
      prevStep={prevStep}
      formData={formData}
      setFormData={setFormData}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
    />,
  ];

  return (
    <div className="stepper-form m-3 p-2">
      <div className="stepper-timeline">
        <div className="connector-line">
          <div
            className="connector-fill"
            style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}
          />
        </div>
        {steps.map((step, index) => (
          <div
            className={`step-container ${currentStep >= index ? 'active' : ''}`}
            key={index}
          >
            <div className="step">
              <div className="icon-circle">{step.icon}</div>
              <div className="step-text">
                <div className="step-name fw-bold">{step.name}</div>
                <div className="step-name-details">{step.nameDetail}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {stepComponents[currentStep]}
      <RemarkModal
        isOpen={showPopup}
        onClose={() => {
          setShowPopup(false);
        }}
        remark={remark}
        onChange={setRemark}
        onSave={handleRemarkUpdate}
        clientId={clientId}
      />

      <div className="back-button-container">
        <BackButton onClick={handleBack} />
      </div>
    </div>
  );
}

export default NewClientForm;
