import axios from 'axios';
import {
  makeBearerJsonApiRequest,
  makeJsonApiRequest,
  makeJsonFormDataApiRequest,
} from '../../services/ApiService';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';

const API_URL = process.env.REACT_APP_API_URL;

// const FingerPrint_API_URL = 'http://3.109.178.47:8443/';
const FingerPrint_API_URL = 'https://localhost:8443/';

export const updateUserDetails = async (dataToSubmit) => {
  try {
    const response = await makeBearerJsonApiRequest(
      'patch',
      `${API_URL}update-user`,
      dataToSubmit
    );
    return response;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || 'Error updating user details'
    );
  }
};

export const updateClientDetails = async (dataToSubmit) => {
  const token = Cookies.get('token');
  try {
    const response = await fetch(`${API_URL}update-user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(dataToSubmit),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Error updating user details');
    }

    return await response.json();
  } catch (error) {
    throw new Error(error.message || 'Error updating user details');
  }
};

export const updateClientDetailApi = async (dataToSubmit) => {
  try {
    const response = await fetch(`${API_URL}update-client-with-details`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataToSubmit),
    });

    const result = await response.json();

    if (!response.ok) {
      throw new Error(result.message || 'Error updating client details');
    }

    return result;
  } catch (error) {
    throw new Error(error.message || 'Error submitting the form');
  }
};

export const uploadImageToAPI = async (formData) => {
  try {
    const response = await makeJsonFormDataApiRequest(
      'post',
      `${API_URL}upload-image`,
      formData
    );

    return response.data.data;
  } catch (error) {
    console.error('Error uploading image to API:', error);
    throw error;
  }
};

export const handleUserDetailsSubmit = async (data) => {
  try {
    const response = await axios.post(`${API_URL}create-user`, data);
    return response.data;
  } catch (error) {
    toast.error('Error submitting user details:', error);
    console.error('Error submitting user details:', error);
    throw error;
  }
};

export const getFingerPrint = async (applicationNo) => {
  try {
    const { data } = await makeJsonFormDataApiRequest(
      'post',
      `${API_URL}get-client-fingerprint`,
      { applicationNo }
    );
    if (data.success && data.status === 200) {
      return data;
    }
    console.error(data.message);
    return null;
  } catch (error) {
    console.error('Error fetching fingerprint data:', error);
    return null;
  }
};

export const getClientData = async (applicationNo) => {
  try {
    const response = await makeJsonFormDataApiRequest(
      'get',
      `${API_URL}/get-client?applicationNo=${applicationNo}`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching client data:', error);
    return null;
  }
};

export const fetchPlans = async (size) => {
  const response = await makeJsonFormDataApiRequest(
    'get',
    `${API_URL}get-plans?planType=locker&size=${size}`,
    {}
  );

  return response.data;
};

export const fetchLockers = async (size) => {
  const response = await makeJsonFormDataApiRequest(
    'get',
    `${API_URL}get-lockers?status=Vacant&size=${encodeURIComponent(size)}`,
    {}
  );

  return response.data;
};

export const fetchSafes = async (size) => {
  try {
    const response = await makeJsonFormDataApiRequest(
      'get',
      `${API_URL}get-safes?status=Vacant&size=${size}`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching safes:', error);
    throw error;
  }
};

export const archiveUserDetails = async (data) => {
  try {
    const response = await makeBearerJsonApiRequest(
      'patch',
      `${API_URL}update-user`,
      data
    );
    return await response;
  } catch (error) {
    console.error('API Error:', error);
    throw error;
  }
};

export const archiveUsers = async (payload) => {
  const params = new URLSearchParams(payload).toString();
  try {
    const response = await makeBearerJsonApiRequest(
      'get',
      `${API_URL}get-all-clients?${params}`
    );
    return await response.data;
  } catch (error) {
    throw error;
  }
};

export const ClientHistoryApI = async (userId) => {
  const endpoint = `${API_URL}get-visit-history?userId=${userId}`;
  try {
    const response = await makeBearerJsonApiRequest('get', endpoint, {});
    return response;
  } catch (error) {
    throw error;
  }
};

export const upgradeLockerPlan = async ({
  userId,
  boxId,
  plan,
  boxType,
  paymentType,
  complimentaryLocker,
  paymentAmount,
  keyDepositDue,
  keyDeposit,
  selectedDate
}) => {
  try {
    const payload = {
      userId,
      boxId,
      plan,
      boxType,
      paymentType,
      paymentAmount,
      keyDepositDue,
      keyDeposit,
      selectedDate
    };

    if (boxType === 'safe' && complimentaryLocker) {
      payload.complimentaryLocker = {
        lockerId: complimentaryLocker.lockerId,
        lockerNumber: complimentaryLocker.lockerNumber,
        column: complimentaryLocker.column,
      };
    }

    const response = await makeBearerJsonApiRequest(
      'post',
      `${API_URL}change-user-plan`,
      payload
    );

    return response;
  } catch (error) {
    console.error('Error upgrading locker plan:', error);
    throw error;
  }
};

export const ClientViewDetailsAPI = async (userId) => {
  const endpoint = `${API_URL}get-client?userId=${userId}`;

  const payload = { isArchive: true };

  const response = await makeBearerJsonApiRequest('GET', endpoint, payload);
  return response;
};

export const fetchAllClientLoginDetails = async () => {
  const endpoint = `${API_URL}get-all-client-login-details`;

  try {
    const response = await makeBearerJsonApiRequest('POST', endpoint, {});
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getReportArchive = async () => {
  const endpoint = `${API_URL}get-report-archive`;

  try {
    const response = await makeJsonApiRequest('POST', endpoint, {});
    if (response?.status === 200) {
      return response?.data;
    } else {
      throw new Error('Failed to fetch the report URL');
    }
  } catch (error) {
    console.error('Error fetching the report:', error);
    throw error;
  }
};

export const captureFingerPrint = async () => {
  try {
    const response = await fetch(`${FingerPrint_API_URL}SGIFPCapture`, {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded',
        Origin: 'https://webapipython.secugen.com',
        Referer: 'https://webapipython.secugen.com/',
        'User-Agent':
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/130.0.0.0 Safari/537.36',
        'sec-ch-ua':
          '"Chromium";v="130", "Google Chrome";v="130", "Not?A_Brand";v="99"',
        'sec-ch-ua-mobile': '?0',
        'sec-ch-ua-platform': '"Windows"',
      },
      body: new URLSearchParams({
        timeout: '10000',
        quality: '50',
        licstr: '',
        templateformat: 'ISO',
        imagewsqrate: '0.75',
      }),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error capturing fingerprint:', error);
    throw new Error('Error connecting to the fingerprint capture service.');
  }
};

export const compareFingerprints = async (
  fingerPrintString,
  capturedFingerPrint
) => {
  try {
    const response = await fetch(`${FingerPrint_API_URL}SGIMatchScore`, {
      method: 'POST',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded',
        Origin: 'https://webapipython.secugen.com',
        Referer: 'https://webapipython.secugen.com/',
        'User-Agent':
          'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/130.0.0.0 Safari/537.36',
        'sec-ch-ua':
          '"Chromium";v="130", "Google Chrome";v="130", "Not?A_Brand";v="99"',
        'sec-ch-ua-mobile': '?0',
        'sec-ch-ua-platform': '"Windows"',
      },
      body: new URLSearchParams({
        licstr: '',
        Template1: fingerPrintString,
        Template2: capturedFingerPrint,
        Templateformat: 'ISO',
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to compare fingerprints: ${response.statusText}`);
    }

    const data = await response.json();

    return data?.MatchingScore || 0;
  } catch (error) {
    console.error('Error comparing fingerprints:', error);
    return 0;
  }
};

// apiService.js

export const withoutFingerScanService = async (apiPayload) => {
  const endpoint = `${API_URL}generate-app-without-fingerprint`;
  try {
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(apiPayload), // Send only the payload without nesting it
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in withoutFingerScanService:', error);
    throw error; // Re-throw the error for the caller to handle
  }
};

export const submitFingerPrintData = async (apiPayload) => {
  const endpoint = `${API_URL}capture-client-fingerprint`;
  try {
    const response = await makeJsonApiRequest('POST', endpoint, apiPayload);
    return response;
  } catch (error) {
    console.error('Error submitting fingerprint data:', error);
    throw new Error('Error submitting fingerprint data.');
  }
};

export const sendOtpToEmail = async (email) => {
  const endpoint = `${API_URL}create-new-client-with-otp`;
  try {
    const response = await makeJsonApiRequest('POST', endpoint, { email });
    if (response.success) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return { success: false, message: 'An error occurred while sending OTP.' };
  }
};

export const verifyOtp = async (email, otp) => {
  const endpoint = `${API_URL}verify-new-client`;

  try {
    const response = await makeJsonApiRequest('POST', endpoint, { email, otp });
    if (response.success) {
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return { success: false, message: 'An error occurred while sending OTP.' };
  }
};

export const getApplicationNumber = async (email) => {
  const endpoint = `${API_URL}get-application-number`;

  try {
    const response = await makeJsonApiRequest('POST', endpoint, { email });
    return response;
  } catch (error) {
    console.error('Error fetching application number:', error);
    return { exists: false, applicationNo: null };
  }
};
