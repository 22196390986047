import React, { useEffect, useState } from 'react';
import {
  Table,
  Pagination,
  message,
  Result,
  Button,
  Spin,
  Input,
  Card,
  Tag,
  Image,
} from 'antd';
import { getArchiveClient } from '../../client-management/API/ClientTableAPI';
import { getVisitHistoryAPI, sendUserEventReport } from '../EventApis';
import BackButton from '../../reusable/reusableComponent/BackButton';
import { useNavigate } from 'react-router-dom';
import 'jspdf-autotable';
import '../EventsStyle/EventTable.css';
import moment from 'moment/moment';
import Swal from 'sweetalert2';
import { Col, Row } from 'react-bootstrap';
import CopyableText from '../../reusable/Common/CopyableText';

const { Search } = Input;

const EventTable = ({ searchTerm }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [clientDatas, setClientDatas] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [visitHistory, setVisitHistory] = useState([]);
  const [showVisitHistory, setShowVisitHistory] = useState(false);
  const [searchTerm1, setSearchTerm1] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    fetchClientData();
  }, []);

  useEffect(() => {
    filterClients();
  }, [searchTerm, searchTerm1, clientDatas]);

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const response = await getArchiveClient();
      if (response.success && response?.data) {
        const clients = response?.data?.clients;
        setClientDatas(clients);
        setFilteredClients(clients);
      } else {
        throw new Error(response?.message || 'Failed to fetch client data');
      }
    } catch (error) {
      console.error('Network Error:', error);
      setError('Failed to fetch client data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const filterClients = () => {
    if (!searchTerm && !searchTerm1) {
      setFilteredClients(clientDatas);
      setPagination({ current: 1, pageSize: pagination.pageSize });
      return;
    }

    const lowerSearchTerm = searchTerm?.toLowerCase() || '';
    const lowerSearchTerm1 = searchTerm1?.toLowerCase() || '';

    const filtered = clientDatas.filter((client) => {
      const combinedString = `
        ${client.foreName || ''} ${client.surName || ''}
        ${client.email || ''} ${client.uccNumber || ''}
        ${client.boxNumber || ''} ${client.postcode || ''}
        ${client.telephone || ''} ${client.street || ''}
        ${client.town || ''}
        ${moment(client.createdAt).format('DD-MM-YYYY')}
        ${moment(client.renewalDate).format('DD-MM-YYYY')}
      `.toLowerCase();

      return (
        combinedString.includes(lowerSearchTerm) &&
        combinedString.includes(lowerSearchTerm1)
      );
    });

    setFilteredClients(filtered);
    setPagination({ current: 1, pageSize: pagination.pageSize });
  };

  const handleShowVisitHistory = async (client) => {
    try {
      const visitHistoryResponse = await getVisitHistoryAPI(client?._id);
      setVisitHistory(visitHistoryResponse);
      setShowVisitHistory(true);
    } catch (error) {
      message.error('Failed to fetch visit history.');
      console.error('Error fetching visit history:', error);
    }
  };

  const handlePrint = () => {
    const printableElement = document.getElementById('printableArea');
    const originalBody = document.body.innerHTML;

    if (printableElement) {
      document.body.innerHTML = printableElement.innerHTML;
      window.print();
      document.body.innerHTML = originalBody;
      window.location.reload();
    } else {
      message.error('Nothing to print');
    }
  };

  const handleBackToClients = () => {
    if (showVisitHistory) {
      setShowVisitHistory(false);
    } else {
      navigate(-1);
    }
  };

  const clientColumns = [
    {
      title: 'CLIENT PIC',
      dataIndex: 'clientPic',
      align: 'center',
      render: (text, client) => (
        <div className="d-flex client-view justify-content-center">
          <img
            src={
              client.clientPic ||
              'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9SRRmhH4X5N2e4QalcoxVbzYsD44C-sQv-w&s'
            }
            alt="profile"
            className="avatar rounded-circle client-avatar"
          />
        </div>
      ),
    },
    {
      title: 'NAME/EMAIL',
      dataIndex: 'foreName',
      align: 'center',
      sorter: (a, b) => {
        const nameA = `${a.foreName} ${a.surName}`.toLowerCase();
        const nameB = `${b.foreName} ${b.surName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
      render: (text, client) => (
        <div className="d-flex client-view justify-content-center">
          <div className="client-details text-center">
            <p className="fw-bold mb-0">
              {`${client.foreName} ${client.surName}` || 'Unknown'}
            </p>
            <p className="client-email fw-normal">{client.email || 'N/A'}</p>
          </div>
        </div>
      ),
    },
    {
      title: 'START DATE',
      dataIndex: 'createdAt',
      align: 'center',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (text) => moment(text).format('DD-MM-YYYY'),
    },
    {
      title: 'UCC NO.',
      dataIndex: 'uccNumber',
      align: 'center',
      render: (text) => <CopyableText text={text} />,
    },
    {
      title: 'STREET',
      dataIndex: 'street',
      align: 'center',
      render: (text) => {
        if (text && text.length > 20) {
          return `${text.substring(0, 20)}...`;
        }
        return text || '---';
      },
    },
    {
      title: 'TOWN',
      dataIndex: 'town',
      align: 'center',
      render: (text) => text || '---',
    },
    {
      title: 'POSTCODE',
      dataIndex: 'postcode',
      align: 'center',
      render: (text) => text || '---',
    },
    {
      title: 'TELEPHONE',
      dataIndex: 'telephone',
      align: 'center',
      render: (text) => text || '---',
    },
    {
      title: 'VIEW',
      align: 'center',
      render: (text, client) => (
        <div>
          <button
            className="btn btn-view-history mb-3"
            onClick={() => {
              handleShowVisitHistory(client);
              setSelectedClient(client);
            }}
          >
            Visit History
          </button>
        </div>
      ),
    },
  ];

  const historyColumns = [
    {
      title: <div className="text-center">LOGIN TIME</div>,
      dataIndex: 'loginTime',
      render: (text) => {
        if (!text) {
          return <div className="text-center">N/A</div>;
        }

        try {
          const date = new Date(text);
          if (isNaN(date.getTime())) {
            throw new Error('Invalid date');
          }
          return (
            <div className="text-center">
              <p className="mb-0 fs-6 fw-medium">{date.toLocaleDateString()}</p>
              <p className="mb-0 fs-7 text-muted">
                {date.toLocaleTimeString()}
              </p>
            </div>
          );
        } catch (error) {
          return <div className="text-center">Invalid Date</div>;
        }
      },
    },
    {
      title: <div className="text-center">LOGOUT TIME</div>,
      dataIndex: 'logoutTime',
      render: (text) => {
        if (!text) {
          return <div className="text-center">N/A</div>;
        }
        try {
          const date = new Date(text);
          if (isNaN(date.getTime())) {
            throw new Error('Invalid date');
          }
          return (
            <div className="text-center">
              <p className="mb-0 fs-6 fw-medium">{date.toLocaleDateString()}</p>
              <p className="mb-0 fs-7 text-muted">
                {date.toLocaleTimeString()}
              </p>
            </div>
          );
        } catch (error) {
          return <div className="text-center">Invalid Date</div>;
        }
      },
    },
    {
      title: <div className="text-center">STATUS</div>,
      dataIndex: 'status',
      render: (text) => {
        if (!text) {
          return (
            <span className="d-block text-center fw-bold text-muted fs-6">
              N/A
            </span>
          );
        }

        const formattedStatus = text
          ? text.replace(/_/g, ' ').toUpperCase()
          : 'N/A';
        const statusClass =
          formattedStatus === 'LOGGED OUT' ? 'text-danger' : 'text-success';

        return (
          <span className={`d-block text-center fw-bold ${statusClass} fs-6`}>
            {formattedStatus}
          </span>
        );
      },
    },
  ];

  const handleMailSend = async () => {
    setLoading(true);

    try {
      if (!visitHistory.length) {
        return message.error('No Visit History Found');
      }
      const result = await sendUserEventReport(visitHistory[0]?.userId);
      if (result.success) {
        Swal.fire({
          icon: 'success',
          title: 'Email Sent',
          text: result.message || 'Email sent successfully!',
          confirmButtonText: 'Close',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          text: result.message || 'Failed to send the document link.',
          confirmButtonText: 'Retry',
        });
      }
    } catch (error) {
      console.error('Error in handleMailSend:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An unexpected error occurred.',
        confirmButtonText: 'Close',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-fluid my-5 p-3">
      <div className="row justify-content-end mb-2">
        <div className="col-sm-12 col-md-6 col-lg-6 w-25">
          <Search
            placeholder="Search by Postcode or Box Number"
            onChange={(e) => setSearchTerm1(e.target.value)}
            enterButton
            allowClear
            value={searchTerm1}
            className="custom-search-bar"
          />
        </div>
      </div>

      <div className="table-responsive">
        {loading ? (
          <div className="loading-container d-flex align-item-center justify-content-center">
            <Spin size="large" />
          </div>
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <>
            <div className="back-button-container">
              <BackButton onClick={handleBackToClients} />
            </div>
            {showVisitHistory ? (
              <div id="printableArea">
                <div className=" d-flex">
                  <button
                    onClick={handleMailSend}
                    className="btn btn-button btn-print-email m-2"
                  >
                    Print via Email
                  </button>
                  <button
                    onClick={handlePrint}
                    className="btn-button btn-print m-2"
                  >
                    Print PDF
                  </button>
                </div>
                <Row gutter={[16, 16]} className="m-0 p-0">
                  <Col lg={12} md={12} sm={12} xs={12}>
                    <Card className="my-2 border-2">
                      <div
                        style={{
                          marginBottom: '20px',
                          textAlign: 'center',
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                      >
                        <Tag
                          color={selectedClient?.isArchived ? 'red' : 'green'}
                          className="fw-bold fs-6 p-2 rounded"
                        >
                          {selectedClient?.isArchived ? 'Archived' : 'Active'}
                        </Tag>
                      </div>
                      <Row>
                        <Col lg={4} md={6} sm={12} xs={12}>
                          <div
                            style={{
                              textAlign: 'center',
                              marginBottom: '16px',
                            }}
                          >
                            <Image
                              src={selectedClient?.clientPic}
                              height={100}
                              width={100}
                            />
                            <h3 style={{ marginTop: '10px' }}>
                              {selectedClient?.foreName}{' '}
                              {selectedClient?.surName}
                            </h3>
                            <p>
                              <strong>UCC NO. </strong>{' '}
                              <CopyableText text={selectedClient?.uccNumber} />
                            </p>
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12}>
                          <div style={{ marginBottom: '20px' }}>
                            <h4>Contact Information</h4>
                            <p>
                              <strong>Email:</strong> {selectedClient?.email}
                            </p>
                            <p>
                              <strong>Phone:</strong>{' '}
                              {selectedClient?.telephone}
                            </p>
                            <p>
                              <strong>Street:</strong> {selectedClient?.street}
                            </p>
                            <p>
                              <strong>Town:</strong> {selectedClient?.town}
                            </p>
                            <p>
                              <strong>Postcode:</strong>{' '}
                              {selectedClient?.postcode}
                            </p>
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12}>
                          <div style={{ marginBottom: '20px' }}>
                            <h4>Box Information</h4>
                            <p>
                              <strong>Box Number:</strong>{' '}
                              {selectedClient?.boxNumber}
                            </p>
                            <p>
                              <strong>Box Size:</strong>{' '}
                              {selectedClient?.boxSize}
                            </p>
                            <p>
                              <strong>Box Type:</strong>{' '}
                              {selectedClient?.boxType}
                            </p>
                            <p>
                              <strong>Plan Duration:</strong>{' '}
                              {selectedClient?.plan} months
                            </p>
                            <p>
                              <strong>Application No:</strong>{' '}
                              {selectedClient?.applicationNo}
                            </p>
                          </div>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]}>
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <h4>Additional Information</h4>
                          <div
                            style={{ marginBottom: '20px' }}
                            className="d-flex justify-content-between"
                          >
                            <p>
                              <strong>Account Balance:</strong> $
                              {selectedClient?.accountBalance}
                            </p>
                            <p>
                              <strong>Renewal Date:</strong>{' '}
                              {moment(selectedClient?.renewalDate).format(
                                'DD-MM-YYYY'
                              )}
                            </p>
                            <p>
                              <strong>Date of Birth:</strong>{' '}
                              {selectedClient?.dob}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Table
                  style={{ textAlign: 'center' }}
                  columns={historyColumns}
                  dataSource={visitHistory}
                  rowKey="_id"
                  loading={loading}
                  pagination={false}
                />
              </div>
            ) : filteredClients.length === 0 ? (
              <Result
                status="404"
                title="No Clients Found"
                subTitle={
                  error || "Sorry, we couldn't find any matching clients."
                }
              />
            ) : (
              <>
                <Table
                  columns={clientColumns}
                  dataSource={filteredClients.slice(
                    (pagination.current - 1) * pagination.pageSize,
                    pagination.current * pagination.pageSize
                  )}
                  pagination={false}
                  rowKey="_id"
                  loading={loading}
                />
                <Pagination
                  current={pagination.current}
                  pageSize={pagination.pageSize}
                  total={filteredClients.length}
                  onChange={(page, pageSize) =>
                    setPagination({ current: page, pageSize })
                  }
                  className="pagination"
                  style={{ marginTop: '20px', textAlign: 'right' }}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EventTable;
