import React, { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./components/auth/Login";
import ForgetPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import ResetPasswordSuccessfull from "./components/auth/ResetPasswordSuccessfull";
import AdminHome from "./components/home/AdminHome";
import {
  AuthProvider,
  ProtectedRoute,
  PublicRoute,
} from "./services/AuthContext";
import {
  requestFCMToken,
  setupOnMessageListener,
} from "./config/firebaseConfig";
import SafesHome from "./components/safes-management/SafesHome";
import EventHome from "./components/event-management/Events/EventHome";
import ClientHome from "./components/client-management/ClientHome";
import LockerHome from "./components/locker-management/locker/LockerHome";
import VerifyOTP from "./components/auth/VerifyOTP";
import HomeFinance from "./components/financial-management/financial/HomeFinance";
import UpgradeLocker from "./components/reusable/Client/UpgradeLocker";
import UpgradeSafe from "./components/reusable/Client/UpgradeSafe";
import RenewalHome from "./components/RenewalEmails/RenewalHome";
import ViewClientVisitHistroy from "./components/client-management/ViewClientVisitHistroy";
import ClientDetailsViewAdmin from "./components/client-management/ClientDetailsViewAdmin";
import LockerSafeAllocate from "./components/client-management/LockerSafeAllocate";
import PaymentCalculator from "./components/payment-management/payment-calc/PaymentCalculator";
import { getLoginData } from "./components/client-management/API/ClientTableAPI";
import { Avatar, Drawer, message } from "antd";
import dayjs from "dayjs";
import "./App.css";
import { clientLogout } from "./components/event-management/EventApis";
import moment from "moment";

const App = () => {
  const [fcmToken, setFcmToken] = useState(null);
  const [usersWithNullLogout, setUsersWithNullLogout] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [formatedDate, setFormatedDate] = useState("");
  const [formatedTime, setFormatedTime] = useState("");

  const usersRef = useRef(usersWithNullLogout);
  const location = useLocation();

  useEffect(() => {
    handleFetchFCMToken();
    setupOnMessageListener();
  }, []);

  useEffect(() => {
    fetchUsersWithNullLogout();

    const timerInterval = setInterval(() => {
      updateRemainingTime();
    }, 1000);

    const pollingInterval = setInterval(() => {
      fetchUsersWithNullLogout();
    }, 10000);

    return () => {
      clearInterval(timerInterval);
      clearInterval(pollingInterval);
    };
  }, []);

  const handleFetchFCMToken = async () => {
    const token = await requestFCMToken();
    setFcmToken(token);
  };

  useEffect(() => {
    let timeStamp = selectedUser?.loginData?.loginTime;
    let date = moment(timeStamp).format("DD-MM-YYYY");
    let time = moment(timeStamp).format("HH:mm:ss");
    setFormatedDate(date);
    setFormatedTime(time);
  }, [selectedUser]);
  const fetchUsersWithNullLogout = async () => {
    try {
      const response = await getLoginData();
      if (response.success && response.data) {
        const filteredUsers = response.data.filter(
          (user) => !user.loginData.logoutTime
        );
        const usersWithTimeData = filteredUsers.map((user) => ({
          ...user,
          remainingTime: calculateTimeLeft(user.loginData.loginTime),
        }));
        usersRef.current = usersWithTimeData;
        setUsersWithNullLogout(usersWithTimeData);
      } else {
        console.error("Error fetching data:", response.message);
      }
    } catch (error) {
      console.error("Network Error:", error.message);
    }
  };
  const formatTime = (timeInSeconds) => {
    if (isNaN(timeInSeconds) || timeInSeconds < 0) {
      return "00:00";
    }
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const calculateTimeLeft = (loginTime) => {
    if (loginTime) {
      const elapsedTime = dayjs().diff(dayjs(loginTime), "seconds");
      return Math.max(3600 - elapsedTime, 0);
    }
    return 3600;
  };

  const updateRemainingTime = () => {
    let shouldFetchData = false;

    setUsersWithNullLogout((prevUsers) => {
      const updatedUsers = prevUsers.map((user) => {
        const remainingTime = calculateTimeLeft(user.loginData.loginTime);

        if (remainingTime <= 0 && user.remainingTime > 0) {
          shouldFetchData = true;
        }

        return {
          ...user,
          remainingTime: Math.max(remainingTime, 0),
        };
      });

      return updatedUsers;
    });

    if (shouldFetchData) {
      fetchUsersWithNullLogout();
    }
  };

  const handleIconClick = (user) => {
    setSelectedUser(user);
    setDrawerVisible(true);
  };

  const publicRoutes = [
    "/",
    "/forgot-password",
    "/reset-password",
    "/verify-otp",
    "/reset-password-successfull",
  ];

  const handleLogout = async () => {
    try {
      if (!selectedUser || !selectedUser?._id) {
        throw new Error("Client ID is not available. Cannot log out.");
      }
      const data = await clientLogout(selectedUser?._id);

      if (data && data?.status === 200) {
        message.success(data.message || "Client successfully logged out");

        setUsersWithNullLogout((prevUsers) =>
          prevUsers.filter((user) => user._id !== selectedUser._id)
        );
        setDrawerVisible(false);
        setSelectedUser(null);
      } else {
        message.error(data?.message || "Logout failed. Please try again.");
      }
    } catch (error) {
      console.error("Error during logout:", error);
      message.error("An error occurred during logout.");
    }
  };

  return (
    <div className="App">
      <AuthProvider>
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password-successfull"
            element={
              <PublicRoute>
                <ResetPasswordSuccessfull />
              </PublicRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <PublicRoute>
                <ResetPassword />
              </PublicRoute>
            }
          />
          <Route
            path="/verify-otp"
            element={
              <PublicRoute>
                <VerifyOTP />
              </PublicRoute>
            }
          />
          <Route
            path="/admin-home"
            element={
              <ProtectedRoute>
                <AdminHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clients"
            element={
              <ProtectedRoute>
                <ClientHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/events"
            element={
              <ProtectedRoute>
                <EventHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/safes"
            element={
              <ProtectedRoute>
                <SafesHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/lockers"
            element={
              <ProtectedRoute>
                <LockerHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/finance-home"
            element={
              <ProtectedRoute>
                <HomeFinance />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upgrade-locker"
            element={
              <ProtectedRoute>
                <UpgradeLocker />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upgrade-safe"
            element={
              <ProtectedRoute>
                <UpgradeSafe />
              </ProtectedRoute>
            }
          />
          <Route
            path="/renewal-mails"
            element={
              <ProtectedRoute>
                <RenewalHome />
              </ProtectedRoute>
            }
          />
          <Route
            path="/visit-history"
            element={
              <ProtectedRoute>
                <ViewClientVisitHistroy />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-client-details-show"
            element={
              <ProtectedRoute>
                <ClientDetailsViewAdmin />
              </ProtectedRoute>
            }
          />

          <Route
            path="/locker-safe-allocate"
            element={
              <ProtectedRoute>
                <LockerSafeAllocate />
              </ProtectedRoute>
            }
          />

          <Route
            path="/payment-calculator"
            element={
              <ProtectedRoute>
                <PaymentCalculator />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>

      {!publicRoutes.includes(location.pathname) && (
        <div
          style={{
            position: "fixed",
            bottom: 20,
            right: 20,
            display: "flex",
            flexDirection: "column",
            gap: 10,
          }}
        >
          {usersWithNullLogout.map((user) => (
            <div
              key={user._id}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* Circle with User's Name */}
              <div
                style={{
                  width: "80px",
                  height: "80px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                  color: "#fff",
                  fontWeight: "bold",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
                onClick={() => handleIconClick(user)}
              >
                {user.foreName}
              </div>

              {/* Remaining Time Display */}
              <div
                style={{
                  marginTop: 5,
                  fontSize: "16px",
                  fontWeight: "bold",
                  // color: user.remainingMinutes > 0 ? "green" : "red",
                  color:"white",
                  width: "60px",
                  display: "flex",
                  justifyContent: "center",
                  height: "25px",
                  borderRadius: "10px",
                  boxShadow: "rgb(233 14 14 / 57%) 0px 3px 14px",
                  backgroundColor:"green"
                }}
              >
                <p>{formatTime(user.remainingTime)}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      <Drawer
        title={<span className="drawer-title">Login Client Details</span>}
        placement="right"
        closable
        onClose={() => setDrawerVisible(false)}
        open={drawerVisible}
        width={400}
        bodyStyle={{ padding: "0", backgroundColor: "#f4f6f8" }}
      >
        {selectedUser ? (
          <div className="user-details-wrapper">
            <div className="user-header  text-center p-4">
              <Avatar
                size={100}
                src={selectedUser.clientPic}
                alt={selectedUser.foreName}
                className="user-avatar mx-auto mb-3"
              />
              <h4 className="user-name mb-1">{`${selectedUser.foreName} ${selectedUser.surName}`}</h4>
            </div>

            <div className="user-info p-4">
              <div className="info-item d-flex justify-content-between align-items-center mb-3">
                <strong>UCC Number:</strong>
                <span>{selectedUser.uccNumber || "---"}</span>
              </div>
              <div className="info-item d-flex justify-content-between align-items-center mb-3">
                <strong>Box Number:</strong>
                <span>{selectedUser.boxNumber || "---"}</span>
              </div>
              <div className="info-item d-flex justify-content-between align-items-center mb-3">
                <strong>Login Date:</strong>
                <span>{formatedDate || "---"}</span>
              </div>
              <div className="info-item d-flex justify-content-between align-items-center mb-3">
                <strong>Login Time:</strong>
                <span>{formatedTime || "---"}</span>
              </div>
              <div className="info-item d-flex justify-content-between align-items-center mb-3">
                <strong>Email:</strong>
                <span>{selectedUser.email || "---"}</span>
              </div>

              <div className="info-item d-flex justify-content-between align-items-center">
                <strong>DOB:</strong>
                <span>{selectedUser.dob || "---"}</span>
              </div>
            </div>
            <div className="user-footer text-center p-3 bg-light">
              <button
                className="btn btn-danger logout-button fw-bold px-4 py-2"
                onClick={handleLogout}
              >
                Log Out
              </button>
            </div>
          </div>
        ) : (
          <div className="no-user-selected text-center p-4">
            <p className="text-muted">No user selected.</p>
          </div>
        )}
      </Drawer>
    </div>
  );
};

export default App;
