import React, { useState } from "react";
import {
  fetchLockers,
  fetchPlans,
  upgradeLockerPlan,
} from "../../client-management/ClientApi";
import { Button, Select } from "antd";
import NavbarWithSearchBar from "../reusableComponent/NavbarWithSearchBar";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../reusableComponent/BackButton";
import HomeFooter from "../../home/HomeFooter/HomeFooter";
import Swal from "sweetalert2";

const UpgradeLocker = () => {
  const location = useLocation();
  const { client } = location.state || {};
  const [selectedBoxType, setSelectedBoxType] = useState("");
  const [selectedDuration, setSelectedDuration] = useState({});
  const [rentalDurations, setRentalDurations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split("T")[0]); 
  const [amounts, setAmounts] = useState({});
  const [plans, setPlans] = useState({});
  const [loading, setLoading] = useState(false);
  const [lockersList, setLockersList] = useState([]);
  const [selectedLockers, setSelectedLockers] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [payloadValue, setPayloadValue] = useState("");
  const [keyDepositDue, setKeyDepositDue] = useState();
  const [selectedOption, setSelectedOption] = useState("due");
  const [keyDeposit, setKeyDeposit] = useState("0");

  const navigate = useNavigate();

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    if (value === "due") {
      setKeyDepositDue(true);
    }
  };

  const handleKeyDepositChange = (e) => {
    setKeyDeposit(e.target.value);
  };

  const boxData = [
    {
      id: 1,
      label: "SMALL (SV65)",
      length: "65cm",
      width: "45cm",
      depth: "50cm",
    },
    {
      id: 2,
      label: "MEDIUM (SV83)",
      length: "83cm",
      width: "60cm",
      depth: "60cm",
    },
    {
      id: 3,
      label: "LARGE (SV83)",
      length: "83cm",
      width: "80cm",
      depth: "70cm",
    },
    {
      id: 4,
      label: "EXTRA LARGE (SV93)",
      length: "93cm",
      width: "90cm",
      depth: "80cm",
    },
  ];

  const mapBoxSizeToApiSize = (label) => {
    switch (label) {
      case "SMALL (SV65)":
        return "Small";
      case "MEDIUM (SV83)":
        return "Medium";
      case "LARGE (SV83)":
        return "Large";
      case "EXTRA LARGE (SV93)":
        return "Extra Large";
      default:
        return "";
    }
  };

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const removePreviousData = (index) => {
    setSelectedDuration((prevDurations) => {
      const updateDuration = Array.isArray(prevDurations)
        ? [...prevDurations]
        : [];
      updateDuration[index] = "";
      return updateDuration;
    });
    setSelectedLockers((prevLockers) => {
      const updatedLockers = Array.isArray(prevLockers) ? [...prevLockers] : [];
      updatedLockers[index] = { lockerNumber: "" };
      return updatedLockers;
    });
    setAmounts({});
  };

  const handleChange = (field, value, index) => {
    if (field === "boxType") {
      setSelectedBoxType(value);

      removePreviousData(field, value, index);

      setSelectedLockers((prev) => ({ ...prev, [index]: "" }));
      setSelectedDuration((prev) => ({ ...prev, [index]: "" }));
      setAmounts((prev) => ({ ...prev, [index]: "" }));
      setRentalDurations([]);
      fetchLockersForBoxSize(value, index);
      fetchPlanDetails(value);
    }
  };

  const handleAmountChange = (event, index) => {
    const newAmount = event.target.value;
    setAmounts((prevAmounts) => ({
      ...prevAmounts,
      [index]: newAmount,
    }));
  };

  const fetchLockersForBoxSize = async (size, index) => {
    try {
      setLoading(true);
      const apiSize = mapBoxSizeToApiSize(size);
      const lockersResponse = await fetchLockers(apiSize);
      if (lockersResponse.success) {
        const lockers = lockersResponse.data.lockers;
        setLockersList(lockers);
        setSelectedLockers((prevLockers) => {
          const updatedLockers = Array.isArray(prevLockers)
            ? [...prevLockers]
            : [];
          updatedLockers[index] = lockers[0] || { lockerNumber: "" };
          return updatedLockers;
        });
      }
    } catch (error) {
      console.error("Error fetching lockers:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPlanDetails = async (boxType) => {
    try {
      setLoading(true);
      const apiSize = mapBoxSizeToApiSize(boxType);
      const data = await fetchPlans(apiSize);
      if (data.success) {
        const planData = data.data[0];
        setPlans(planData.price);
        setRentalDurations(Object.keys(planData.price));
      }
    } catch (err) {
      console.error("Failed to fetch plans:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDurationChange = (event, index) => {
    const duration = event.target.value;
    setSelectedDuration((prevDurations) => ({
      ...prevDurations,
      [index]: duration,
    }));
    setAmounts((prevAmounts) => ({
      ...prevAmounts,
      [index]: plans[duration] || "",
    }));
  };

  const handleLockerChange = (value, index) => {
    const selectedLocker = lockersList.find(
      (locker) => locker.lockerNumber === value
    );
    setSelectedLockers((prevLockers) => ({
      ...prevLockers,
      [index]: selectedLocker || {},
    }));
  };

  const handleUpgrade = async () => {
    const lockerIndex = Object.keys(selectedLockers).find(
      (key) => selectedLockers[key]
    );

    const selectedLocker = selectedLockers[lockerIndex];
    const selectedPlan = selectedDuration[lockerIndex];
    const paymentAmount = amounts[lockerIndex];

    if (!selectedBoxType) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please select a box size.",
      });
      return;
    }

    if (!selectedLocker) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please select a locker.",
      });
      return;
    }

    if (!selectedPlan) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please select a rental duration.",
      });
      return;
    }

    if (!paymentAmount) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter the amount.",
      });
      return;
    }

    if (!selectedOption) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please choose a Key Deposit option.",
      });
      return;
    }

    Swal.fire({
      icon: "success",
      title: "Success",
      text: `You selected "${selectedOption}" with deposit value: ${keyDeposit}`,
    });

    if (!keyDeposit) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please enter the key deposit amount.",
      });
      return;
    }

    if (!paymentMethod) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please select a payment method.",
      });
      return;
    }

    const payload = {
      userId: client?._id,
      boxId: selectedLocker._id,
      plan: selectedPlan,
      boxType: mapBoxSizeToApiSize(selectedBoxType),
      boxType: "locker",
      paymentType: payloadValue,
      paymentAmount: paymentAmount,
      keyDeposit: parseInt(keyDeposit),
      keyDepositDue: keyDepositDue,
      selectedDate: selectedDate,
    };

    console.log(payload,"payload ins sksks ksksks ksksks ksks s");

    setLoading(true);
    try {
      const response = await upgradeLockerPlan(payload);

      if (response.success && response.status) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.message,
        });
        navigate("/clients");
      } else {
        Swal.fire({
          icon: "error",
          title: "Upgrade Failed",
          html: `<span style="color: red; font-weight: bold;">${
            response.message || "Upgrade failed."
          }</span>`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred during upgrade.",
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const paymentMethodMap = {
    Cash: "cash",
    DC: "dc",
    CC: "cc",
    BT: "bt",
    SO: "so",
  };

  const handlePaymentMethodChange = (e) => {
    const selectedMethod = e.target.value;
    setPaymentMethod(selectedMethod);
    const value = paymentMethodMap[selectedMethod];
    setPayloadValue(value);
  };

  return (
    <>
      <div>
        <NavbarWithSearchBar />

        <div className="table-responsive mt-3 p-5">
          <form className={loading ? "blur-background" : ""}>
            <div className="client-details-container">
              <h3 className="fw-bold text-uppercase" style={{ color: "#333" }}>
                Upgrade Locker
              </h3>

              <p className="fw-bold fs-5">
                Account Balance: {"  "}
                <span
                  className={`fs-4 ${
                    client?.accountBalance < 0 ? "text-danger" : "text-success"
                  }`}
                  style={{ fontWeight: "bold" }}
                >
                  £{client?.accountBalance?.toLocaleString()}
                </span>
              </p>
            </div>

            <div className="d-flex justify-content-end w-100 mb-4">
              <div className="start-date-container w-25 text-end">
                <label htmlFor="startDate" className="form-label fw-bold">
                  Select Date:
                </label>
                <input
                  type="date"
                  id="startDate"
                  className="form-control"
                  max={new Date().toISOString().split("T")[0]}
                  value={selectedDate}
                  onChange={(e) => setSelectedDate(e.target.value)} 
                />
              </div>
            </div>

            <table className="table box-size-table">
              <thead>
                <tr>
                  <th className="box-size-head">Box Size</th>
                  <th className="box-size-head">Length</th>
                  <th className="box-size-head">Width</th>
                  <th className="box-size-head">Depth</th>
                  <th className="box-size-head">Duration</th>
                  <th className="box-size-head">Amount</th>
                  <th className="box-size-head">Locker Number</th>
                </tr>
              </thead>
              <tbody className="table-body-boxrental">
                {boxData.map((box, index) => (
                  <tr key={box.id}>
                    <td className="box-size-col">
                      <div className="form-check">
                        <input
                          className="form-check-input radio-button-box-sizes"
                          type="radio"
                          name="boxType"
                          id={`boxType${index}`}
                          value={box.label}
                          checked={selectedBoxType === box.label}
                          onChange={(e) =>
                            handleChange("boxType", e.target.value, index)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`boxType${index}`}
                        >
                          {box.label}
                        </label>
                      </div>
                    </td>
                    <td className="box-size-col">{box.length}</td>
                    <td className="box-size-col">{box.width}</td>
                    <td className="box-size-col">{box.depth}</td>
                    <td className="box-size-col">
                      <select
                        className="form-select"
                        aria-label="Select rental duration"
                        name={`rentalDuration${index}`}
                        value={selectedDuration[index] || ""}
                        onChange={(e) => handleDurationChange(e, index)}
                        disabled={selectedBoxType !== box.label}
                      >
                        <option value="">--Select--</option>
                        {rentalDurations.map((duration, idx) => (
                          <option key={idx} value={duration}>
                            {duration} Months
                          </option>
                        ))}
                      </select>
                    </td>
                    <td className="box-size-col">
                      <input
                        type="text"
                        className="form-control custom-input"
                        name={`amount${index}`}
                        value={amounts[index] || ""}
                        onChange={(e) => handleAmountChange(e, index)}
                      />
                    </td>
                    <td className="box-size-col">
                      <Select
                        key={selectedLockers[index]?.lockerNumber || ""}
                        className="form-control custom-input"
                        value={selectedLockers[index]?.lockerNumber || ""}
                        onChange={(value) => handleLockerChange(value, index)}
                        options={lockersList?.map((locker) => ({
                          label: locker?.lockerNumber,
                          value: locker?.lockerNumber,
                        }))}
                        disabled={selectedBoxType !== box.label}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div>
              <div className="row align-items-center p-2 mt-2">
                <div className="col-sm-2 col-lg-2 col-md-2">
                  <label className="custom-label">Key Deposit:</label>
                </div>
                <div className="col-sm-2 col-lg-2 col-md-2">
                  <label className="fs-6">
                    <input
                      type="radio"
                      name="keyDepositDue"
                      value="due"
                      checked={selectedOption === "due"}
                      onChange={handleRadioChange}
                    />{" "}
                    Key Deposit Due
                  </label>
                </div>
                <div className="col-sm-4 col-lg-8 col-md-8">
                  {selectedOption && (
                    <input
                      id="keyDeposit"
                      name="keyDeposite"
                      className="form-control custom-input"
                      type="text"
                      placeholder="Key Deposit"
                      value={keyDeposit}
                      onChange={handleKeyDepositChange}
                    />
                  )}
                </div>{" "}
              </div>
            </div>

            <h2 className="header-text mt-4 mb-4">Payment Process of Locker</h2>

            <div className="d-flex flex-wrap">
              {["Cash", "DC", "CC", "BT", "SO"].map((method, index) => (
                <div className="form-check me-2" key={index}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="paymentMethod"
                    id={`payment${method.replace(/\s+/g, "")}`}
                    value={method}
                    checked={paymentMethod === method}
                    onChange={handlePaymentMethodChange}
                  />
                  <label
                    className="security-form-label fw-bolder"
                    htmlFor={`payment${method.replace(/\s+/g, "")}`}
                  >
                    {method}
                  </label>
                </div>
              ))}
            </div>
          </form>

          <Button
            onClick={handleUpgrade}
            disabled={
              loading ||
              !selectedBoxType ||
              !Object.keys(selectedLockers).length ||
              !Object.keys(selectedDuration).length ||
              !Object.keys(amounts).length ||
              !keyDeposit ||
              !paymentMethod ||
              !selectedOption
            }
            className="float-end btn-view-detail p-0 fs-6 m-2"
            style={{ width: "160px" }}
          >
            {loading ? "Upgrading..." : "Complete Upgrade"}
          </Button>
        </div>
        <div className="back-button-container">
          <BackButton onClick={handleBackButtonClick} />
        </div>
      </div>
      <HomeFooter />
    </>
  );
};

export default UpgradeLocker;
