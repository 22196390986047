import React, { useEffect, useState } from 'react';
import {
  archiveUserDetails,
  ClientViewDetailsAPI,
  updateUserDetails,
} from './ClientApi';
import RemarkModal from '../reusable/Client/RemarkModal';
import BackButton from '../reusable/reusableComponent/BackButton';
import { Button, Image, Modal, Pagination } from 'antd';
import DefaultProfileImage from '../../assets/user.jpg';

import { ImForward } from 'react-icons/im';
import NomineeDetailsShow from './NomineeDetailsShow';
import Swal from 'sweetalert2';

const ClientDetailsShow = ({ clientDetails, onClose }) => {
  const [formData, setFormData] = useState({});
  const [showArchiveTable, setShowArchiveTable] = useState(false);
  const [clientImageShow, setClientImageShow] = useState(null);
  const [identificationDocument, setIdentificationDocument] = useState();
  const [documents, setDocuments] = useState({
    passport: false,
    driversLicense: false,
    bankStatement: false,
    councilTax: false,
    utilityBill: false,
  });
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [remark, setRemark] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState('');
  const itemsPerPage = 5;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = formData.transactionHistory
    ? formData.transactionHistory.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'F10') {
        event.preventDefault();
        setShowPopup(true);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const showDocument = (doc) => {
    setSelectedDocument(doc);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedDocument(null);
  };

  const fetchedData = async () => {
    try {
      const response = await ClientViewDetailsAPI(clientDetails?._id);
      if (response.success && response.data) {
        const client = response.data;
        setFormData(client);
        setIdentificationDocument(client.identificationDocument || []);
        setClientImageShow(client.clientPic);
        setRemark(client?.remark);
      } else {
        console.error('API Error:', response.message);
      }
    } catch (error) {
      console.error('error: ', error);
      throw error;
    }
  };

  useEffect(() => {
    fetchedData();
  }, [clientDetails]);

  const handleShowArchiveTable = async () => {
    const payload = {
      userId: clientDetails._id,
      isArchived: true,
    };
    setIsLoading(true);
    try {
      const response = await archiveUserDetails(payload);
      if (response.success) {
        Swal.fire({
          title: 'Success!',
          text: response.message,
          icon: 'success',
          confirmButtonText: 'OK',
        });
        setShowArchiveTable(true);
      } else {
        Swal.fire({
          title: 'Error!',
          html: `<span style="color: red; font-weight: bold;">${response.message}</span>`,
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      console.error('API error during archiving:', error);
    }
  };

  const handleRemarkUpdate = async () => {
    try {
      const response = await updateUserDetails({
        userId: clientDetails?._id,
        remark,
      });
      setFormData((prevData) => ({
        ...prevData,
        remark,
      }));
      setShowPopup(false);
      return response;
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  return (
    <div className="user-details-container mt-5 p-3">
      {!showArchiveTable && (
        <div>
          <div className="header mb-4 d-flex justify-content-between">
            <div>
              <h4 className="header-text fs-3">CLIENT HOLDER DETAILS</h4>
            </div>
            <button
              className="btn btn-view-detail mb-3 m-2 fs-6"
              onClick={handleShowArchiveTable}
              type="button"
              style={{ width: '200px' }}
            >
              Move to Archive <ImForward className="mr-3 fs-5" />
            </button>
          </div>

          <form>
            <div className="d-flex align-items-start p-3 border rounded bg-light mb-3">
              <div className="me-4">
                {formData.clientPic ? (
                  <div>
                    <Image
                      src={formData.clientPic}
                      alt="Client Profile Picture"
                      className="img-thumbnail"
                      style={{
                        minWidth: '120px',
                        height: '120px',
                        borderRadius: '10px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                ) : (
                  <Image
                    src={DefaultProfileImage}
                    alt="Client Profile Picture"
                    className="img-thumbnail"
                    style={{
                      minWidth: '120px',
                      height: '120px',
                      borderRadius: '10px',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </div>

              <div>
                <div className="info-block mb-4">
                  <h3 className="fs-6 fw-bold text-uppercase text-secondary mb-1">
                    UCC Number
                  </h3>
                  <p className="info-value fs-5 mb-0">
                    {formData?.uccNumber || (
                      <span className="text-muted">Not Available</span>
                    )}
                  </p>
                </div>

                {/* Application Number */}
                <div className="info-block">
                  <h3 className="fs-6 fw-bold text-uppercase text-secondary mb-1">
                    Application Number
                  </h3>
                  <p className="info-value fs-5 mb-0">
                    {formData?.applicationNo || (
                      <span className="text-muted">Not Available</span>
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="mb-3 d-flex align-items-center">
                  <label htmlFor="title" className="custom-label">
                    Title:
                  </label>
                  <input
                    id="title"
                    name="title"
                    type="text"
                    className="form-control custom-input"
                    placeholder="Title"
                    value={formData.title || ''}
                    readOnly
                  />
                </div>
                <div className="mb-3 d-flex align-items-center">
                  <label htmlFor="forenames" className="custom-label">
                    Forenames:
                  </label>
                  <input
                    id="foreName"
                    name="foreName"
                    type="text"
                    className="form-control custom-input"
                    placeholder="Forenames"
                    value={formData.foreName}
                    readOnly
                  />
                </div>

                <div className="mb-3 d-flex align-items-center">
                  <label htmlFor="tel" className="custom-label">
                    Tel:
                  </label>
                  <input
                    id="telephone"
                    name="telephone"
                    type="text"
                    className="form-control custom-input"
                    placeholder="Telephone"
                    value={formData.telephone}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3 d-flex align-items-center">
                  <label htmlFor="dob" className="custom-label">
                    DOB:
                  </label>
                  <input
                    id="dob"
                    name="dob"
                    type="text"
                    className="form-control custom-input"
                    value={formData.dob}
                    readOnly
                  />
                </div>
                <div className="mb-3 d-flex align-items-center">
                  <label htmlFor="surnames" className="custom-label">
                    Surnames:
                  </label>
                  <input
                    id="surName"
                    name="surName"
                    type="text"
                    className="form-control custom-input"
                    placeholder="Surnames"
                    value={formData.surName}
                    readOnly
                  />
                </div>

                <div className="mb-3 d-flex align-items-center">
                  <label htmlFor="email" className="custom-label">
                    Email:
                  </label>
                  <input
                    id="email"
                    name="email"
                    className="form-control custom-input"
                    type="text"
                    placeholder="Email"
                    value={formData.email}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-6  mb-3 d-flex align-items-center">
                <label className="custom-label">Street:</label>
                <input
                  id="street"
                  name="street"
                  className="form-control custom-input"
                  type="text"
                  placeholder="street"
                  value={formData.street}
                  readOnly
                />
              </div>
              <div className="col-md-6  mb-3 d-flex align-items-center">
                <label htmlFor="town" className="custom-label">
                  Town:
                </label>
                <input
                  id="town"
                  name="town"
                  type="text"
                  className="form-control custom-input"
                  placeholder="town"
                  value={formData.town}
                  readOnly
                />
              </div>
            </div>
            <div className="row d-flex">
              <div className="col-md-6  mb-3 d-flex align-items-center">
                <label htmlFor="postcode" className="custom-label">
                  Postcode:
                </label>
                <input
                  id="postcode"
                  name="postcode"
                  type="text"
                  className="form-control custom-input"
                  placeholder="Postcode"
                  value={formData.postcode}
                  readOnly
                />
              </div>

              <div className="col-md-6 mb-3 d-flex align-items-center">
                <label htmlFor="nationality" className="custom-label">
                  Nationality:
                </label>

                <input
                  value={formData.nationality}
                  className="form-control custom-input"
                  readOnly
                />
              </div>
            </div>
            <div className="d-flex align-items-center  mb-3">
              <label className="custom-label">Remark:</label>
              <input
                id="remark"
                name="remark"
                className="form-control custom-input"
                type="text"
                placeholder="Remark"
                value={formData.remark}
                readOnly
              />
            </div>

            <div className="mt-4">
              <h5 className="mb-2 fw-bold">Identification Documents</h5>
              {formData?.identificationDocument?.length > 0 ? (
                <div className="document-list">
                  {formData.identificationDocument.map((doc, index) => (
                    <div
                      key={index}
                      className="document-card p-3 mb-3 d-flex justify-content-between align-items-center shadow-sm rounded"
                    >
                      <div>
                        <p className="fs-6 mb-1">
                          <strong>Document Name:</strong> {doc.documentType}
                        </p>
                      </div>
                      <Button
                        type="primary"
                        className="rounded fw-bold px-4"
                        onClick={() => showDocument(doc)}
                      >
                        View
                      </Button>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="fw-bold text-muted text-center">
                  No Documents Available
                </p>
              )}

              <Modal
                title={
                  <span className="fs-5 fw-bold">
                    {selectedDocument?.documentType || 'Document'}
                  </span>
                }
                open={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                centered
                width={450}
              >
                {selectedDocument ? (
                  <div className="text-center">
                    <Image
                      src={selectedDocument.documentUrl}
                      alt={selectedDocument.documentType}
                      style={{
                        width: '100%',
                        maxWidth: '350px',
                        height: 'auto',
                        borderRadius: '8px',
                      }}
                      className="mb-3"
                    />
                    <Button type="default" onClick={handleCancel}>
                      Close
                    </Button>
                  </div>
                ) : (
                  <p className="text-center text-muted">No Document Selected</p>
                )}
              </Modal>
            </div>

            <div className="m-2">
              <NomineeDetailsShow formData={formData} />
            </div>
            <div>
              <div className="mt-5 table-responsive">
                <h6 className="header-text">PAYMENT HISTORY</h6>
                <table className="table table-bordered table-hover text-center">
                  <thead>
                    <tr>
                      <th>Payment Method</th>
                      <th>Details</th>
                      <th>Debit</th>
                      <th>Credit</th>
                      <th>Balance</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTransactions.length > 0 ? (
                      currentTransactions.map((transaction, index) => (
                        <tr key={index}>
                          <td>
                            {transaction?.paymentMethod
                              ? transaction?.paymentMethod
                              : '---'}
                          </td>
                          <td>
                            {transaction?.details
                              ? transaction?.details
                              : '---'}
                          </td>

                          <td className="text-danger fw-bold">
                            {transaction?.debit && transaction?.debit !== 0
                              ? transaction.debit
                              : "-"}
                          </td>
                          <td>
                            {transaction?.credit && transaction?.credit !== 0
                              ? transaction.credit
                              : "-"}
                          </td>
                          <td
                            className={
                              transaction?.balance < 0
                                ? 'text-danger fw-bold'
                                : ''
                            }
                          >
                            {transaction?.balance && transaction?.balance !== 0
                              ? transaction.balance
                              : 0}
                          </td>
                          <td>
                            {new Date(
                              transaction.createdAt
                            ).toLocaleDateString('en-GB')}
                            <p>
                              {new Date(
                                transaction.createdAt
                              ).toLocaleTimeString()}
                            </p>
                          </td>
                          <td>
                            {new Date(
                              transaction.updatedAt
                            ).toLocaleDateString('en-GB')}
                            <p>
                              {new Date(
                                transaction.updatedAt
                              ).toLocaleTimeString()}
                            </p>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          Not Found Payment History
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="d-flex justify-content-center mt-3">
                  <Pagination
                    current={currentPage}
                    pageSize={itemsPerPage}
                    total={
                      formData.transactionHistory
                        ? formData.transactionHistory.length
                        : 0
                    }
                    onChange={handleChangePage}
                    className="pagination"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5">
              <div className="d-flex justify-content-between mb-3">
                <h6 className="header-text">LOCKER / SAFE</h6>
              </div>

              <div className="table-responsive">
                <table className="table box-size-table text-center">
                  <thead>
                    <tr>
                      <th className="box-size-head">Box Type</th>
                      <th className="box-size-head">Box Size</th>
                      <th className="box-size-head">Box Number</th>
                      {formData?.boxType !== 'safe' && (
                        <th className="box-size-head">Column</th>
                      )}{' '}
                      <th className="box-size-head">Status</th>
                      {formData?.boxType === 'safe' && (
                        <>
                          <th className="box-size-head">Compl. Locker No.</th>
                          <th className="box-size-head">Column</th>
                        </>
                      )}
                      <th className="box-size-head">Duration</th>
                      <th className="box-size-head">Amount</th>
                    </tr>
                  </thead>
                  <tbody className="table-body-boxrental">
                    {formData ? (
                      <tr>
                        <td className="box-size-col">
                          {formData?.boxType || 'N/A'}
                        </td>
                        <td className="box-size-col">
                          {formData?.boxSize || 'N/A'}
                        </td>
                        <td className="box-size-col">
                          {formData?.boxNumber || 'N/A'}
                        </td>
                        {formData?.boxType !== 'safe' && (
                          <td className="box-size-col">
                            {formData.locker?.column || 'N/A'}
                          </td>
                        )}
                        <td className="box-size-col">
                          {formData?.locker?.status
                            ? formData?.locker?.status
                            : formData?.safe?.status}
                        </td>
                        {formData?.boxType === 'safe' && (
                          <>
                            <td className="box-size-col">
                              {formData?.complimentaryLocker?.lockerNumber ||
                                'N/A'}
                            </td>
                            <td className="box-size-col">
                              {formData?.complimentaryLocker?.column || 'N/A'}
                            </td>
                          </>
                        )}
                        <td className="box-size-col">
                          {formData?.plan || 'N/A'} months
                        </td>
                        <td className="box-size-col">
                          {formData?.paymentAmount || 'N/A'}
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">
                          No Locker Information Available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </form>
        </div>
      )}

      {showArchiveTable && onClose()}
      <div className="back-button-container">
        <BackButton onClick={onClose} />
      </div>

      <RemarkModal
        isOpen={showPopup}
        onClose={() => setShowPopup(false)}
        remark={remark}
        onChange={setRemark}
        onSave={handleRemarkUpdate}
        clientId={clientDetails?._id}
      />
    </div>
  );
};

export default ClientDetailsShow;
