export const validateUserDetails = (formData) => {
  const errors = {};
  const currentDate = new Date();

  if (!formData || typeof formData !== 'object') {
    errors.formData = 'Form data is required';
    return errors;
  }

  if (!formData.applicationNo) {
    errors.applicationNo = 'Application no is required';
  }
  if (!formData.title) errors.title = 'Title is required';

  if (!formData.foreName) {
    errors.foreName = 'Forenames are required';
  } else if (!/^[a-zA-Z\s]+$/.test(formData.foreName)) {
    errors.foreName = 'Forenames must contain only letters';
  }

  if (!formData.surName) {
    errors.surName = 'Surnames are required';
  } else if (!/^[a-zA-Z\s]+$/.test(formData.surName)) {
    errors.surName = 'Surnames must contain only letters';
  }
  if (!formData.postcode) {
    errors.postcode = 'Postcode is required';
  } else if (!/^\w{1,4}\s?\d{1,2}|\d{1,2}\s?\w{1,4}$/.test(formData.postcode)) {
    errors.postcode = 'Invalid postcode format';
  }

  if (!formData.telephone) {
    errors.telephone = 'Telephone number is required';
  } else if (!/^\d{10,15}$/.test(formData.telephone)) {
    errors.telephone =
      'Telephone number must be 10 to 15 digits without special characters';
  }

  if (!formData.dob) {
    errors.dob = 'Date of birth is required';
  } else if (new Date(formData.dob) > currentDate) {
    errors.dob = 'Date of birth cannot be in the future';
  }

  if (!formData.nationality) errors.nationality = 'Nationality is required';

  if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = 'Email is invalid';
  }

  if (!formData.street) errors.street = 'Street is required';
  if (!formData.town) errors.town = 'Town is required';

  return errors;
};

export const validateNomineeDetails = (
  formData,
  nomineeTwo,
  documents,
  identificationDocument,
  nomineeTwoDocuments,
  nomineeTwoIdentificationDocument
) => {
  const errors = {};
  const currentDate = new Date();

  const isAnyFieldFilled = (nominee) =>
    Object.values(nominee).some(
      (value) =>
        (typeof value === 'string' && value.trim() !== '') ||
        (typeof value === 'number' && !isNaN(value))
    );

  if (isAnyFieldFilled(formData)) {
    // if (!formData.nomineePic) {
    //   errors.nomineeOnePic = 'Please upload a nominee picture.';
    // }

    // const isDocumentSelected = Object.keys(documents).some(
    //   (key) => documents[key] === true
    // );
    // const isDocumentUploaded = identificationDocument.length > 0;

    // if (isDocumentSelected || isDocumentUploaded) {
    //   if (!isDocumentUploaded) {
    //     errors.documents = 'Please upload an identification document.';
    //   }
    // } else {
    //   errors.documents = 'At least one document must be selected or uploaded.';
    // }

    if (!formData.nomineeTitle) errors.title = 'Title is required';

    if (!formData.nomineeForename) {
      errors.forenames = 'Forenames are required';
    } else if (!/^[a-zA-Z\s]+$/.test(formData.nomineeForename)) {
      errors.forenames = 'Forenames must contain only letters';
    }

    if (!formData.nomineeSurname) {
      errors.surnames = 'Surnames are required';
    } else if (!/^[a-zA-Z\s]+$/.test(formData.nomineeSurname)) {
      errors.surnames = 'Surnames must contain only letters';
    }

    if (!formData.nomineePostcode) {
      errors.postcode = 'Postcode is required';
    } else if (
      !/^\w{1,4}\s?\d{1,2}|\d{1,2}\s?\w{1,4}$/.test(formData.nomineePostcode)
    ) {
      errors.postcode = 'Invalid postcode format';
    }

    if (!formData.nomineeTel) {
      errors.telephone = 'Telephone number is required';
    } else if (!/^\d{10,15}$/.test(formData.nomineeTel)) {
      errors.telephone = 'Invalid phone number format';
    }

    if (!formData.nomineeDob) {
      errors.dob = 'Date of birth is required';
    } else if (new Date(formData.nomineeDob) > currentDate) {
      errors.dob = 'Date of birth cannot be in the future';
    }

    if (!formData.nomineeNationality)
      errors.nationality = 'Nationality is required';

    if (!formData.nomineeEmail) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.nomineeEmail)) {
      errors.email = 'Email is invalid';
    }

    if (!formData.nomineeStreet) errors.street = 'Street is required';
    if (!formData.nomineeTown) errors.town = 'Town is required';
  }
  if (isAnyFieldFilled(nomineeTwo)) {
    if (
      nomineeTwo.nomineeForename ||
      nomineeTwo.nomineeSurname ||
      nomineeTwo.nomineeDob ||
      nomineeTwo.nomineeTel ||
      nomineeTwo.nomineeEmail ||
      nomineeTwo.nomineePostcode ||
      nomineeTwo.nomineeNationality ||
      nomineeTwo.nomineeTitle ||
      nomineeTwo.nomineeStreet ||
      nomineeTwo.nomineeTown
    ) {
      // if (!nomineeTwo.nomineePic) {
      //   errors.nomineeTwoPic =
      //     'Please upload a nominee picture for nominee two.';
      // }

      // const isNomineeTwoDocumentSelected = Object.keys(
      //   nomineeTwoDocuments
      // ).some((key) => nomineeTwoDocuments[key] === true);
      // const isNomineeTwoDocumentUploaded =
      //   nomineeTwoIdentificationDocument.length > 0;

      // if (isNomineeTwoDocumentSelected || isNomineeTwoDocumentUploaded) {
      //   if (!isNomineeTwoDocumentUploaded) {
      //     errors.nomineeTwoDocuments =
      //       'Please upload an identification document for nominee two.';
      //   }
      // } else {
      //   errors.nomineeTwoDocuments =
      //     'At least one document must be selected or uploaded for nominee two.';
      // }

      if (!nomineeTwo.nomineeTitle) {
        errors.nomineeTwoTitle = 'Nominee Two Title is required.';
      }

      if (!nomineeTwo.nomineeDob) {
        errors.nomineeTwoDob = 'Date of birth is required';
      } else if (new Date(nomineeTwo.nomineeDob) > currentDate) {
        errors.nomineeTwoDob = 'Date of birth cannot be in the future';
      }

      if (!nomineeTwo.nomineeForename) {
        errors.nomineeTwoForename = 'Forename is required';
      } else if (!/^[a-zA-Z\s]+$/.test(nomineeTwo.nomineeForename)) {
        errors.nomineeTwoForename = 'Forename must contain only letters';
      }

      if (!nomineeTwo.nomineeSurname) {
        errors.nomineeTwoSurname = 'Surname is required';
      } else if (!/^[a-zA-Z\s]+$/.test(nomineeTwo.nomineeSurname)) {
        errors.nomineeTwoSurname = 'Surname must contain only letters';
      }

      if (!nomineeTwo.nomineeTel) {
        errors.nomineeTwoTel = 'Telephone number is required';
      } else if (!/^\d{10,15}$/.test(nomineeTwo.nomineeTel)) {
        errors.nomineeTwoTel = 'Invalid phone number format';
      }

      if (!nomineeTwo.nomineeEmail) {
        errors.nomineeTwoEmail = 'Email is required';
      } else if (!/\S+@\S+\.\S+/.test(nomineeTwo.nomineeEmail)) {
        errors.nomineeTwoEmail = 'Email is invalid';
      }

      if (!nomineeTwo.nomineePostcode) {
        errors.nomineeTwoPostcode = 'Postcode is required';
      } else if (
        !/^\w{1,4}\s?\d{1,2}|\d{1,2}\s?\w{1,4}$/.test(
          nomineeTwo.nomineePostcode
        )
      ) {
        errors.nomineeTwoPostcode = 'Invalid postcode format';
      }

      if (!nomineeTwo.nomineeNationality) {
        errors.nomineeTwoNationality = 'Nationality is required';
      }

      if (!nomineeTwo.nomineeStreet) {
        errors.nomineeTwoStreet = 'Street is required';
      }
      if (!nomineeTwo.nomineeTown) {
        errors.nomineeTwoTown = 'Town is required';
      }
    }
  }
  return errors;
};

export const validateSecuritySafe = (formData) => {
  const errors = {};

  if (!formData.safeNo) {
    errors.safeNo = 'Safe number is required.';
  }

  if (!formData.keyDeposit) {
    errors.keyDeposit = 'Key deposit is required.';
  }

  if (!formData.years) {
    errors.years = 'Please select the number of years.';
  }

  if (!formData.depositBox) {
    errors.depositBox = 'Deposit box number is required.';
  }

  if (!formData.total) {
    errors.total = 'Total amount is required.';
  }

  return errors;
};

export const validatePaymentProcess = (feedback, otherFeedback) => {
  const errors = {};

  if (feedback.length === 0) {
    errors.feedback = 'Please select one feedback option.';
  }

  if (feedback.includes('other') && !otherFeedback) {
    errors.otherFeedback = 'Please specify your feedback.';
  }

  return errors;
};
