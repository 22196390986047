import React, { useState } from 'react';
import {
  captureFingerPrint,
  fetchLockers,
  fetchPlans,
  submitFingerPrintData,
  withoutFingerScanService,
} from '../../client-management/ClientApi';
import { Button, Image, message, Modal, Spin, Tooltip } from 'antd';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { BsClipboard, BsClipboardCheck } from 'react-icons/bs';
import { CheckCircleOutlined } from '@ant-design/icons';

const BoxSizeRental = ({
  boxizeRental,
  clientId,
  email,
  handleBack,
  dob,
  telephone,
}) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [rentalDuations, setRentalDurations] = useState([]);
  const [plans, setPlans] = useState({});
  const [selectedDuration, setSelectedDuration] = useState();
  const [amounts, setAmounts] = useState({});
  const [selectedLocker, setSelectedLocker] = useState('');
  const [selectedDurations, setSelectedDurations] = useState({});
  const [lockers, setLockers] = useState([]);
  const [boxSizeValue, setBoxSizeValue] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [applicationNo, setApplicationNo] = useState('');
  const [copied, setCopied] = useState(false);
  const [fingerPrintImage, setFingerPrintImage] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [loadingWithoutScan, setLoadingWithoutScan] = useState(false);

  const [formData, setFormData] = useState({
    userId: clientId || localStorage.getItem('clientIdLocal'),
    boxType: 'locker',
    paymentType: '',
    plan: '',
    paymentAmount: '',
    boxId: '',
    keyDeposite: '',
    keyDepositDue: '',
  });

  const boxData = [
    {
      id: 1,
      label: 'SMALL (SV65)',
      length: '65cm',
      width: '45cm',
      depth: '50cm',
    },
    {
      id: 2,
      label: 'MEDIUM (SV83)',
      length: '83cm',
      width: '60cm',
      depth: '60cm',
    },
    {
      id: 3,
      label: 'LARGE (SV83)',
      length: '83cm',
      width: '80cm',
      depth: '70cm',
    },
    {
      id: 4,
      label: 'EXTRA LARGE (SV93)',
      length: '93cm',
      width: '90cm',
      depth: '80cm',
    },
  ];

  const mapBoxSizeToApiSize = (label) => {
    switch (label) {
      case 'SMALL (SV65)':
        return 'Small';
      case 'MEDIUM (SV83)':
        return 'Medium';
      case 'LARGE (SV83)':
        return 'Large';
      case 'EXTRA LARGE (SV93)':
        return 'Extra Large';
      default:
        return '';
    }
  };

  const handleBoxTypeChange = async (selectedBoxType, index) => {
    setSelectedDurations({});
    setAmounts({});

    const apiSize = mapBoxSizeToApiSize(selectedBoxType);
    setBoxSizeValue(apiSize);

    try {
      await fetchLockersForBoxSize(apiSize);
      await fetchPlanDetails(apiSize);
    } catch (error) {
      console.error('Error handling radio button change:', error);
    }
  };

  const fetchLockersForBoxSize = async (apiSize) => {
    try {
      setLoading(true);
      const lockersResponse = await fetchLockers(apiSize);
      if (lockersResponse.success) {
        const lockerOptions = lockersResponse.data.lockers.map((locker) => ({
          value: locker._id,
          label: locker.lockerNumber,
          boxSize: apiSize,
        }));
        setFormData((prev) => ({
          ...prev,
          boxId: lockerOptions.length > 0 ? lockerOptions[0].value : '',
        }));
        setLockers(lockerOptions);
        const preSelectedLocker = lockerOptions.find(
          (locker) => locker.value === boxizeRental?.boxId
        );
        setSelectedLocker(preSelectedLocker || null);
      }
    } catch (error) {
      console.error('Error fetching lockers:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchPlanDetails = async (apiSize) => {
    try {
      setLoading(true);
      const data = await fetchPlans(apiSize);
      if (data.success) {
        const planData = data.data[0];
        setPlans(planData.price);
        setRentalDurations({});
        setRentalDurations((prev) => ({
          ...prev,
          [apiSize]: Object.keys(planData.price),
        }));
      } else {
        setError('Failed to fetch plans.');
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleDurationChange = (e, apiSize) => {
    const selectedValue = parseInt(e.target.value, 10);
    const amountValue = (plans[selectedValue] || '').toString();

    setFormData((prev) => ({
      ...prev,
      plan: selectedValue,
    }));

    setFormData((prev) => {
      return {
        ...prev,
        paymentAmount: amountValue,
      };
    });

    setSelectedDurations((prev) => ({
      ...prev,
      [apiSize]: selectedValue,
    }));

    setAmounts((prev) => ({
      ...prev,
      [apiSize]: amountValue,
    }));
  };

  const validateForm = () => {
    let isValid = true;
    let errorMessage = '';

    if (!formData.plan) {
      isValid = false;
      errorMessage = 'Please select a plan.';
    }

    if (!formData.paymentAmount) {
      isValid = false;
      errorMessage = 'Please enter the amount.';
    }

    if (!formData.boxId) {
      isValid = false;
      errorMessage = 'Please select a locker number.';
    }

    if (!formData.keyDeposite) {
      isValid = false;
      errorMessage = 'Please enter the key deposit amount.';
    }

    if (!formData.paymentType) {
      isValid = false;
      errorMessage = 'Please select a payment type.';
    }

    setError(errorMessage);

    if (!isValid) {
      toast.error('Please fill in all required fields.');
    }
    return isValid;
  };

  const handleStartNowClick = async () => {
    setLoading(true);

    if (!validateForm()) {
      toast.error(error);
      setLoading(false);
      return;
    }
    try {
      const captureData = await captureFingerPrint();
      setFingerPrintImage(captureData?.BMPBase64);

      if (captureData && captureData.TemplateBase64) {
        const apiPayload = {
          fingerPrintRaw: {
            ...captureData,
            BMPBase64: undefined,
            WSQImageSize: undefined,
          },
          fingerPrintString: captureData.TemplateBase64,
          boxId: formData.boxId,
          boxType: formData.boxType,
          plan: formData.plan,
          paymentAmount: parseInt(formData.paymentAmount),
          paymentType: formData.paymentType,
          complimentaryLocker: formData.complimentaryLocker,
          keyDeposite: parseInt(formData.keyDeposite),
          keyDepositDue: formData.keyDepositDue,
          // email: email,
          dob: dob,
          telephone: telephone,
        };

        const submitData = await submitFingerPrintData(apiPayload);

        if (submitData && submitData?.data) {
          setApplicationNo(submitData?.data?.applicationNo);
          setIsModalVisible(true);
        }
      }
    } catch (error) {
      alert('Error connecting to the capture services.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(applicationNo);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleModalOpen = () => {
    const isValid = validateForm();
    if (isValid) {
      setIsModalVisible(true);
    }
  };

  const handleReset = () => {
    setSelectedDuration('');
    setSelectedDurations({});
    setAmounts({});
    setSelectedLocker(null);
  };

  const paymentMethodMap = {
    Cash: 'cash',
    DC: 'dc',
    CC: 'cc',
    BT: 'bt',
    SO: 'so',
  };

  const handleBackToPrevious = () => {
    handleBack();
    setIsModalVisible(false);
  };

  const handleWithoutFingerScanClick = async () => {
    setLoadingWithoutScan(true);

    try {
      const apiPayload = {
        boxId: formData.boxId,
        boxType: formData.boxType,
        plan: formData.plan,
        paymentAmount: parseInt(formData.paymentAmount),
        paymentType: formData.paymentType,
        complimentaryLocker: formData.complimentaryLocker,
        keyDeposite: parseInt(formData.keyDeposite),
        keyDepositDue: formData.keyDepositDue,
        email: email,
        dob: dob,
        telephone: telephone,
      };

      const data = await withoutFingerScanService(apiPayload);

      if (data.success) {
        message.success(data.message);
        setApplicationNo(data?.data?.applicationNo);
      } else {
        message.error(data.message);
      }
    } catch (error) {
      message.error('Something went wrong!');
    } finally {
      setLoadingWithoutScan(false);
    }
  };

  return (
    <div className="mt-5 p-3 w-100">
      <div className="d-flex justify-content-between mb-3">
        <h6 className="header-text">BOX SIZE AND RENTAL PERIOD</h6>
        <div>
          <button
            onClick={handleReset}
            className="btn skip-btn me-2"
            style={{ background: '#adaba5' }}
          >
            <i className="fa-solid fa-arrows-rotate me-2" />
            Reset
          </button>
        </div>
      </div>
      <p className="text-danger">{error}</p>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner-container">
            <Spin size="large" />
          </div>
        </div>
      )}
      <div className="">
        <form className={loading ? 'blur-background' : ''}>
          <table className="table table-responsive box-size-table text-center">
            <thead>
              <tr>
                <th className="box-size-head">Box Size</th>
                <th className="box-size-head">Length</th>
                <th className="box-size-head">Width</th>
                <th className="box-size-head">Depth</th>
                <th className="box-size-head">Duration</th>
                <th className="box-size-head">Amount</th>
                <th className="box-size-head">Locker Number</th>
              </tr>
            </thead>
            <tbody className="table-body-boxrental">
              {boxData.map((box, index) => (
                <tr key={box.id}>
                  <td className="box-size-col">
                    <div className="form-check">
                      <input
                        className="form-check-input radio-button-box-sizes"
                        type="radio"
                        name="boxType"
                        id={`boxType${index}`}
                        value={box.label}
                        onChange={(e) => handleBoxTypeChange(e.target.value)}
                        checked={
                          boxSizeValue === mapBoxSizeToApiSize(box.label)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`boxType${index}`}
                      >
                        {box.label}
                      </label>
                    </div>
                  </td>
                  <td className="box-size-col">{box.length}</td>
                  <td className="box-size-col">{box.width}</td>
                  <td className="box-size-col">{box.depth}</td>
                  <td className="box-size-col">
                    <select
                      className="form-select"
                      aria-label="Select rental duration"
                      name={`rentalDuration${index}`}
                      value={selectedDurations[box.label] || ''}
                      onChange={(e) => handleDurationChange(e, box.label)}
                      disabled={boxSizeValue !== mapBoxSizeToApiSize(box.label)}
                    >
                      <option value="">--Select--</option>
                      {Object.keys(plans).map((duration) => (
                        <option key={duration} value={duration}>
                          {duration} Months
                        </option>
                      ))}
                    </select>
                  </td>

                  <td className="box-size-col">
                    <input
                      type="text"
                      placeholder="Enter amount"
                      className="form-control custom-input"
                      name={`amount${index}`}
                      value={amounts[box.label] || ''}
                      disabled={boxSizeValue !== mapBoxSizeToApiSize(box.label)}
                      onChange={(e) => {
                        const newAmount = e.target.value;

                        setAmounts((prev) => ({
                          ...prev,
                          [box.label]: newAmount,
                        }));

                        setFormData((prev) => {
                          return {
                            ...prev,
                            paymentAmount: newAmount,
                          };
                        });
                      }}
                    />
                  </td>

                  <td className="box-size-col">
                    <Select
                      options={lockers.filter(
                        (locker) =>
                          locker.boxSize === mapBoxSizeToApiSize(box.label)
                      )}
                      className="text-start"
                      isDisabled={
                        boxSizeValue !== mapBoxSizeToApiSize(box.label)
                      }
                      value={
                        selectedLocker &&
                        selectedLocker.boxSize ===
                          mapBoxSizeToApiSize(box.label)
                          ? selectedLocker
                          : null
                      }
                      onChange={(selectedOption) => {
                        setSelectedLocker(selectedOption);
                        setFormData((prev) => ({
                          ...prev,
                          boxId: selectedOption ? selectedOption.value : '',
                        }));
                      }}
                      placeholder="Select Locker"
                      isSearchable={true}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="row align-items-center p-2">
            <div className="col-sm-2 col-lg-2 col-md-2">
              <label className="custom-label">Key Deposit:</label>
            </div>

            <div className="col-sm-2 col-lg-2 col-md-2">
              <label className="fs-6">
                <input
                  type="radio"
                  name="keyDepositDue"
                  value="due"
                  checked={selectedOption === 'due'}
                  onChange={(e) => {
                    const value = e.target.value;
                    setSelectedOption(value);
                    setFormData((prev) => ({
                      ...prev,
                      keyDepositDue: true,
                    }));
                  }}
                />{' '}
                Key Deposit Due
              </label>
            </div>
            <div className="col-sm-4 col-lg-8 col-md-8">
              {selectedOption && (
                <input
                  id="keyDeposit"
                  name="keyDeposite"
                  className="form-control custom-input"
                  type="text"
                  placeholder="Key Deposit"
                  value={formData.keyDeposite}
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setFormData((prev) => ({
                      ...prev,
                      keyDeposite: value,
                    }));
                  }}
                />
              )}
            </div>
          </div>

          <h2 className="header-text mt-4 mb-4 fs-6">
            Payment Process of Locker
          </h2>

          <div className="d-flex gap-5 flex-wrap mb-5">
            {['Cash', 'DC', 'CC', 'BT', 'SO'].map((method, index) => (
              <div key={index} className="form-check form-check-inline fw-bold">
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  id={`payment${method.replace(/\s+/g, '')}`}
                  value={method}
                  onChange={(e) => {
                    const selectedMethod = e.target.value;
                    const mappedValue = paymentMethodMap[selectedMethod];
                    setFormData((prevState) => ({
                      ...prevState,
                      paymentType: mappedValue,
                    }));
                  }}
                />
                <label
                  className="form-check-label"
                  id={`payment${method.replace(/\s+/g, '')}`}
                >
                  {method}
                </label>
              </div>
            ))}
          </div>
        </form>
      </div>
      <div className="stepper-navigation">
        <button
          onClick={handleModalOpen}
          className="btn next-btn fw-bold"
          loading={loading}
        >
          Submit{' '}
        </button>
      </div>
      {/* <Modal
        title="Fingerprint Scanner"
        open={isModalVisible}
        onCancel={handleBackToPrevious}
        footer={[
          <Button key="close" onClick={handleBackToPrevious}>
            Close
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          {!applicationNo && (
            <div className="d-flex justify-content-around">
              <Button
                type="primary"
                onClick={handleStartNowClick}
                style={{ width: '200px', height: '50px', fontSize: '16px' }}
              >
                {loading ? 'Scanning...' : 'Scan Fingerprint'}{' '}
              </Button>
              <Button
                type="default"
                onClick={handleWithoutFingerScanClick}
                style={{ width: '200px', height: '50px', fontSize: '16px' }}
              >
                {loadingWithoutScan ? 'Processing...' : 'Without Finger Scan'}
              </Button>
            </div>
          )}
          {applicationNo ? (
            <div style={{ marginTop: '20px' }}>
              <CheckCircleOutlined
                style={{ fontSize: '48px', color: 'green' }}
              />
              <p>Fingerprint scanned successfully!</p>

              <div className="copy-container text-center mt-3">
                <Tooltip title={copied ? 'Copied!' : 'Click to copy'}>
                  <div
                    onClick={handleCopyToClipboard}
                    className="application-number d-flex align-items-center justify-content-center"
                    style={{
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      border: '1px solid #e0e0e0',
                      padding: '10px',
                      borderRadius: '8px',
                      backgroundColor: '#f9f9f9',
                      marginBottom: '20px',
                    }}
                  >
                    <span>{applicationNo}</span>
                    {copied ? (
                      <BsClipboardCheck
                        className="ms-2"
                        style={{ color: 'green', fontSize: '1.5rem' }}
                      />
                    ) : (
                      <BsClipboard
                        className="ms-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    )}
                  </div>
                </Tooltip>

                {fingerPrintImage && (
                  <div>
                    <h4>Fingerprint Image:</h4>
                    <Image
                      src={`data:image/bmp;base64,${fingerPrintImage}`}
                      width={'100px'}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <p>No application number available.</p>
          )}
        </div>
      </Modal> */}
      <Modal
        title="Fingerprint Scanner"
        open={isModalVisible}
        onCancel={handleBackToPrevious}
        footer={[
          <Button key="close" onClick={handleBackToPrevious}>
            Close
          </Button>,
        ]}
      >
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          {!applicationNo && (
            <div className="d-flex justify-content-around gap-2">
              <button
                onClick={handleStartNowClick}
                className="custom-button print-button"
              >
                {loading ? 'Scanning...' : 'Scan Fingerprint'}
              </button>

              <button
                className="custom-button email-button"
                onClick={handleWithoutFingerScanClick}
              >
                {loadingWithoutScan ? 'Processing...' : 'Without Finger Scan'}
              </button>
            </div>
          )}
          {applicationNo ? (
            <div style={{ marginTop: '20px' }}>
              <CheckCircleOutlined
                style={{ fontSize: '48px', color: 'green' }}
              />
              <p>Application processed successfully!</p>

              <div className="copy-container text-center mt-3">
                <Tooltip title={copied ? 'Copied!' : 'Click to copy'}>
                  <div
                    onClick={handleCopyToClipboard}
                    className="application-number d-flex align-items-center justify-content-center"
                    style={{
                      cursor: 'pointer',
                      fontSize: '1.5rem',
                      border: '1px solid #e0e0e0',
                      padding: '10px',
                      borderRadius: '8px',
                      backgroundColor: '#f9f9f9',
                      marginBottom: '20px',
                    }}
                  >
                    <span>{applicationNo}</span>
                    {copied ? (
                      <BsClipboardCheck
                        className="ms-2"
                        style={{ color: 'green', fontSize: '1.5rem' }}
                      />
                    ) : (
                      <BsClipboard
                        className="ms-2"
                        style={{ fontSize: '1.5rem' }}
                      />
                    )}
                  </div>
                </Tooltip>

                {fingerPrintImage && (
                  <div>
                    <h4>Fingerprint Image:</h4>
                    <Image
                      src={`data:image/bmp;base64,${fingerPrintImage}`}
                      width={'100px'}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <p>No application number available.</p>
          )}
        </div>
      </Modal>
      ;
    </div>
  );
};

export default BoxSizeRental;
