import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Swal from 'sweetalert2';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const vapidKey = process.env.REACT_APP_VAPID_KEY;

const messaging = getMessaging(app);

export const requestFCMToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const currentToken = await getToken(messaging, { vapidKey });
      if (currentToken) {
        localStorage.setItem('webToken', JSON.stringify(currentToken));
        return currentToken;
      } else {
        console.error(
          'No registration token available. Request permission to generate one.'
        );
        return null;
      }
    } else {
      console.error('Notification permission not granted.');
      return null;
    }
  } catch (error) {
    console.error('Error getting FCM token:', error);
    return null;
  }
};

export const setupOnMessageListener = () => {
  onMessage(messaging, (payload) => {
    const { title, body } = payload.notification || {};

    if (title && body) {
      Swal.fire({
        title: title,
        text: body,
        icon: 'info',
        showConfirmButton: false,
        showCloseButton: true,  
      });
    } else {
      console.warn('Notification data missing');
    }
  });
};
