import React, { useEffect, useState } from 'react';
import { Image, Pagination, Result, Spin, Input } from 'antd';
import BackButton from '../reusable/reusableComponent/BackButton';
import { fetchAllClientLoginDetails } from './ClientApi';
import { useNavigate } from 'react-router-dom';
import { BsFillCaretDownFill, BsFillCaretUpFill } from 'react-icons/bs';
import moment from 'moment';
import CopyableText from '../reusable/Common/CopyableText';
import DefaultClientProfile from '../../assets/user.jpg';

const { Search } = Input;

const AllClientVisitHistory = ({ searchTerm }) => {
  const [searchTerm1, setSearchTerm1] = useState('');
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState({
    name: null,
    createdAt: null,
  });
  const navigate = useNavigate();
  const pageSize = 10;

  useEffect(() => {
    const loadClients = async () => {
      try {
        const data = await fetchAllClientLoginDetails();
        setClients(data);
        setFilteredData(data);
      } catch (err) {
        setError(err.message || 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    loadClients();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const lowerSearchTerm = searchTerm.toLowerCase();
      const filtered = clients.filter((client) => {
        const fullName = `${client.foreName || ''} ${
          client.surName || ''
        }`.toLowerCase();
        return (
          fullName.includes(lowerSearchTerm) ||
          (client.uccNumber &&
            String(client.uccNumber).toLowerCase().includes(lowerSearchTerm)) ||
          (client.applicationNo &&
            String(client.applicationNo)
              .toLowerCase()
              .includes(lowerSearchTerm)) ||
          (client.telephone &&
            String(client.telephone).toLowerCase().includes(lowerSearchTerm)) ||
          (client.dob &&
            moment(client.dob)
              .format('DD-MM-YYYY')
              .includes(lowerSearchTerm)) ||
          (client.createdAt &&
            moment(client.createdAt)
              .format('DD-MM-YYYY')
              .includes(lowerSearchTerm)) ||
          (client.boxNumber &&
            String(client.boxNumber).toLowerCase().includes(lowerSearchTerm))
        );
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(clients);
    }
  }, [searchTerm, clients]);

  useEffect(() => {
    if (searchTerm1) {
      const lowerSearchTerm1 = searchTerm1.toLowerCase();
      const filtered = clients.filter((client) => {
        const fullName = `${client.foreName || ''} ${
          client.surName || ''
        }`.toLowerCase();
        return (
          client.email?.toLowerCase().includes(lowerSearchTerm1) ||
          client.telephone?.toLowerCase().includes(lowerSearchTerm1) ||
          client.uccNumber?.toLowerCase().includes(lowerSearchTerm1) ||
          client.boxNumber?.toLowerCase().includes(lowerSearchTerm1) ||
          fullName.includes(lowerSearchTerm1) ||
          client.street?.toLowerCase().includes(lowerSearchTerm1) ||
          client.town?.toLowerCase().includes(lowerSearchTerm1) ||
          (client.dob &&
            moment(client.dob)
              .format('DD-MM-YYYY')
              .includes(lowerSearchTerm1)) ||
          (client.dob &&
            moment(client.createdAt)
              .format('DD-MM-YYYY')
              .includes(lowerSearchTerm1))
        );
      });

      setFilteredData(filtered);
    } else {
      setFilteredData(clients);
    }
  }, [searchTerm1, clients]);

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const toggleSortOrder = (field) => {
    const currentOrder = sortOrder[field];
    const newOrder = currentOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder({ ...sortOrder, [field]: newOrder });
    sortData(field, newOrder);
  };

  const sortData = (field, order) => {
    const sortedClients = [...filteredData];
    if (field === 'name') {
      sortedClients.sort((a, b) => {
        const nameA = `${a.foreName || ''} ${a.surName || ''}`.toLowerCase();
        const nameB = `${b.foreName || ''} ${b.surName || ''}`.toLowerCase();
        return order === 'asc'
          ? nameA.localeCompare(nameB)
          : nameB.localeCompare(nameA);
      });
    } else if (field === 'createdAt') {
      sortedClients.sort((a, b) => {
        const dateA = moment(a.createdAt).unix();
        const dateB = moment(b.createdAt).unix();
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      });
    }
    setFilteredData(sortedClients);
  };

  const paginatedClients = filteredData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleBackPrevious = () => {
    navigate(-1);
  };

  const formatData = (logoutTime, loginTime) => {
    if (!logoutTime || !loginTime) return 'N/A';
    const duration = moment.duration(
      moment(logoutTime).diff(moment(loginTime))
    );
    const hours = String(duration.hours()).padStart(2, '0');
    const minutes = String(duration.minutes()).padStart(2, '0');
    const seconds = String(duration.seconds()).padStart(2, '0');
    return `${hours}:${minutes}:${seconds}`;
  };

  const handleNavigateVisitHistory = (clientId) => {
    navigate('/visit-history', { state: { clientId } });
  };

  return (
    <div className="container-fluid my-3">
      <div className="back-button-container">
        <BackButton onClick={handleBackPrevious} />
      </div>
      <div className="d-flex justify-content-end align-items-center">
        <Search
          placeholder="Search clients by name, email, or other details"
          allowClear
          value={searchTerm1}
          onChange={(e) => setSearchTerm1(e.target.value)}
          enterButton
          className="search-input"
        />
      </div>

      <div className="table-responsive">
        {loading ? (
          <Spin className="d-flex justify-content-center align-items-center" />
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <>
            <table className="table client-table">
              <thead>
                <tr>
                  <th scope="col">CLIENT</th>
                  <th scope="col" onClick={() => toggleSortOrder('name')}>
                    NAME/EMAIL
                    {sortOrder.name === 'asc' ? (
                      <BsFillCaretUpFill className="m-2 sort-icon" />
                    ) : (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    )}
                  </th>
                  <th scope="col">CONTACT NO.</th>
                  <th scope="col" className="px-0">
                    UCC NO.
                  </th>
                  <th scope="col" className="px-0">
                    BOX NO.
                  </th>
                  <th scope="col" onClick={() => toggleSortOrder('createdAt')}>
                    DATE
                    {sortOrder.createdAt === 'asc' ? (
                      <BsFillCaretUpFill className="m-2 sort-icon" />
                    ) : (
                      <BsFillCaretDownFill className="m-2 sort-icon" />
                    )}
                  </th>
                  <th scope="col">LOG IN</th>
                  <th scope="col">LOG OUT</th>
                  <th scope="col">DURATION</th>
                  <th scope="col">VIEW HISTROY</th>
                </tr>
              </thead>
              <tbody>
                {paginatedClients.length > 0 ? (
                  paginatedClients.map((client) => (
                    <tr key={client._id}>
                      <td>
                        <Image
                          src={
                            client.clientPic || DefaultClientProfile
                          }
                          alt="profile"
                          className="avatar rounded-circle client-avatar"
                          width={60}
                          height={60}
                        />
                      </td>
                      <td>
                        <p className="client-name fw-bold fs-6 mb-0">
                          {`${client?.foreName || 'Unknown'} ${
                            client?.surName || ''
                          }`}
                        </p>
                        <p className="client-email">{client?.email || '---'}</p>
                      </td>
                      <td>{client?.telephone || '---'}</td>
                      <td>
                        <CopyableText text={client.uccNumber} />
                      </td>
                      <td>{client?.boxNumber || '---'}</td>
                      <td>{moment(client?.createdAt).format('DD-MM-YYYY')}</td>
                      <td>
                        {client?.visitHistory?.[0]?.loginTime
                          ? moment(client?.visitHistory[0].loginTime).format(
                              'HH:mm:ss'
                            )
                          : '---'}
                      </td>
                      <td>
                        {client.visitHistory?.[0]?.logoutTime
                          ? moment(client?.visitHistory[0].logoutTime).format(
                              'HH:mm:ss'
                            )
                          : '---'}
                      </td>
                      <td>
                        {client.visitHistory?.[0]
                          ? formatData(
                              client?.visitHistory[0].logoutTime,
                              client?.visitHistory[0].loginTime
                            )
                          : '---'}
                      </td>
                      <td>
                        <button
                          className="btn btn-view-history"
                          onClick={() => handleNavigateVisitHistory(client._id)}
                        >
                          Visit History
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={8}>
                      <Result
                        status="404"
                        title="No Clients Found"
                        subTitle={error || 'Sorry, no matching clients found.'}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <Pagination
              className="pagination"
              current={currentPage}
              pageSize={pageSize}
              total={filteredData.length}
              onChange={handlePaginationChange}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AllClientVisitHistory;
