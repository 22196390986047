import { toast } from 'react-toastify';
import {
  makeBearerJsonApiRequest,
  makeJsonApiRequest,
} from '../../services/ApiService';

const API_URL = process.env.REACT_APP_API_URL;

export const getUpcommingRenewal = async (startDate, endDate) => {
  const endpoint = `${API_URL}get-all-upcomming-renewals`;
  try {
    const response = await makeBearerJsonApiRequest('POST', endpoint, {
      startDate,
      endDate,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

export const generateReport = async (startDate, endDate) => {
  const endpoint = `${API_URL}generate-report`;
  try {
    const response = await makeJsonApiRequest('POST', endpoint, {
      startDate,
      endDate,
    });

    if (response?.status === 200 && response?.success) {
      return response?.data;
    } else {
      throw new Error('Failed to generate the report');
    }
  } catch (error) {
    console.error('Error generating the report:', error);
    throw error;
  }
};

export const renewPlan = async (payload) => {
  const endpoint = `${API_URL}renew-plan`;
  try {
    const response = await makeJsonApiRequest('POST', endpoint, payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const fetchPlans = async (boxType, size) => {
  const endpoint = `${API_URL}get-plans?planType=${boxType}&size=${size}`;
  try {
    const response = await makeBearerJsonApiRequest('GET', endpoint, {});
    if (response.success) {
      return response.data[0];
    } else {
      toast.error(response.message || 'Failed to fetch plans.');
      throw new Error(response.message || 'Failed to fetch plans.');
    }
  } catch (error) {
    toast.error('An error occurred while fetching plans.');
    throw error;
  }
};
