import { Button, Collapse, Image, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import DefaultImage from '../../assets/images/Home/no-image.png';

const { Panel } = Collapse;

const NomineeDetailsShow = ({ formData }) => {
  const [nomineeOne, setNomineeOne] = useState({});
  const [nomineeTwo, setNomineeTwo] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isNomineeTwoModalVisible, setIsNomineeTwoModalVisible] =
    useState(false);
  const [isNomineeTwoSelectedDocument, setIsNomineeTwoSelectedDocument] =
    useState(null);

  useEffect(() => {
    if (formData?.nomineeOne) {
      setNomineeOne(formData.nomineeOne);
    }
    if (formData?.nomineeTwo) {
      setNomineeTwo(formData.nomineeTwo);
    }
  }, [formData]);

  const showDocument = (doc) => {
    setSelectedDocument(doc);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedDocument(null);
  };

  const showDocumentNomineeTwo = (doc) => {
    setIsNomineeTwoSelectedDocument(doc);
    setIsNomineeTwoModalVisible(true);
  };

  const handleCancelNomineeTwo = () => {
    setIsNomineeTwoModalVisible(false);
    setIsNomineeTwoSelectedDocument(null);
  };

  return (
    <div className="mt-4">
      <Collapse className="mt-3 mb-2">
        <Panel
          header={
            <div className="d-flex justify-content-between bg-light">
              <h6 className="header-text fs-4">VIEW NOMINEE ONE DETAILS</h6>
            </div>
          }
          key="1"
        >
          <div className="card">
            <div className="row p-3">
              <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center align-items-center">
                <Image
                  src={nomineeOne?.nomineePic}
                  minWidth={300}
                  height={300}
                  className="border-1 rounded"
                  fallback={DefaultImage}
                />
              </div>
              <div className="col-lg-8 col-md-8 col-12 mt-2">
                <div className="row">
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="title" className="custom-label">
                      Title:
                    </label>
                    <input
                      id="title"
                      name="title"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Title"
                      value={nomineeOne?.nomineeTitle || ''}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="dob" className="custom-label">
                      DOB:
                    </label>
                    <input
                      id="dob"
                      name="dob"
                      type="text"
                      className="form-control custom-input"
                      value={nomineeOne?.nomineeDob || ''}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="forenames" className="custom-label">
                      Forenames:
                    </label>
                    <input
                      id="forenames"
                      name="forenames"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Forenames"
                      value={nomineeOne?.nomineeForename || ''}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="surnames" className="custom-label">
                      Surnames:
                    </label>
                    <input
                      id="surnames"
                      name="surnames"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Surnames"
                      value={nomineeOne?.nomineeSurname || ''}
                      readOnly
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="tel" className="custom-label">
                      Tel:
                    </label>
                    <input
                      id="tel"
                      name="tel"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Telephone"
                      value={nomineeOne?.nomineeTel || ''}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="email" className="custom-label">
                      Email:
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Email"
                      value={nomineeOne?.nomineeEmail || ''}
                      readOnly
                    />
                  </div>
                </div>

                <div className="row d-flex">
                  <div className="col-md-6  mb-3 d-flex align-items-center">
                    <label className="custom-label">Street:</label>
                    <input
                      id="nomineeStreet"
                      name="nomineeStreet"
                      className="form-control custom-input"
                      type="text"
                      placeholder="street"
                      value={nomineeOne?.nomineeStreet}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6  mb-3 d-flex align-items-center">
                    <label htmlFor="town" className="custom-label">
                      Town:
                    </label>
                    <input
                      id="nomineenomineeTownStreet"
                      name="nomineeTown"
                      type="text"
                      className="form-control custom-input"
                      placeholder="town"
                      value={nomineeOne?.nomineeTown}
                      readOnly
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3 d-flex align-items-center">
                    <label htmlFor="postcode" className="custom-label">
                      Postcode:
                    </label>
                    <input
                      id="postcode"
                      name="postcode"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Postcode"
                      value={nomineeOne?.nomineePostcode || ''}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6 mb-3 d-flex align-items-center">
                    <label htmlFor="nationality" className="custom-label">
                      Nationality:
                    </label>
                    <input
                      id="nationality"
                      name="nationality"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Nationality"
                      value={nomineeOne?.nomineeNationality || ''}
                      readOnly
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <h5>Identification Documents:</h5>
                  {nomineeOne?.identificationDocument?.length > 0 ? (
                    nomineeOne.identificationDocument.map((doc, index) => (
                      <div
                        key={index}
                        className="mb-3 d-flex justify-content-between"
                      >
                        <p className="fs-6">
                          <strong>Document Name: </strong> {doc.documentType}
                        </p>
                        <Button
                          type="primary"
                          className="rounded fw-bold"
                          onClick={() => showDocument(doc)}
                        >
                          View
                        </Button>
                      </div>
                    ))
                  ) : (
                    <p>No Documents Available</p>
                  )}

                  <Modal
                    title={selectedDocument?.documentType || 'Document'}
                    open={isModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    {selectedDocument && (
                      <img
                        src={selectedDocument.documentUrl}
                        alt={selectedDocument.documentType}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    )}
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>

      <Collapse className="mt-3">
        <Panel
          header={
            <div className="d-flex justify-content-between bg-light">
              <h6 className="header-text fs-4">VIEW NOMINEE TWO DETAILS</h6>
            </div>
          }
          key="2"
        >
          <div className="card">
            <div className="row p-3">
              <div className="col-lg-4 col-md-4 col-12 d-flex justify-content-center align-items-center">
                <Image
                  src={nomineeTwo?.nomineePic}
                  minWidth={300}
                  height={300}
                  className="border-1 rounded"
                  fallback={DefaultImage}
                />
              </div>
              <div className="col-lg-8 col-md-8 col-12 mt-2">
                <div className="row">
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="title" className="custom-label">
                      Title:
                    </label>
                    <input
                      id="title"
                      name="title"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Title"
                      value={nomineeTwo?.nomineeTitle || ''}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="dob" className="custom-label">
                      DOB:
                    </label>
                    <input
                      id="dob"
                      name="dob"
                      type="text"
                      className="form-control custom-input"
                      value={nomineeTwo?.nomineeDob || ''}
                      readOnly
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="forenames" className="custom-label">
                      Forenames:
                    </label>
                    <input
                      id="forenames"
                      name="forenames"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Forenames"
                      value={nomineeTwo?.nomineeForename || ''}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="surnames" className="custom-label">
                      Surnames:
                    </label>
                    <input
                      id="surnames"
                      name="surnames"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Surnames"
                      value={nomineeTwo?.nomineeSurname || ''}
                      readOnly
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="tel" className="custom-label">
                      Tel:
                    </label>
                    <input
                      id="tel"
                      name="tel"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Telephone"
                      value={nomineeTwo?.nomineeTel || ''}
                      readOnly
                    />
                  </div>
                  <div className="col-lg-6 mb-3 d-flex align-items-center">
                    <label htmlFor="email" className="custom-label">
                      Email:
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Email"
                      value={nomineeTwo?.nomineeEmail || ''}
                      readOnly
                    />
                  </div>
                </div>

                <div className="row d-flex">
                  <div className="col-md-6  mb-3 d-flex align-items-center">
                    <label className="custom-label">Street:</label>
                    <input
                      id="nomineeStreet"
                      name="nomineeStreet"
                      className="form-control custom-input"
                      type="text"
                      placeholder="street"
                      value={nomineeTwo?.nomineeStreet}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6  mb-3 d-flex align-items-center">
                    <label htmlFor="town" className="custom-label">
                      Town:
                    </label>
                    <input
                      id="nomineeTown"
                      name="nomineeTown"
                      type="text"
                      className="form-control custom-input"
                      placeholder="town"
                      value={nomineeTwo?.nomineeTown}
                      readOnly
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3 d-flex align-items-center">
                    <label htmlFor="postcode" className="custom-label">
                      Postcode:
                    </label>
                    <input
                      id="postcode"
                      name="postcode"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Postcode"
                      value={nomineeTwo?.nomineePostcode || ''}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6 mb-3 d-flex align-items-center">
                    <label htmlFor="nationality" className="custom-label">
                      Nationality:
                    </label>
                    <input
                      id="nationality"
                      name="nationality"
                      type="text"
                      className="form-control custom-input"
                      placeholder="Nationality"
                      value={nomineeTwo?.nomineeNationality || ''}
                      readOnly
                    />
                  </div>
                </div>

                <div className="mt-3">
                  <h5>Identification Documents:</h5>
                  {nomineeTwo?.identificationDocument?.length > 0 ? (
                    nomineeTwo.identificationDocument.map((doc, index) => (
                      <div
                        key={index}
                        className="mb-3 d-flex justify-content-between"
                      >
                        <p className="fs-6">
                          <strong>Document Name: </strong> {doc.documentType}
                        </p>
                        <Button
                          type="primary"
                          className="rounded fw-bold"
                          onClick={() => showDocumentNomineeTwo(doc)}
                        >
                          View
                        </Button>
                      </div>
                    ))
                  ) : (
                    <p>No Documents Available</p>
                  )}

                  <Modal
                    title={
                      isNomineeTwoSelectedDocument?.documentType || 'Document'
                    }
                    open={isNomineeTwoModalVisible}
                    onCancel={handleCancelNomineeTwo}
                    footer={null}
                  >
                    {isNomineeTwoSelectedDocument && (
                      <img
                        src={isNomineeTwoSelectedDocument.documentUrl}
                        alt={isNomineeTwoSelectedDocument.documentType}
                        style={{ width: '100%', height: 'auto' }}
                      />
                    )}
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default NomineeDetailsShow;
