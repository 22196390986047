import { Collapse, DatePicker, Image, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { nationalities } from '../reusable/reusableComponent/nationalities';
import { MdOutlineFileUpload } from 'react-icons/md';
import { updateUserDetails, uploadImageToAPI } from './ClientApi';
import IdentificationDocuments from '../reusable/Client/DocumentUpload';
import { toast } from 'react-toastify';
import { IoCloseCircleSharp } from 'react-icons/io5';

const { Panel } = Collapse;

const NomineeTwoDetailsEdit = ({ formData, setFormData, fetchedData }) => {
  const [errors, setErrors] = useState({});
  const [isEditable, setIsEditable] = useState(false);
  const [nomineeTwoImageShow, setNomineeTwoImageShow] = useState();
  const [
    nomineeTwoIdentificationDocument,
    setNomineeTwoIdentificationDocument,
  ] = useState([]);
  const [nomineeTwoDocuments, setNomineeTwoDocuments] = useState({
    passport: false,
    driversLicense: false,
    bankStatement: false,
    councilTax: false,
    utilityBill: false,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNomineeTwoIdentificationDocument(
      formData?.nomineeTwo?.identificationDocument
    );
    setNomineeTwoImageShow(formData?.nomineeTwo?.nomineePic);
  }, [formData]);

  const fileNomineeTwoInputRef = useRef(null);

  const handleNomineeTwoProfileFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      try {
        const fileURL = await uploadImageToAPI(formData);
        setNomineeTwoImageShow(fileURL[0]);
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.nomineePic;
          return newErrors;
        });
      } catch (error) {
        setErrors({ fileUploaded: error.message });
      }
    }
  };

  const handleNomineeTwoFileChange = async (e, docName) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const fileURL = await uploadImageToAPI(formData);
        setNomineeTwoIdentificationDocument([
          {
            documentType: docName,
            documentUrl: fileURL[0],
          },
        ]);
      } catch (error) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fileUploaded: error.message,
        }));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      nomineeTwo: {
        ...prev.nomineeTwo,
        [name.split('.').pop()]: value,
      },
    }));
    validateField(name.split('.').pop(), value);
  };

  const validateField = (fieldName, value) => {
    let error = '';
    if (!value) {
      error = `${fieldName} is required`;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [fieldName]: error,
    }));
  };

  const toggleEditMode = () => {
    if (isEditable) {
      setFormData({
        nomineeTwo: {
          nomineeTitle: '',
          nomineeDob: '',
          nomineeForename: '',
          nomineeSurname: '',
          nomineePostcode: '',
          nomineeStreet: '',
          nomineeTown: '',
          nomineeTel: '',
          nomineeNationality: '',
          nomineeEmail: '',
          nomineePic: '',
        },
      });
      setNomineeTwoImageShow(null);
      setErrors({});
      setNomineeTwoIdentificationDocument(null);
      setLoading(false);
    }
    setIsEditable(!isEditable);
  };

  const handleNomineeTwoFileUpload = (e) => {
    e.preventDefault();
    fileNomineeTwoInputRef.current.click();
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let validationErrors = {};

    if (!formData?.nomineeTwo?.nomineeTitle) {
      validationErrors.nomineeTitle = 'Nominee title is required.';
    }

    if (!formData?.nomineeTwo?.nomineeDob) {
      validationErrors.nomineeDob = 'Nominee date of birth is required.';
    } else if (new Date(formData.nomineeTwo.nomineeDob) > new Date()) {
      validationErrors.nomineeDob =
        'Nominee date of birth cannot be in the future.';
    }

    if (!formData?.nomineeTwo?.nomineeForename) {
      validationErrors.nomineeForename = 'Nominee forename is required.';
    } else if (!/^[A-Za-z]+$/.test(formData.nomineeTwo.nomineeForename)) {
      validationErrors.nomineeForename =
        'Nominee forename must contain only letters.';
    }

    if (!formData?.nomineeTwo?.nomineeSurname) {
      validationErrors.nomineeSurname = 'Nominee surname is required.';
    } else if (!/^[A-Za-z]+$/.test(formData.nomineeTwo.nomineeSurname)) {
      validationErrors.nomineeSurname =
        'Nominee surname must contain only letters.';
    }

    if (!formData?.nomineeTwo?.nomineePostcode) {
      validationErrors.nomineePostcode = 'Nominee postcode is required.';
    } else if (
      !/^[A-Za-z0-9 ]{3,10}$/.test(formData.nomineeTwo.nomineePostcode)
    ) {
      validationErrors.nomineePostcode = 'Nominee postcode is not valid.';
    }

    if (!formData?.nomineeTwo?.nomineeStreet) {
      validationErrors.nomineeStreet = 'Nominee telephone is required.';
    }

    if (!formData?.nomineeTwo?.nomineeTown) {
      validationErrors.nomineeTown = 'Nominee town is required.';
    }

    if (!formData?.nomineeTwo?.nomineeTel) {
      validationErrors.nomineeTel = 'Nominee telephone is required.';
    } else if (!/^\+?[0-9]{10,15}$/.test(formData.nomineeTwo.nomineeTel)) {
      validationErrors.nomineeTel =
        'Nominee telephone must be a valid phone number.';
    }

    if (!formData?.nomineeTwo?.nomineeNationality) {
      validationErrors.nomineeNationality = 'Nominee nationality is required.';
    }

    if (!formData?.nomineeTwo?.nomineeEmail) {
      validationErrors.nomineeEmail = 'Nominee email is required.';
    } else if (
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(
        formData.nomineeTwo.nomineeEmail
      )
    ) {
      validationErrors.nomineeEmail = 'Nominee email is not valid.';
    }

    // if (!nomineeTwoIdentificationDocument) {
    //   validationErrors.nomineeIdentificationDocument =
    //     'Identification document is required.';
    // }

    if (!nomineeTwoImageShow) {
      validationErrors.nomineePic = 'Nominee picture is required.';
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error('Please fill in all required fields.');
      return;
    }
    const dataToSubmit = {
      userId: formData?._id,
      nomineeTwo: {
        nomineeTitle: formData.nomineeTwo.nomineeTitle,
        nomineeDob: formData.nomineeTwo.nomineeDob,
        nomineeForename: formData.nomineeTwo.nomineeForename,
        nomineeSurname: formData.nomineeTwo.nomineeSurname,
        nomineePostcode: formData.nomineeTwo.nomineePostcode,
        nomineeTel: formData.nomineeTwo.nomineeTel,
        nomineeStreet: formData.nomineeOne.nomineeStreet,
        nomineeTown: formData.nomineeOne.nomineeTown,
        nomineeNationality: formData.nomineeTwo.nomineeNationality,
        nomineeEmail: formData.nomineeTwo.nomineeEmail,
        identificationDocument: nomineeTwoIdentificationDocument,
        nomineePic: nomineeTwoImageShow,
      },
    };

    try {
      const response = await updateUserDetails(dataToSubmit);

      if (response && response.success && response.status === 200) {
        toast.success(response.message);
        fetchedData();
        setIsEditable(!isEditable);
      } else {
        throw new Error('Invalid response');
      }
    } catch (error) {
      console.error('Error submitting user details:', error);
      setErrors({ submit: 'Error submitting user details. Please try again.' });
    }
  };

  const handleRemoveImage = () => {
    setNomineeTwoImageShow('');
    setFormData((prevFormData) => ({
      ...prevFormData,
      nomineeTwo: {
        ...prevFormData.nomineeTwo,
        nomineePic: '',
      },
    }));
  };

  const handleRemoveDocument = () => {
    setNomineeTwoIdentificationDocument([]);
    setFormData((prevFormData) => ({
      ...prevFormData,
      nomineeTwo: {
        ...prevFormData.nomineeTwo,
        identificationDocument: [],
      },
    }));
  };

  return (
    <div className="user-details-container mt-5">
      <Collapse className="mt-3">
        <Panel
          header={
            <div className="d-flex justify-content-between bg-light">
              <h6 className="header-text fs-4">
                EDIT NOMINEE TWO ACCOUNT DETAILS
              </h6>
            </div>
          }
          key="1"
        >
          <div className="d-flex justify-content-end  mb-2">
            {isEditable ? (
              <>
                <button
                  type="button"
                  className="btn btn-success me-3"
                  onClick={handleSave}
                  style={{
                    maxWidth: '100px',
                    minWidth: '80px',
                    width: '100px',
                    fontWeight: '600',
                    border: 'none',
                    borderRadius: '4px',
                  }}
                >
                  Save
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={toggleEditMode}
                  style={{
                    maxWidth: '100px',
                    minWidth: '80px',
                    width: '100px',
                    fontWeight: '600',
                    border: 'none',
                    borderRadius: '4px',
                  }}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={toggleEditMode}
                style={{
                  maxWidth: '100px',
                  minWidth: '80px',
                  width: '100px',
                  fontWeight: '600',
                  border: 'none',
                  borderRadius: '4px',
                }}
              >
                Edit
              </button>
            )}
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center mb-0">
                <label htmlFor="nomineeTitle" className="custom-label">
                  Title:
                  <br />
                  <span className="subTitle">(Mr/Mrs/Miss/Ms/Dr):</span>
                </label>
                <select
                  id="nomineeTitle"
                  name="nomineeTwo.nomineeTitle"
                  className={`form-control select-title text-dark custom-input-box w-100 ${
                    errors.nomineeTitle ? 'border-danger' : ''
                  }`}
                  value={formData?.nomineeTwo?.nomineeTitle || ''}
                  onChange={handleChange}
                  disabled={!isEditable}
                >
                  <option value="">Select Title</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                </select>
              </div>
              <div className="error-message">
                {errors.nomineeTitle && (
                  <p className="error">{errors.nomineeTitle}</p>
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="nomineeDob" className="custom-label">
                  DOB:
                </label>
                <DatePicker
                  id="nomineeDob"
                  name="nomineeTwo.nomineeDob"
                  className={`custom-input-box mt-1 w-100 custom-datepicker ${
                    errors.nomineeDob ? 'border-danger' : ''
                  }`}
                  color="black"
                  style={{
                    height: '40px',
                    border: '1px solid rgb(206 212 217)',
                  }}
                  format="DD-MM-YYYY"
                  value={
                    formData?.nomineeTwo?.nomineeDob
                      ? moment(formData?.nomineeTwo?.nomineeDob, 'DD-MM-YYYY')
                      : null
                  }
                  placeholder="Select Date of Birth"
                  onChange={(date, dateString) => {
                    setFormData((prev) => ({
                      ...prev,
                      nomineeTwo: {
                        ...prev.nomineeTwo,
                        nomineeDob: dateString,
                      },
                    }));
                  }}
                  disabled={!isEditable}
                />
              </div>
              <div className="error-message">
                {errors.nomineeDob && (
                  <span className="text-danger">{errors.nomineeDob}</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="nomineeForenames" className="custom-label">
                  Forename:
                </label>
                <input
                  id="nomineeForenames"
                  name="nomineeForename"
                  type="text"
                  className={`form-control custom-input-box ${
                    errors.nomineeForename ? 'border-danger' : ''
                  }`}
                  placeholder="Forename"
                  value={formData?.nomineeTwo?.nomineeForename || ''}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </div>
              <div className="error-message">
                {errors.nomineeForename && (
                  <p className="error">{errors.nomineeForename}</p>
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="nomineeSurname" className="custom-label">
                  Surname:
                </label>
                <input
                  id="nomineeSurname"
                  name="nomineeSurname"
                  type="text"
                  className={`form-control custom-input-box ${
                    errors.nomineeSurname ? 'border-danger' : ''
                  }`}
                  placeholder="Surname"
                  value={formData?.nomineeTwo?.nomineeSurname || ''}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </div>
              <div className="error-message">
                {errors.nomineeSurname && (
                  <span className="text-danger">{errors.nomineeSurname}</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="telephone" className="custom-label">
                  Telephone:
                </label>
                <input
                  id="telephone"
                  name="nomineeTel"
                  type="text"
                  className={`form-control custom-input-box ${
                    errors.nomineeTel ? 'border-danger' : ''
                  }`}
                  placeholder="Telephone"
                  value={formData?.nomineeTwo?.nomineeTel || ''}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </div>
              <div className="error-message">
                {errors.nomineeTel && (
                  <span className="text-danger">{errors.nomineeTel}</span>
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="email" className="custom-label">
                  Email:
                </label>
                <input
                  id="nomineeEmail"
                  name="nomineeEmail"
                  type="text"
                  className={`form-control custom-input-box ${
                    errors.nomineeEmail ? 'border-danger' : ''
                  }`}
                  placeholder="Email"
                  value={formData?.nomineeTwo?.nomineeEmail || ''}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </div>
              <div className="error-message">
                {errors.nomineeEmail && (
                  <span className="text-danger">{errors.nomineeEmail}</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="nomineeStreet" className="custom-label">
                  Street:
                </label>
                <input
                  id="nomineeStreet"
                  name="nomineeStreet"
                  type="text"
                  className={`form-control custom-input-box ${
                    errors.nomineeTel ? 'border-danger' : ''
                  }`}
                  placeholder="Street"
                  value={formData?.nomineeTwo?.nomineeStreet || ''}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </div>
              <div className="error-message">
                {errors.nomineeStreet && (
                  <span className="text-danger">{errors.nomineeStreet}</span>
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="nomineeTown" className="custom-label">
                  Town:
                </label>
                <input
                  id="nomineeTown"
                  name="nomineeTown"
                  type="text"
                  className={`form-control custom-input-box ${
                    errors.nomineeTown ? 'border-danger' : ''
                  }`}
                  placeholder="Town"
                  value={formData?.nomineeTwo?.nomineeTown || ''}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </div>
              <div className="error-message">
                {errors.nomineeTown && (
                  <span className="text-danger">{errors.nomineeTown}</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex align-items-center">
                <label htmlFor="postcode" className="custom-label">
                  Postcode:
                </label>
                <input
                  id="nomineePostcode"
                  name="nomineePostcode"
                  type="text"
                  className={`form-control custom-input-box ${
                    errors.nomineePostcode ? 'border-danger' : ''
                  }`}
                  placeholder="Postcode"
                  value={formData?.nomineeTwo?.nomineePostcode || ''}
                  onChange={handleChange}
                  disabled={!isEditable}
                />
              </div>
              <div className="error-message">
                {errors.nomineePostcode && (
                  <span className="text-danger">{errors.nomineePostcode}</span>
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="d-flex align-items-center">
                <label htmlFor="nationality" className="custom-label">
                  Nationality:
                </label>
                <select
                  id="nomineeNationality"
                  name="nomineeNationality"
                  className={`form-select-dropdown w-100 ${
                    errors.nomineeNationality ? 'border-danger' : ''
                  }`}
                  value={formData?.nomineeTwo?.nomineeNationality || ''}
                  onChange={handleChange}
                  disabled={!isEditable}
                >
                  <option value="">Select Nationality</option>
                  {nationalities.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="error-message">
                {errors.nomineeNationality && (
                  <span className="text-danger">
                    {errors.nomineeNationality}
                  </span>
                )}
              </div>
            </div>

            <h6 className="header-text mt-5">Upload Second Nominee Picture</h6>
            <div className="row upload-section mb-4">
              <div className="drop-box d-flex justify-content-between align-items-center col-sm-12 col-md-8 col-lg-8">
                <div className="upload-text">
                  <p className="upload-instruction">
                    <MdOutlineFileUpload /> Drag and Drop file here or{' '}
                    <span
                      className="upload-link fw-bold"
                      // onClick={handleNomineeTwoFileUpload}
                      // role="button"
                      tabIndex={0}
                    >
                      Upload
                    </span>
                  </p>
                  <p className="upload-supported">
                    Files Supported: JPG, JPEG, PNG
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="file"
                    ref={fileNomineeTwoInputRef}
                    name="nominee one pic"
                    accept="image/png, image/jpeg"
                    className="d-none"
                    onChange={handleNomineeTwoProfileFileChange}
                  />
                  <button
                    type="button"
                    className="btn upload-button"
                    onClick={handleNomineeTwoFileUpload}
                    disabled={!isEditable}
                  >
                    Upload Profile
                  </button>
                </div>
              </div>
              {errors.nomineePic && (
                <span className="text-danger mt-2">{errors.nomineePic}</span>
              )}

              {loading ? (
                <div className="loader-overlay">
                  <Spin size="large" />
                </div>
              ) : (
                <div className="d-flex justify-content-between align-items-center col-sm-12 col-md-4 col-lg-4">
                  {(nomineeTwoImageShow ||
                    formData?.nomineeTwo?.nomineePic) && (
                    <div className="position-relative">
                      <Image
                        src={
                          nomineeTwoImageShow ||
                          formData?.nomineeTwo?.nomineePic
                        }
                        alt="Nominee One"
                        style={{
                          width: '100px',
                          height: '100px',
                          borderRadius: '12px',
                          border: '1px solid #ddd',
                          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                          marginLeft: '15px',
                        }}
                      />
                      <IoCloseCircleSharp
                        className={`image-close-icon ${
                          !isEditable ? 'disabled-icon' : ''
                        }`}
                        onClick={isEditable ? handleRemoveImage : null}
                        style={{
                          cursor: isEditable ? 'pointer' : 'not-allowed',
                          opacity: isEditable ? 1 : 0.5,
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            <IdentificationDocuments
              documents={nomineeTwoDocuments}
              setDocuments={setNomineeTwoDocuments}
              identificationDocument={nomineeTwoIdentificationDocument}
              handleFileChange={handleNomineeTwoFileChange}
              disabled={!isEditable}
              errors={errors}
              handleRemoveDocument={handleRemoveDocument}
            />
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default NomineeTwoDetailsEdit;
