import React, { useEffect, useState } from 'react';
import { BsArrowUpRightCircleFill } from 'react-icons/bs';
import { getLockers, getLockersByColumns } from '../lockerApi/LockerApi';
import { Table, Pagination, Result, Spin } from 'antd';
import moment from 'moment';
import '../locker-style/LockerTable.css';
import ResultNotFound from '../../reusable/Common/ResultNotFound';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../reusable/reusableComponent/BackButton';
import AZFilter from '../../reusable/reusableComponent/AZFilter';
import ClientDetailShowModal from '../../reusable/reusableComponent/ClientDetailShowModal';
import { ClientViewDetailsAPI } from '../../client-management/ClientApi';
import UpgradeLockerPlan from './UpgradeLockerPlan';
import { GiUpgrade } from 'react-icons/gi';

function LockerTable({ searchTerm }) {
  const [allLockers, setAllLockers] = useState([]);
  const [filteredLockers, setFilteredLockers] = useState([]);
  const [lockers, setLockers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalLockers, setTotalLockers] = useState(0);
  const [totalVacant, setTotalVacant] = useState(0);
  const [totalOccupied, setTotalOccupied] = useState(0);
  const [lockersPerPage, setLockersPerPage] = useState(20);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleConfirmUpgrade = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetchLockers = async () => {
      setLoading(true);
      try {
        const response = await getLockers();
        const fetchedLockers = response.data.lockers;
        setAllLockers(fetchedLockers);
        setTotalLockers(fetchedLockers.length);
        setTotalVacant(response.data.vacantCount);
        setTotalOccupied(response.data.occupiedCount);
        setCurrentPage(1);
      } catch (err) {
        setError('Error fetching lockers data');
        console.error('Error fetching lockers data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchLockers();
  }, []);

  useEffect(() => {
    const filtered = allLockers.filter((locker) => {
      const lockerNumberMatch =
        locker?.lockerNumber
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) || false;
      const sizeMatch =
        locker?.size?.toLowerCase().includes(searchTerm.toLowerCase()) || false;
      const columnMatch =
        locker?.column?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        false;
      const startDateMatch = locker?.startDate
        ? moment(locker.startDate).format('DD-MM-YYYY').includes(searchTerm)
        : false;
      const renewalDateMatch = locker?.renewalDate
        ? moment(locker.renewalDate).format('DD-MM-YYYY').includes(searchTerm)
        : false;
      const statusMatch =
        locker?.status?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        false;
      const uccNumberMatch =
        locker?.userId?.uccNumber?.toString().includes(searchTerm) || false;
      const applicationNoMatch =
        locker?.userId?.applicationNo
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) || false;
      const foreNameMatch =
        locker?.userId?.foreName
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) || false;
      const surNameMatch =
        locker?.userId?.surName
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) || false;
      const nationalityMatch =
        locker?.userId?.nationality
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) || false;
      const telephoneMatch =
        locker?.userId?.telephone
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase()) || false;

      return (
        lockerNumberMatch ||
        sizeMatch ||
        columnMatch ||
        startDateMatch ||
        renewalDateMatch ||
        statusMatch ||
        uccNumberMatch ||
        applicationNoMatch ||
        foreNameMatch ||
        surNameMatch ||
        nationalityMatch ||
        telephoneMatch
      );
    });

    setFilteredLockers(filtered);
  }, [searchTerm, allLockers]);

  const handleAZFilter = async (letter) => {
    try {
      setLoading(true);
      const response = await getLockersByColumns(letter);
      const fetchedLockers = response.data.lockers;

      setAllLockers(fetchedLockers);
      setFilteredLockers(fetchedLockers);
      setTotalLockers(fetchedLockers.length);
      setTotalVacant(response.data.vacantCount);
      setTotalOccupied(response.data.occupiedCount);
      setCurrentPage(1);
    } catch (err) {
      setError('Error fetching lockers by filter');
      console.error('Error fetching lockers by filter:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const indexOfLastLocker = currentPage * lockersPerPage;
    const indexOfFirstLocker = indexOfLastLocker - lockersPerPage;
    const currentLockers = filteredLockers.slice(
      indexOfFirstLocker,
      indexOfLastLocker
    );
    setLockers(currentLockers);
  }, [currentPage, lockersPerPage, filteredLockers]);

  const handleStatusClick = async (userId) => {
    try {
      const response = await ClientViewDetailsAPI(userId?._id);
      if (response.success) {
        setSelectedUserData(response.data);
      } else {
        setError('User not found');
      }
    } catch (error) {
      setError('Failed to fetch user data. Please try again later.');
    } finally {
      setIsModalVisible(true);
    }
  };

  const handleCardClick = (status) => {
    let filteredLockers;

    switch (status) {
      case 'Occupied':
        filteredLockers = allLockers.filter(
          (locker) => locker.status === 'Occupied'
        );
        break;
      case 'Vacant':
        filteredLockers = allLockers.filter(
          (locker) => locker.status === 'Vacant'
        );
        break;
      case 'All':
      default:
        filteredLockers = allLockers;
        break;
    }

    setFilteredLockers(filteredLockers);
    setCurrentPage(1);
  };

  const columns = [
    {
      title: 'Locker Number',
      dataIndex: 'lockerNumber',
      render: (text) => <p className="fw-bold fs-6 mb-0">{text || '---'}</p>,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      render: (text) => (
        <p className="client-deposit fw-bold fw-normal">{text || '---'}</p>
      ),
    },
    {
      title: 'Column',
      dataIndex: 'column',
      render: (text) => (
        <p className="client-deposit fw-bold fw-normal">{text || '---'}</p>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      render: (date) => (
        <>
          <p className="client-deposit fw-bold mb-0">
            {date ? moment(date).format('DD-MM-YYYY') : '---'}
          </p>
          <p className="client-deposit-details fw-normal">
            Start Time ({date ? moment(date).format('HH:mm') : '---'})
          </p>
        </>
      ),
    },
    {
      title: 'Renewal Date',
      dataIndex: 'renewalDate',
      render: (date) => (
        <>
          <p className="client-deposit fw-bold mb-0">
            {date ? moment(date).format('DD-MM-YYYY') : '---'}
          </p>
          <p className="client-deposit-details fw-normal">
            Renewal Time ({date ? moment(date).format('HH:mm') : '---'})
          </p>
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (status, record) => (
        <button
          onClick={() =>
            status === 'Occupied' && handleStatusClick(record?.userId)
          }
          style={{
            backgroundColor: status === 'Occupied' ? '#FFC7C7' : '#C4FFD1',
            color: status === 'Occupied' ? '#FF2424' : '#00A324',
            borderRadius: '10px',
            padding: '8px 22px',
            display: 'inline-block',
            fontWeight: '700',
            width: '110px',
            textAlign: 'center',
            cursor: 'pointer',
            border: 'none',
          }}
        >
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </button>
      ),
    },
  ];

  const onChangePage = (page) => {
    setCurrentPage(page);
  };

  const handleLockersPerPageChange = (size) => {
    setLockersPerPage(size);
    setCurrentPage(1);
  };

  if (loading) {
    return (
      <div
        className="loading-container"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  if (error) {
    return (
      <Result
        status="error"
        title="Error"
        subTitle={error}
        style={{ marginTop: '20px' }}
      />
    );
  }

  return (
    <div className="client-table-container">
      <div className="back-button-container">
        <BackButton onClick={handleBackButtonClick} />
      </div>
      <AZFilter onFilter={handleAZFilter} />
      <div className="d-flex justify-content-between">
        <div className="card-section d-flex">
          <div
            className="client-card card border"
            onClick={() => handleCardClick('All')}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-body">
              <div className="d-flex client-info">
                <p className="client-count fs-3 mt-2 text-primary fw-bold">
                  {totalLockers}
                </p>
                <BsArrowUpRightCircleFill className="icon-arrow text-primary fs-4 mt-3 ms-2" />
              </div>
              <h5 className="card-title text-start mt-1">TOTAL LOCKERS</h5>
            </div>
          </div>

          <div
            className="client-card card border mx-2 mb-2"
            onClick={() => handleCardClick('Occupied')}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-body">
              <div className="d-flex client-info">
                <p className="client-count fs-3 mt-2 me-2 text-primary fw-bold">
                  {totalOccupied}
                </p>
                <i className="fa-solid fa-lock fs-3 mt-2 text-primary" />
              </div>
              <h5 className="card-title text-start mt-1">TOTAL OCCUPIED</h5>
            </div>
          </div>

          <div
            className="client-card card border"
            onClick={() => handleCardClick('Vacant')}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-body">
              <div className="d-flex client-info">
                <p className="client-count fs-3 mt-2 me-2 text-primary fw-bold">
                  {totalVacant}
                </p>
                <i className="fa-solid fa-lock-open fs-3 mt-2 text-primary" />
              </div>
              <h5 className="card-title text-start mt-1">TOTAL VACANT</h5>
            </div>
          </div>
        </div>

        <div
          className="client-card card border mt-5"
          onClick={handleOpen}
          style={{ cursor: 'pointer' }}
        >
          <div className="card-body">
            <div className="d-flex client-info">
              <p className="client-count fs-4 text-primary fw-bold">LOCKER</p>
              <GiUpgrade className="text-primary fs-3 ms-2" />
            </div>
            <h5 className="card-title text-start mt-1">PLAN UPGRADE</h5>
          </div>
        </div>
      </div>

      <div className="container-fluid my-5">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p>{error}</p>
        ) : filteredLockers.length === 0 ? (
          <ResultNotFound error={null} searchTerm={searchTerm} />
        ) : (
          <Table columns={columns} dataSource={lockers} pagination={false} />
        )}

        <div className="">
          <Pagination
            className="pagination"
            current={currentPage}
            total={filteredLockers.length}
            onChange={onChangePage}
            pageSize={lockersPerPage}
            showSizeChanger={true}
            onShowSizeChange={(current, size) =>
              handleLockersPerPageChange(size)
            }
          />
        </div>
        <ClientDetailShowModal
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          selectedUserData={selectedUserData}
        />

        <UpgradeLockerPlan
          showModal={showModal}
          handleClose={handleClose}
          handleConfirmUpgrade={handleConfirmUpgrade}
        />
      </div>
    </div>
  );
}

export default LockerTable;
