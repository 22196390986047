import React, { useEffect, useState } from 'react';
import { Table, message, Modal, Button, Input } from 'antd';
import moment from 'moment';
import { getClientList } from '../EventApis';
import BackButton from '../../reusable/reusableComponent/BackButton';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CopyableText from '../../reusable/Common/CopyableText';

const { Search } = Input;

const EventMailStorage = ({ searchTerm }) => {
  const [searchTerm1, setSearchTerm1] = useState('');
  const [clientDatas, setClientDatas] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [viewEmails, setViewEmails] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientData();
  }, []);

  useEffect(() => {
    filterClients();
  }, [searchTerm, searchTerm1]);

  const fetchClientData = async () => {
    setLoading(true);
    try {
      const response = await getClientList();
      if (response.success && response?.data) {
        const clients = response?.data;
        setClientDatas(clients);
        setFilteredClients(clients);
        setCurrentPage(1);
      } else {
        setError(response.message || 'Error fetching clients');
        message.error(response.message || 'Error fetching clients');
      }
    } catch (error) {
      setError('Network Error: ' + error.message);
      message.error('Network Error: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const filterClients = () => {
    const search = searchTerm || searchTerm1;
    const lowerCaseSearchTerm = search.toLowerCase();

    if (!search || !search.trim()) {
      setFilteredClients(clientDatas);
      return;
    }

    const filtered = clientDatas.filter((client) => {
      const fullNameMatch = `${client.foreName} ${client.surName}`
        .toLowerCase()
        .includes(lowerCaseSearchTerm);
      const emailMatch = client.email
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const uccNumberMatch = client.uccNumber
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const boxNumberMatch = client.boxNumber
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const postcodeMatch = client.postcode
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const streetMatch = client.street
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const townMatch = client.town
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const telephoneMatch = client.telephone
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const renewalDateMatch = moment(client.renewalDate)
        .format('DD-MM-YYYY')
        .toLowerCase()
        .includes(lowerCaseSearchTerm);
      const regDateMatch = moment(client.createdAt)
        .format('DD-MM-YYYY')
        .toLowerCase()
        .includes(lowerCaseSearchTerm);
      const dobMatch = moment(client.dob)
        .format('DD-MM-YYYY')
        .toLowerCase()
        .includes(lowerCaseSearchTerm);

      return (
        fullNameMatch ||
        emailMatch ||
        uccNumberMatch ||
        boxNumberMatch ||
        postcodeMatch ||
        telephoneMatch ||
        renewalDateMatch ||
        regDateMatch ||
        dobMatch ||
        streetMatch ||
        townMatch
      );
    });

    setFilteredClients(filtered);
    setCurrentPage(1);
  };

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleViewMails = (client) => {
    const emails = client.emailObj || [];
    if (!emails.length) {
      Swal.fire({
        title: 'No Emails Available',
        text: 'Emails are not available for this client.',
        icon: 'info',
        confirmButtonText: 'Okay',
      });
      return;
    }
    setSelectedEmail(emails);
    setViewEmails(true);
  };

  const handleBackToTable = () => {
    setViewEmails(false);
    setSelectedEmail(null);
  };

  const columns = [
    {
      title: 'PROFILE',
      dataIndex: 'clientPic',
      render: (text, client) => (
        <div className="d-flex client-view" style={{ textAlign: 'center' }}>
          <img
            src={
              client.clientPic ||
              'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR9SRRmhH4X5N2e4QalcoxVbzYsD44C-sQv-w&s'
            }
            alt="profile"
            className="avatar rounded-circle client-avatar me-2"
          />
        </div>
      ),
      align: 'center',
    },

    {
      title: 'NAME/EMAIL',
      dataIndex: 'clientPic',
      render: (text, client) => (
        <div style={{ textAlign: 'center' }}>
          <p className="fw-bold mb-0">
            {client.foreName} {client.surName}
          </p>
          <p className="client-email fw-normal">{client.email || 'N/A'}</p>
        </div>
      ),
      align: 'center',
      sorter: (a, b) => {
        const nameA = `${a.foreName} ${a.surName}`.toLowerCase();
        const nameB = `${b.foreName} ${b.surName}`.toLowerCase();
        return nameA.localeCompare(nameB);
      },
    },
    {
      title: 'START DATE',
      dataIndex: 'createdAt',
      render: (text) => moment(text).format('DD-MM-YYYY'),
      align: 'center',
      width: 150,
      sorter: (a, b) =>
        moment(a.createdAt).isBefore(moment(b.createdAt)) ? -1 : 1,
    },
    {
      title: 'UCC NO.',
      dataIndex: 'uccNumber',
      align: 'center',
      sorter: (a, b) => (a.uccNumber || '').localeCompare(b.uccNumber || ''),
      render: (text) => <CopyableText text={text || '---'} />,
    },
    {
      title: 'BOX',
      dataIndex: 'boxNumber',
      render: (text) => text || '---',
      align: 'center',
    },
    {
      title: 'STREET',
      dataIndex: 'street',
      render: (text) => text || '---',
      align: 'center',
    },
    {
      title: 'TOWN',
      dataIndex: 'town',
      render: (text) => text || '---',
      align: 'center',
    },
    {
      title: 'POSTCODE',
      dataIndex: 'postcode',
      render: (text) => text || '---',
      align: 'center',
      width: 150,
      sorter: (a, b) => (a.postcode || '').localeCompare(b.postcode || ''),
    },
    {
      title: 'TELEPHONE',
      dataIndex: 'telephone',
      render: (text) => text || '---',
      align: 'center',
      width: 150,
      sorter: (a, b) => (a.telephone || '').localeCompare(b.telephone || ''),
    },
    {
      title: 'VIEW',
      render: (text, client) => (
        <div>
          <button
            className="btn btn-view-history mb-3"
            onClick={() => handleViewMails(client)}
          >
            View Mails
          </button>
        </div>
      ),
      align: 'center',
    },
  ];

  return (
    <div className="">
      <div className="d-flex justify-content-end align-items-center">
        <Search
          placeholder="Search clients by name, email, or other details"
          allowClear
          value={searchTerm1}
          onChange={(e) => setSearchTerm1(e.target.value)}
          enterButton
          className="search-input"
        />
      </div>
      <div className="back-button-container">
        <BackButton onClick={handleBack} />
      </div>
      {!viewEmails && (
        <div className="p-4">
          <Table
            columns={columns}
            dataSource={filteredClients}
            pagination={{
              current: currentPage,
              pageSize: pageSize,
              total: filteredClients.length,
              onChange: handleTableChange,
            }}
            rowKey="uccNumber"
            loading={loading}
            scroll={{ x: true }}
            bordered={false}
          />
        </div>
      )}

      {viewEmails && (
        <div>
          <div className="back-button-container ">
            <BackButton onClick={handleBackToTable} />
          </div>

          {viewEmails && (
            <div>
              <div>
                <h4 className="header-text fs-2 mb-3 bg-light p-2">Emails</h4>
                <ul className="email-list">
                  {Array.isArray(selectedEmail) && selectedEmail.length > 0 ? (
                    selectedEmail.map((email, index) => (
                      <li key={index} className="mb-4 card p-3">
                        <p className="text-end fs-5">
                          <span className="fw-bolder">Time:</span>{' '}
                          {moment(email?.time).format('DD-MM-YYYY HH:mm')}
                        </p>
                        <div
                          className="email-content mx-auto p-2"
                          dangerouslySetInnerHTML={{
                            __html: email?.email,
                          }}
                        />
                      </li>
                    ))
                  ) : (
                    <li>No emails available</li>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EventMailStorage;
