import React, { useEffect, useState } from 'react';
import { FaUserCircle, FaHome } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { TfiMenuAlt } from 'react-icons/tfi';
import Cookies from 'js-cookie';
import logo from '../../../assets/images/logo.png';
import Client from '../../../assets/images/clients.png';
import Events from '../../../assets/images/Home/events.png';
import Finance from '../../../assets/images/Home/finance.png';
import RenewalsImage from '../../../assets/images/Home/renewal-image.png';
import Locker from '../../../assets/images/Home/reshot-icon-locker.png';
import SafesImage from '../../../assets/images/Home/safes.png';
import PaymentCalcImage from '../../../assets/images/Home/payment-calculator.png';
import LockerTable from '../../locker-management/locker/LockerTable';
import PaymentCalculator from '../../payment-management/payment-calc/PaymentCalculator';
import { useNavigate } from 'react-router-dom';
import SafesHome from '../../safes-management/SafesHome';
import './AdminNavbar.css';
import { toast } from 'react-toastify';
import { logoutUser } from '../../auth/AuthApi';
import { getClientTableAPI } from '../../client-management/API/ClientTableAPI';
import { Image, Spin, Table } from 'antd';
import moment from 'moment';

const menuItems = [
  {
    id: 1,
    name: 'CLIENTS',
    imageUrl: Client,
  },
  {
    id: 2,
    name: 'EVENTS',
    imageUrl: Events,
  },
  {
    id: 3,
    name: 'FINANCIALS',
    imageUrl: Finance,
  },
  {
    id: 4,
    name: 'LOCKERS',
    imageUrl: Locker,
  },
  {
    id: 5,
    name: 'SAFES',
    imageUrl: SafesImage,
  },
  {
    id: 6,
    name: 'PAYMENT CALCULATOR',
    imageUrl: PaymentCalcImage,
  },
  {
    id: 7,
    name: 'RENEWALS',
    imageUrl: RenewalsImage,
  },
];

function AdminNavbar() {
  const [menuVisible, setMenuVisible] = useState(true);
  const [showClientFilters, setShowClientFilters] = useState(false);
  const [showSafes, setShowSafes] = useState(false);
  const [showLockers, setShowLockers] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [paymentCalc, setPaymentCalc] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [adminFirstName, setAdminFirstName] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [allClients, setAllClients] = useState([]);
  const [FilterClientResult, setFilterClientResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchActive, setSearchActive] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getAdminDetails();
  }, []);

  useEffect(() => {
    if (searchQuery.trim()) {
      fetchClientData();
    } else {
      setFilterClientResult([]);
      setShowSearchResults(false);
    }
  }, [searchQuery]);

  const fetchClientData = async () => {
    setLoading(true);
    setSearchActive(true);
    setError('');

    try {
      const response = await getClientTableAPI({
        searchQuery,
      });

      if (response.success && response?.data?.clients) {
        setAllClients(response.data.clients);
        filterClients(response.data.clients);
        setShowSearchResults(true);
      } else {
        setError(response.message || 'Error fetching clients');
        setFilterClientResult([]);
      }
    } catch (error) {
      setError('Network Error: ' + error.message);
      setFilterClientResult([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const filterClients = (clients) => {
    if (!searchQuery || !searchQuery.trim()) {
      setFilterClientResult(clients);
      return;
    }

    const filtered = clients.filter((client) => {
      const lowerCaseSearchTerm = searchQuery.toLowerCase();

      const fullNameMatch = `${client.foreName} ${client.surName}`
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const emailMatch = client.email
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const UccMatch = client.uccNumber
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const telMatch = client.telephone
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const boxMatch = client.boxNumber
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const boxTypeMatch = client.boxType
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const streetMatch = client.street
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const townMatch = client.town
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const postcodeMatch = client.postcode
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const boxNumberMatch = client.boxNumber
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);
      const dobMatch = moment(client.dob)
        .format('DD-MM-YYYY')
        ?.toLowerCase()
        .includes(lowerCaseSearchTerm);

      return (
        fullNameMatch ||
        boxTypeMatch ||
        boxMatch ||
        streetMatch ||
        UccMatch ||
        telMatch ||
        townMatch ||
        emailMatch ||
        boxNumberMatch ||
        dobMatch ||
        postcodeMatch
      );
    });

    setFilterClientResult(filtered);
  };

  const filteredClients =
    FilterClientResult.length > 0 ? FilterClientResult : [];

  const toggleMenu = () => {
    if (menuVisible) {
      setFadeOut(true);
      setTimeout(() => {
        setMenuVisible(false);
        setFadeOut(false);
      }, 500);
    } else {
      setMenuVisible(true);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    try {
      const message = logoutUser(navigate);
      toast.success(message);
    } catch (error) {
      console.error('Error during logout:', error);
      toast.error(error.message);
    }
  };

  const getAdminDetails = () => {
    const adminDetails = Cookies.get('admin-details');
    if (adminDetails) {
      const parsedDetails = JSON.parse(adminDetails);
      setAdminFirstName(parsedDetails.firstName);
    }
  };

  const handleMenuClick = (itemName) => {
    setMenuVisible(false);
    setShowClientFilters(false);
    setShowSafes(false);
    setShowLockers(false);

    if (itemName === 'CLIENTS') {
      navigate('/clients');
    } else if (itemName === 'SAFES') {
      navigate('/safes');
    } else if (itemName === 'LOCKERS') {
      navigate('/lockers');
    } else if (itemName === 'EVENTS') {
      navigate('/events');
    } else if (itemName === 'FINANCIALS') {
      navigate('/finance-home');
    } else if (itemName === 'PAYMENT CALCULATOR') {
      navigate('/payment-calculator');
    } else if (itemName === 'RENEWALS') {
      navigate('/renewal-mails');
    } else {
      navigate(`/${itemName.toLowerCase()}`);
    }
  };

  const handleHomeClick = () => {
    setShowClientFilters(false);
    setShowSafes(false);
    setShowLockers(false);
    setPaymentCalc(false);
    setMenuVisible(true);
  };

  const filteredMenuItems = menuItems.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleViewClient = (clientId) => {
    navigate('/admin-client-details-show', {
      state: { id: clientId },
    });
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-content">
          <img src={logo} alt="Logo" className="logo" />
          <div className="profile text-light">
            <button className='bg-transparent border-0'  onClick={toggleDropdown}>
              <FaUserCircle className="profile-icon" />

              <span className="username">
                {adminFirstName ? adminFirstName : 'User Name'}
              </span>
              {isOpen && (
                <div className="dropdown-menu-profile">
                  <button onClick={handleLogout} className="btn btn-danger w-100">
                    Logout
                  </button>

                </div>
              )}
            </button>
          </div>
        </div>
        <div className="search-container">
          <div className="search-input-container">
            <FiSearch className="search-icon" />
            <input
              type="text"
              className="search-input"
              placeholder="Search by Name, Box Number, DOB, etc."
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
        </div>

        {!showClientFilters && !showSafes && !paymentCalc && (
          <div className="button-container">
            <button className="main-menu-button" onClick={toggleMenu}>
              <span className="me-2">Main Menu</span>
              <TfiMenuAlt className="me-2 fs-5" />
            </button>
          </div>
        )}
      </nav>

      {searchQuery.length > 0 && showSearchResults ? (
        <div className="table-container mt-5 p-3">
          {loading ? (
            <Spin className="d-flex justify-content-center align-items-center" />
          ) : error ? (
            <p className="text-danger">{error}</p>
          ) : allClients.length > 0 ? (
            <Table
              dataSource={filteredClients}
              columns={[
                {
                  title: 'PROFILE',
                  dataIndex: 'profilePic',
                  key: 'profilePic',
                  render: (text, record) => (
                    <Image
                      src={record.clientPic || 'default-image-url.jpg'}
                      alt="Client"
                      className="avatar rounded-circle client-avatar"
                      style={{ width: '60px', height: '60px' }}
                    />
                  ),
                },
                {
                  title: 'NAME/EMAIL',
                  dataIndex: 'uccNumber',
                  align: 'center',
                  sorter: (a, b) => {
                    const nameA = `${a.foreName} ${a.surName}`.toLowerCase();
                    const nameB = `${b.foreName} ${b.surName}`.toLowerCase();
                    return nameA.localeCompare(nameB);
                  },
                  render: (text, record) => (
                    <div>
                      <p className="mb-0 fw-bolder">{`${record.foreName} ${record.surName}`}</p>
                      <p>{record.email || ''}</p>
                    </div>
                  ),
                },
                {
                  title: 'BOX TYPE.',
                  dataIndex: 'boxType',
                  align: 'center',
                  render: (text) => text || '---',
                },
                {
                  title: 'BOX NO.',
                  dataIndex: 'boxNumber',
                  key: 'boxNumber',
                  render: (text) => text || '---',
                  align: 'center',
                },
                {
                  title: 'PHONE',
                  dataIndex: 'telephone',
                  key: 'telephone',
                  render: (text) => text || '---',
                  align: 'center',
                },
                {
                  title: 'STREET',
                  dataIndex: 'street',
                  key: 'street',
                  render: (text) => text || '---',
                  align: 'center',
                },
                {
                  title: 'TOWN',
                  dataIndex: 'town',
                  key: 'town',
                  render: (text) => text || '---',
                  align: 'center',
                },
                {
                  title: 'POSTCODE',
                  dataIndex: 'postcode',
                  key: 'postcode',
                  render: (text) => text || '---',
                  align: 'center',
                },
                {
                  title: 'DOB',
                  dataIndex: 'dob',
                  key: 'dob',
                  render: (dob) =>
                    dob ? moment(dob).format('DD-MM-YYYY') : '---',
                  align: 'center',
                },

                {
                  title: 'VIEW CLIENT',
                  dataIndex: 'id',
                  render: (text, client) => (
                    <button
                      className="btn btn-view-history"
                      onClick={() => handleViewClient(client._id)}
                    >
                      View Client
                    </button>
                  ),
                },
              ]}
              rowKey="id"
              pagination={{ pageSize: 10 }}
              scroll={{ x: 1000 }}
            />
          ) : (
            <p>No results found</p>
          )}
        </div>
      ) : (
        <>
          {showClientFilters && (
            <div className="client-filters-container">
              <div className="home-button-main">
                <button className="home-button" onClick={handleHomeClick}>
                  <FaHome className="me-2" />
                  Home
                </button>
              </div>
            </div>
          )}

          {showSafes && <SafesHome />}
          {showLockers && (
            <>
              <LockerTable />
              <div className="home-button-main">
                <button className="home-button" onClick={handleHomeClick}>
                  <FaHome className="me-2" />
                  Home
                </button>
              </div>
            </>
          )}
          {paymentCalc && (
            <>
              <PaymentCalculator />
              <div className="home-button-main">
                <button className="home-button" onClick={handleHomeClick}>
                  <FaHome className="me-2" />
                  Home
                </button>
              </div>
            </>
          )}
          {menuVisible && !showClientFilters && !showSafes && !showLockers && (
            <div
              className={`menu-container mt-4 mb-4 ${fadeOut ? 'fade-out' : ''
                }`}
            >
              <div className="row">
                {filteredMenuItems.map((item, index) => (
                  <div
                    className="col-md-4 menu-items"
                    key={item.id}
                    style={{ animationDelay: `${index * 200}ms` }}
                    onClick={() => handleMenuClick(item.name)}
                  >
                    <div className="image-card text-center mt-5">
                      <img
                        src={item.imageUrl}
                        alt={item.name}
                        className="img-fluid"
                      />
                      <p className="menu-item-name">{item.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default AdminNavbar;
