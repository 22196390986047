import React, { useEffect, useRef, useState } from 'react';
import { MdOutlineAttachFile, MdOutlineFileUpload } from 'react-icons/md';
import { validateUserDetails } from '../../../services/Utils';
import { uploadImageToAPI } from '../../client-management/ClientApi';
import '../Client/UserDetails.css';
import { DatePicker, Image, Spin } from 'antd';
import moment from 'moment/moment';
import { nationalities } from '../reusableComponent/nationalities';
import { toast } from 'react-toastify';
import { IoCloseCircleSharp } from 'react-icons/io5';

const UserDetails = ({
  nextStep,
  formData,
  setFormData,
  handleChange,
  remark,
  clientId,
  disableFields,
}) => {
  const [documents, setDocuments] = useState({
    passport: false,
    driversLicense: false,
    bankStatement: false,
    councilTax: false,
    utilityBill: false,
  });
  const [errors, setErrors] = useState({});
  const [identificationDocument, setIdentificationDocument] = useState([]);
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef(null);
  const documentInputsRef = useRef({});

  useEffect(() => {
    if (Array.isArray(formData.identificationDocument)) {
      setIdentificationDocument(formData.identificationDocument);

      const documentUrls = formData.identificationDocument.map(
        (doc) => doc.documentUrl
      );

      setDocuments((prevDocuments) => ({
        ...prevDocuments,
        documentUrls,
      }));
    } else {
      console.error(
        'Unexpected format for identificationDocument:',
        formData.identificationDocument
      );
    }
  }, [formData.identificationDocument]);

  const handleNext = async (e) => {
    e.preventDefault();
    const validationErrors = validateUserDetails(formData, documents);

    // if (!formData.clientPic) {
    //   validationErrors.clientPic = 'Please upload a client picture.';
    // }

    if (Object.keys(validationErrors).length > 0) {
      toast.error('Please fix the errors.');
      setErrors(validationErrors);
      return;
    }

    const dataToSubmitUpdate = {
      ...formData,
      userId: clientId,
      identificationDocument,
      remark: remark ? remark : formData.remark,
    };

    nextStep();
  };

  const handleCheckboxChange = (e, docName) => {
    const { checked } = e.target;

    if (checked) {
      setDocuments({
        passport: false,
        driversLicense: false,
        bankStatement: false,
        councilTax: false,
        utilityBill: false,
        [docName]: true,
      });
    } else {
      setDocuments((prev) => ({
        ...prev,
        [docName]: false,
      }));
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileChange = async (e, docName) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      try {
        const fileURL = await uploadImageToAPI(formData);
        setFormData((prevFormData) => ({
          ...prevFormData,
          identificationDocument: [
            {
              documentType: docName,
              documentUrl: fileURL[0],
            },
          ],
        }));
      } catch (error) {
        console.error('File upload failed:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClientFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      try {
        const fileURL = await uploadImageToAPI(formData);
        handleChange('clientPic')({ target: { value: fileURL[0] } });
      } catch (error) {
        toast.error({ fileUploaded: error.message });
        setErrors({ fileUploaded: error.message });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRemoveImage = () => {
    handleChange('clientPic')({ target: { value: '' } });
  };

  const handleRemoveDocument = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      identificationDocument: [],
    }));
  };

  return (
    <div className="user-details-container mt-4 p-3">
      <div className="header mb-4">
        <h6 className="header-text">PRIMARY ACCOUNT HOLDER DETAILS</h6>
      </div>
      {loading ? (
        <div className="loader-overlay">
          <Spin size="large" />
        </div>
      ) : (
        <form>
          <div className="row mb-3">
            <h6 className="header-text mb-1">Upload Client Picture</h6>

            <div className="row upload-section mb-4">
              <div
                className={`drop-box d-flex justify-content-between align-items-center col-sm-12 col-md-8 col-lg-8 ${
                  !formData.applicationNo ? 'disabled' : ''
                }`}
                style={{ height: '100px' }}
              >
                <div className="upload-text">
                  <p className="upload-instruction">
                    <MdOutlineFileUpload /> Drag and Drop file here or{' '}
                    <span className="upload-link fw-bold">Upload</span>
                  </p>
                  <p className="upload-supported">
                    Files Supported: JPG, JPEG, PNG
                  </p>
                </div>
                <div className="d-flex align-items-center">
                  <input
                    type="file"
                    ref={fileInputRef}
                    name="clientPic"
                    accept="image/png, image/jpeg"
                    className="d-none"
                    onChange={handleClientFileChange}
                    disabled={disableFields}
                  />
                  <button
                    className="btn upload-button"
                    onClick={handleFileUpload}
                    disabled={disableFields}
                  >
                    Upload Profile
                  </button>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center col-sm-12 col-md-4 col-lg-4">
                {formData.clientPic ? (
                  <div className="position-relative">
                    <Image
                      src={formData.clientPic}
                      alt="Client Profile Picture"
                      style={{
                        width: '100px',
                        height: '100px',
                        borderRadius: '12px',
                        border: '1px solid #ddd',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        marginLeft: '15px',
                      }}
                    />

                    <IoCloseCircleSharp
                      className="image-close-icon"
                      onClick={handleRemoveImage}
                    />
                  </div>
                ) : null}
                {errors.clientPic && (
                  <span className="text-danger">{errors.clientPic}</span>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center">
              <label htmlFor="title" className="custom-label">
                Application no: <span className="text-danger">*</span>
              </label>
              <input
                id="applicationNo"
                name="applicationNo"
                type="text"
                className={`form-control custom-input-box w-100 ${
                  errors.applicationNo ? 'border-danger' : ''
                }`}
                placeholder="Application no"
                value={formData.applicationNo}
                onChange={handleChange('applicationNo')}
              />
            </div>
            <div className="error-message">
              {errors.applicationNo && (
                <span className="text-danger">{errors.applicationNo}</span>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center mb-0">
                <label htmlFor="title" className="custom-label">
                  Title: <span className="text-danger">*</span>
                  <br />
                  <span className="subTitle">(Mr/Mrs/Miss/Ms/Dr):</span>
                </label>
                <select
                  id="title"
                  name="title"
                  className={`form-control select-title custom-input-box w-100 ${
                    errors.title ? 'border-danger' : ''
                  }`}
                  value={formData.title}
                  onChange={handleChange('title')}
                  disabled={disableFields}
                >
                  <option value="">Select Title</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Miss">Miss</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                </select>
              </div>

              <div className="error-message">
                {errors.title && (
                  <span className="text-danger">{errors.title}</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="dob" className="custom-label">
                  DOB: <span className="text-danger">*</span>
                </label>
                <DatePicker
                  id="dob"
                  name="dob"
                  className={`custom-input-box mt-1 w-100 custom-datepicker ${
                    errors.dob ? 'border-danger' : ''
                  }`}
                  style={{
                    height: '40px',
                    border: '1px solid rgb(206, 212, 217)',
                    color: '#000',
                    fontWeight: 'bold',
                  }}
                  format="DD-MM-YYYY"
                  value={
                    formData.dob ? moment(formData.dob, 'DD-MM-YYYY') : null
                  }
                  onChange={(date) => {
                    if (date) {
                      handleChange('dob')({
                        target: { value: date.format('DD-MM-YYYY') },
                      });
                    } else {
                      handleChange('dob')({ target: { value: '' } });
                    }
                  }}
                  placeholder="Select Date of Birth"
                  disabled={disableFields}
                />
              </div>

              <div className="error-message">
                {errors.dob && (
                  <span className="text-danger">{errors.dob}</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="foreName" className="custom-label">
                  Forename: <span className="text-danger">*</span>
                </label>
                <input
                  id="foreName"
                  name="foreName"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.foreName ? 'border-danger' : ''
                  }`}
                  placeholder="Forenames"
                  value={formData.foreName}
                  onChange={handleChange('foreName')}
                  disabled={disableFields}
                />
              </div>

              <div className="error-message">
                {errors.foreName && (
                  <span className="text-danger">{errors.foreName}</span>
                )}
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="surName" className="custom-label">
                  Surname: <span className="text-danger">*</span>
                </label>
                <input
                  id="surName"
                  name="surName"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.surName ? 'border-danger' : ''
                  }`}
                  placeholder="Surname"
                  value={formData.surName}
                  onChange={handleChange('surName')}
                  disabled={disableFields}
                />
              </div>

              <div className="error-message">
                {errors.surName && (
                  <span className="text-danger">{errors.surName}</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="telephone" className="custom-label">
                  Telephone: <span className="text-danger">*</span>
                </label>
                <input
                  id="telephone"
                  name="telephone"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.telephone ? 'border-danger' : ''
                  }`}
                  placeholder="telephone"
                  value={formData.telephone}
                  onChange={handleChange('telephone')}
                  disabled={disableFields}
                />
              </div>
              <div className="error-message">
                {errors.telephone && (
                  <span className="text-danger">{errors.telephone}</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="email" className="custom-label">
                  Email: 
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  id="email"
                  name="email"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.email ? 'border-danger' : ''
                  }`}
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange('email')}
                  // onChange={(e)=>{
                  //   setFormData((prev)=>{
                  //     return {
                  //       ...prev,
                  //       email:e.target.value
                  //     }
                  //   })
                  // }}
                />
              </div>
              <div className="error-message">
                {errors.email && (
                  <span className="text-danger me-5">{errors.email}</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="street" className="custom-label">
                  Street: <span className="text-danger">*</span>
                </label>
                <input
                  id="street"
                  name="street"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.street ? 'border-danger' : ''
                  }`}
                  placeholder="Street"
                  value={formData.street}
                  onChange={handleChange('street')}
                  disabled={disableFields}
                />
              </div>
              <div className="error-message">
                {errors.street && (
                  <span className="text-danger">{errors.street}</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="town" className="custom-label">
                  Town: <span className="text-danger">*</span>
                </label>
                <input
                  id="town"
                  name="town"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.town ? 'border-danger' : ''
                  }`}
                  placeholder="town"
                  value={formData.town}
                  onChange={handleChange('town')}
                  disabled={disableFields}
                />
              </div>
              <div className="error-message">
                {errors.town && (
                  <span className="text-danger me-5">{errors.town}</span>
                )}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="postcode" className="custom-label">
                  Postcode: <span className="text-danger">*</span>
                </label>
                <input
                  id="postcode"
                  name="postcode"
                  type="text"
                  className={`form-control custom-input-box w-100 ${
                    errors.postcode ? 'border-danger' : ''
                  }`}
                  placeholder="Postcode"
                  value={formData.postcode}
                  onChange={handleChange('postcode')}
                  disabled={disableFields}
                />
              </div>
              <div className="error-message">
                {errors.postcode && (
                  <span className="text-danger">{errors.postcode}</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
              <div className="d-flex align-items-center">
                <label htmlFor="nationality" className="custom-label">
                  Nationality: <span className="text-danger">*</span>
                </label>
                <select
                  id="nationality"
                  name="nationality"
                  className={`form-select-dropdown w-100 ${
                    errors.nationality ? 'border-danger' : ''
                  }`}
                  value={formData.nationality}
                  onChange={handleChange('nationality')}
                  disabled={disableFields}
                >
                  <option value="">Select Nationality</option>
                  {nationalities.map((country, index) => (
                    <option key={index} value={country}>
                      {country}
                    </option>
                  ))}
                </select>
              </div>
              <div className="error-message">
                {errors.nationality && (
                  <span className="text-danger">{errors.nationality}</span>
                )}
              </div>
            </div>
          </div>

          <div className="document-section">
            <h6 className="header-text">
              Identification Documents (please tick box)
            </h6>
            {errors.documents && (
              <span className="text-danger">{errors.documents}</span>
            )}
            {[
              { name: 'Passport', label: 'Passport' },
              { name: 'Drivers License', label: 'Drivers License' },
              { name: 'Bank Statement', label: 'Bank Statement' },
              { name: 'Council Tax', label: 'Council Tax' },
              { name: 'Utility Bill', label: 'Utility Bill' },
            ].map((doc) => {
              const isDocumentUploaded =
                identificationDocument?.some(
                  (identification) => identification.documentType === doc.name
                ) || false;

              return (
                <div key={doc.name}>
                  <div className="d-flex align-items-center mt-4">
                    <input
                      type="checkbox"
                      id={doc.name}
                      name={doc.name}
                      checked={documents[doc.name] || isDocumentUploaded}
                      onChange={(e) => handleCheckboxChange(e, doc.name)}
                      disabled={disableFields}
                    />
                    <label htmlFor={doc.name} className="ms-2 document-title">
                      {doc.label}
                    </label>
                  </div>

                  {(documents[doc.name] || isDocumentUploaded) && (
                    <div className="d-flex align-items-center mt-2">
                      <div className="drop-box col-sm-12 col-md-6 col-lg-6 me-3">
                        <p className="upload-instruction">
                          <MdOutlineFileUpload /> Upload your {doc.label}{' '}
                          document here
                        </p>
                      </div>
                      <div>
                        <input
                          type="file"
                          hidden
                          accept="image/*,.pdf,.doc,.docx,.txt"
                          ref={(el) =>
                            (documentInputsRef.current[doc.name] = el)
                          }
                          onChange={(e) => handleFileChange(e, doc.name)}
                          disabled={disableFields}
                        />
                        <button
                          className="btn upload-button"
                          disabled={disableFields}
                          onClick={(e) => {
                            e.preventDefault();
                            documentInputsRef.current[doc.name].click();
                          }}
                        >
                          Upload file
                        </button>
                      </div>

                      {isDocumentUploaded && (
                        <div className="ms-3">
                          {identificationDocument
                            .filter(
                              (identification) =>
                                identification.documentType === doc.name
                            )
                            .map((identification, index) => {
                              const fileExtension = identification.documentUrl
                                .split('.')
                                .pop()
                                .toLowerCase();

                              if (
                                ['jpg', 'jpeg', 'png', 'gif'].includes(
                                  fileExtension
                                )
                              ) {
                                return (
                                  <div className="position-relative">
                                    <Image
                                      key={index}
                                      src={identification.documentUrl}
                                      alt={identification.documentType}
                                      style={{
                                        width: '100px',
                                        height: '100px',
                                        borderRadius: '12px',
                                        border: '1px solid #ddd',
                                        boxShadow:
                                          '0 2px 8px rgba(0, 0, 0, 0.1)',
                                        marginLeft: '15px',
                                      }}
                                    />
                                    <IoCloseCircleSharp
                                      className="image-close-icon"
                                      onClick={handleRemoveDocument}
                                    />
                                  </div>
                                );
                              } else if (fileExtension === 'pdf') {
                                return (
                                  <div
                                    key={index}
                                    style={{ marginLeft: '15px' }}
                                    className="position-relative"
                                  >
                                    <iframe
                                      src={identification.documentUrl}
                                      width="100"
                                      height="100"
                                      style={{
                                        borderRadius: '12px',
                                        border: '1px solid #ddd',
                                        boxShadow:
                                          '0 2px 8px rgba(0, 0, 0, 0.1)',
                                      }}
                                      title="PDF Document"
                                    />

                                    <IoCloseCircleSharp
                                      className="image-close-icon"
                                      onClick={handleRemoveDocument}
                                    />
                                  </div>
                                );
                              } else if (
                                ['doc', 'docx'].includes(fileExtension)
                              ) {
                                return (
                                  <div
                                    key={index}
                                    style={{ marginLeft: '15px' }}
                                  >
                                    <p>
                                      <MdOutlineAttachFile />{' '}
                                      {identification.documentType} -{' '}
                                      {fileExtension.toUpperCase()}
                                    </p>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    key={index}
                                    style={{ marginLeft: '15px' }}
                                  >
                                    <p>
                                      Unsupported file type: {fileExtension}
                                    </p>
                                  </div>
                                );
                              }
                            })}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <div className="stepper-navigation">
            <button onClick={handleNext} className="btn next-btn">
              Next <i className="fa-solid fa-caret-right"></i>
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default UserDetails;



