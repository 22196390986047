import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Row, Col, Button, message } from 'antd';
import {
  fetchLockers,
  fetchSafes,
  upgradeLockerPlan,
} from '../../client-management/ClientApi';
import NavbarWithSearchBar from '../reusableComponent/NavbarWithSearchBar';
import HomeFooter from '../../home/HomeFooter/HomeFooter';
import BackButton from '../reusableComponent/BackButton';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const { Option } = Select;

const UpgradeSafe = () => {
  const location = useLocation();
  const { client } = location.state || {};
  const [selectedSafe, setSelectedSafe] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [payloadValue, setPayloadValue] = useState('');
  const [locker, setLocker] = useState({
    lockerId: '',
    lockerNumber: '',
    column: '',
  });
  const [securitySafe, setSecuritySafe] = useState({
    cLocker: '',
    plan: '',
    paymentAmount: '',
  });
  const [loading, setLoading] = useState(false);
  const [keyDeposit, setKeyDeposit] = useState('0');
  const [keyDepositDue, setKeyDepositDue] = useState(true);
  const [selectedOption, setSelectedOption] = useState('due');
  const navigate = useNavigate();

  useEffect(() => {
    loadSafes();
  }, []);

  const loadSafes = async () => {
    setLoading(true);
    try {
      const response = await fetchSafes('Medium');
      if (response?.success && response?.data?.safes?.length > 0) {
        const firstSafe = response.data.safes[0];
        setSelectedSafe(firstSafe);
        handleChange(
          'securitySafe',
          'boxId'
        )({
          target: { value: firstSafe?._id },
        });
      }
    } catch (error) {
      console.error('Error fetching safes:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleRadioChange = (e) => {
    const isChecked = e.target.checked;
    setKeyDepositDue(isChecked);
    setSelectedOption(e.target.value);
  };

  const handleKeyDepositDueChange = (e) => {
    const value = e.target.value;
    setKeyDeposit(value ? value : '0');
  };

  const handleChange = (field) => (event) => {
    const value = event.target ? event.target.value : event;
    setSecuritySafe((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleKeyDepositChange = async (value) => {
    setSecuritySafe((prevState) => ({
      ...prevState,
      cLocker: value,
    }));

    const isDeposit = value === 'Yes';

    if (isDeposit) {
      try {
        const lockers = await fetchLockers('Small');
        if (lockers?.success && lockers?.data?.lockers.length > 0) {
          const firstLocker = lockers?.data?.lockers[0];
          setLocker({
            lockerId: firstLocker?._id,
            lockerNumber: firstLocker?.lockerNumber,
            column: firstLocker?.column,
          });
        }
      } catch (error) {
        console.error('Error fetching lockers:', error);
      }
    } else {
      setLocker({
        lockerId: '',
        lockerNumber: '',
        column: '',
      });
    }
  };

  const isFormValid = () => {
    console.log({
      selectedSafe,
      plan: securitySafe?.plan,
      paymentAmount: securitySafe?.paymentAmount,
      paymentMethod,
      cLocker: securitySafe?.cLocker,
      lockerNumber: locker?.lockerNumber,
      keyDeposit,
      keyDepositDue,
    });
    return (
      selectedSafe &&
      securitySafe?.plan &&
      securitySafe?.paymentAmount &&
      !isNaN(securitySafe?.paymentAmount) &&
      paymentMethod &&
      (securitySafe?.cLocker === 'No' || locker?.lockerNumber) &&
      keyDeposit &&
      keyDepositDue
    );
  };

  const handleUpgrade = async () => {
    if (!selectedSafe) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please select a Safe.',
      });
      return;
    }

    if (!securitySafe.plan) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please select a duration (Months).',
      });
      return;
    }

    if (!securitySafe.paymentAmount || isNaN(securitySafe.paymentAmount)) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please enter a valid payment amount.',
      });
      return;
    }

    if (!paymentMethod) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please select a payment method.',
      });
      return;
    }

    if (!selectedOption) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please choose a Key Deposit option.',
      });
      return;
    }

    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: `You selected "${selectedOption}" with deposit value: ${keyDeposit}`,
    });

    if (!keyDeposit) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please enter the key deposit amount.',
      });
      return;
    }

    if (securitySafe.cLocker === 'Yes' && !locker.lockerNumber) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please select a complimentary locker.',
      });
      return;
    }
    setLoading(true);

    try {
      const payload = {
        boxId: selectedSafe?._id,
        userId: client?._id,
        plan: securitySafe?.plan,
        boxType: 'safe',
        paymentType: payloadValue,
        complimentaryLocker:
          securitySafe.cLocker === 'No'
            ? null
            : {
                lockerId: locker.lockerId,
                lockerNumber: locker.lockerNumber,
                column: locker.column,
              },
        paymentAmount: Number(securitySafe.paymentAmount),
        keyDeposit: parseInt(keyDeposit),
        keyDepositDue: keyDepositDue,
      };

      const response = await upgradeLockerPlan(payload);

      if (response.success && response.status) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: response.message,
        });
        handleBackButtonClick();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Upgrade Failed',
          html: `<span style="color: red; font-weight: bold;">${
            response.message || 'Upgrade failed.'
          }</span>`,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred during the upgrade.',
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const paymentMethodMap = {
    Cash: 'cash',
    DC: 'dc',
    CC: 'cc',
    BT: 'bt',
    SO: 'so',
  };

  const handlePaymentMethodChange = (e) => {
    const selectedMethod = e.target.value;
    setPaymentMethod(selectedMethod);
    const value = paymentMethodMap[selectedMethod];
    setPayloadValue(value);
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <NavbarWithSearchBar />
      <div className="p-5" style={{ maxWidth: '100%', overflowX: 'auto' }}>
        <h2 className="mt-2">Upgrade Safe</h2>
        <Form layout="vertical" className="p-2">
          <Row gutter={32}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Safe No" required>
                <Input
                  name="safeNo"
                  value={selectedSafe ? selectedSafe.safeNumber : ''}
                  readOnly
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Complimentry Locker" required>
                <Select
                  value={securitySafe.cLocker}
                  onChange={handleKeyDepositChange}
                  placeholder="Select"
                  className="custom-margin"
                >
                  <Option value="Yes">Yes</Option>
                  <Option value="No">No</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Complimentry Locker Box" required>
                <Input
                  id="depositBox"
                  name="depositBox"
                  type="text"
                  className="form-control custom-margin"
                  value={locker.lockerNumber}
                  readOnly
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Months" required>
                <Select
                  id="years"
                  name="years"
                  className="security-year"
                  value={securitySafe.plan}
                  onChange={(value) =>
                    setSecuritySafe((prevState) => ({
                      ...prevState,
                      plan: value,
                    }))
                  }
                >
                  <Option value={12}>12</Option>
                  <Option value={36}>36</Option>
                  <Option value={60}>60</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item label="Total" required>
                <Input
                  id="total"
                  name="total"
                  type="text"
                  className="form-control custom-margin"
                  value={securitySafe.paymentAmount}
                  onChange={(e) =>
                    setSecuritySafe((prevState) => ({
                      ...prevState,
                      paymentAmount: e.target.value,
                    }))
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <div className="row align-items-center p-2">
            <div className="col-sm-2 col-lg-2 col-md-2">
              <label className="custom-label">Key Deposit:</label>
            </div>
            <div className="col-sm-2 col-lg-2 col-md-2">
              <label className="">
                <input
                  type="radio"
                  name="keyDepositDue"
                  value="due"
                  checked={selectedOption === 'due'}
                  onChange={handleRadioChange}
                />{' '}
                Key Deposit Due
              </label>
            </div>
            <div className="col-sm-4 col-lg-8 col-md-8">
              {selectedOption && (
                <input
                  id="keyDeposit"
                  name="keyDeposite"
                  className="form-control custom-input"
                  type="text"
                  placeholder="Key Deposit"
                  value={keyDeposit}
                  onChange={handleKeyDepositDueChange}
                />
              )}
            </div>
          </div>

          <h2 className="header-text mt-4 mb-4">Payment Process of Locker</h2>

          <div className="d-flex flex-wrap">
            {['Cash', 'DC', 'CC', 'BT', 'SO'].map((method, index) => (
              <div className="form-check me-2" key={index}>
                <input
                  className="form-check-input"
                  type="radio"
                  name="paymentMethod"
                  id={`payment${method.replace(/\s+/g, '')}`}
                  value={method}
                  checked={paymentMethod === method}
                  onChange={handlePaymentMethodChange}
                />
                <label
                  className="security-form-label fw-bolder"
                  htmlFor={`payment${method.replace(/\s+/g, '')}`}
                >
                  {method}
                </label>
              </div>
            ))}
          </div>
        </Form>
        <Button
          onClick={handleUpgrade}
          disabled={loading || !isFormValid()}
          className="float-end btn-view-detail p-0 m-2 fs-6"
          style={{ width: '160px' }}
        >
          {loading ? 'Upgrading...' : 'Complete Upgrade'}
        </Button>
      </div>
      <div className="back-button-container">
        <BackButton onClick={handleBackButtonClick} />
      </div>
      <HomeFooter />
    </div>
  );
};

export default UpgradeSafe;
